<template>
  <v-banner
    v-if="ring && !dataWait"
    single-line
    elevation="2"
    rounded="lg"
    max-height="44"
    lines="two"
    :bg-color="bannerColor"
    density="compact"
    class="viewport mt-n5"
    :text="warrantyMessage"
  />
</template>

<script lang="ts">
  import { Component, Prop, Watch, mixins, toNative } from 'vue-facing-decorator'

  import { DateTime } from '#mixins/dateTime'

  import { alertColors } from '#views/users/constants'

  import { WarrantyData } from '#views/rings/types'

  import { warrantyBannerFeature } from '#utils/darwinFeatureFlags'
  import { getWarrantyInfo } from '#utils/user/warrantyDetails'

  import { RingsStore } from '#stores'

  import { Ring } from '#types'

  @Component
  class Warrantybanner extends mixins(DateTime) {
    @Prop() public ring!: Ring

    public ringsStore = new RingsStore()
    public warrantyMessage: string = 'No warranty data available'
    public bannerColor: string = 'grey'

    @Watch('ring')
    protected onRingChanged() {
      this.ringsStore.getWarrantyData({ serial: this.ring.serialNumber, initial: true })
    }

    @Watch('warrantyData')
    protected onWarrantyDataChanged() {
      if (!warrantyBannerFeature()) {
        return
      }
      if (this.warrantyData?.ringset?.warrantyStartDate) {
        let { state, warrantyState, effectiveEndDate } = getWarrantyInfo(
          this.warrantyData.ringset.warrantyStartDate,
          this.warrantyData.ringset.warrantyEndDate,
          this.warrantyData.ringset.escEndDate,
        )
        this.warrantyMessage = `${warrantyState === 'success' ? 'Warranty' : state}: ${this.warrantyData.ringset.warrantyStartDate ? this.formatDateTime(this.warrantyData.ringset.warrantyStartDate, 'DD MMM YYYY') : ''} ${this.formatDateTime(effectiveEndDate, '→ DD MMM YYYY')}`
        this.bannerColor = alertColors[warrantyState]
      }
    }

    public get warrantyData(): WarrantyData | null {
      return this.ringsStore.warrantyData
    }
    public get dataWait(): boolean {
      return this.ringsStore.dataWait
    }
  }

  export default toNative(Warrantybanner)
</script>

<style lang="scss" scoped>
  :deep(.v-banner__content) {
    font-size: 14px;
    word-spacing: -1px;
  }
  :deep(.v-banner-text) {
    padding-inline-end: 0px;
  }
  .v-banner--density-compact.v-banner--two-line {
    padding-top: 1px;
    padding-bottom: 1px;
  }
</style>
