<template>
  <v-container class="viewport">
    <v-btn-toggle v-if="assaBrowserEnabled" v-model="view" rounded="0" color="deep-purple-accent-3" class="ml-8" group>
      <v-btn color="primary" variant="outlined" size="small" value="clientconfigchange">Client Config Change</v-btn>
      <v-btn color="primary" variant="outlined" size="small" value="assabrowser">Assa browser</v-btn>
    </v-btn-toggle>
    <Diff v-if="view == 'clientconfigchange'" :uuid="uuid" :range="range" :ring-serial="ringSerial" />
    <AssaDataBrowser v-if="view == 'assabrowser'" :uuid="uuid" />
  </v-container>
</template>

<script lang="ts">
  import { Component, Prop, mixins, toNative } from 'vue-facing-decorator'

  import { DateTime } from '#mixins/dateTime'

  import { assaBrowserEnabled } from '#utils/darwinFeatureFlags'

  @Component
  class Diff extends mixins(DateTime) {
    @Prop() public uuid!: string
    @Prop() public range!: boolean
    @Prop() public ringSerial!: string

    public view = 'clientconfigchange'

    public get assaBrowserEnabled() {
      // List of options to show
      return assaBrowserEnabled()
    }
  }

  export default toNative(Diff)
</script>

<style lang="scss" scoped></style>
