<template>
  <v-app-bar>
    <v-btn class="mr-n1" icon="mdi-arrow-left" @click="$router.push(previousRoute || '/ring-search')" />

    <v-app-bar-title>Device details</v-app-bar-title>

    <v-spacer />

    <v-tooltip location="bottom">
      <template #activator="{ props }">
        <v-btn
          v-bind="props"
          color="primary"
          :disabled="!markAsReturnedButtonEnabled"
          class="mr-2"
          icon="mdi-package-check"
          @click="returnDialog = true"
        />
      </template>
      Mark as returned
    </v-tooltip>

    <v-menu location="bottom" offset="8">
      <template #activator="{ props }">
        <v-tooltip location="bottom">
          <template #activator="{ props: tooltipProps }">
            <v-btn
              v-ripple="false"
              v-bind="{ ...props, ...tooltipProps }"
              color="primary"
              icon="mdi-printer"
              :disabled="!showPrintReturnLabelButton"
            />
          </template>
          Print return label
        </v-tooltip>
      </template>
      <v-list>
        <v-list-item v-for="(item, index) in returnLabelData" :key="index" @click="printReturnLabelSelection(index)">
          <v-list-item-title>{{ item.printButtonTitle }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>

  <v-progress-circular
    v-if="dataWait && !initialDataFetched"
    size="96"
    class="d-flex"
    style="margin: calc(50vh - 180px) auto 0 auto"
  />

  <v-container v-if="initialDataFetched">
    <v-row>
      <v-col cols="12" md="9">
        <div class="text-h5 font-weight-light">Device and warranty information for {{ ringOrChargerSerial }}</div>

        <div class="text-subtitle-2 text-medium-emphasis font-weight-light">
          <template v-if="markAsReturnedButtonEnabled">
            This device can be marked as returned if there is a warranty case open
          </template>
          <template v-else>This device can not be marked as returned, maybe not paired to app yet</template>
        </div>
      </v-col>

      <v-col md="3" cols="12" class="text-right">
        <v-btn text="Refresh" color="primary" @click="updateInfo()" />
      </v-col>
    </v-row>

    <template v-if="someDataFound && initialDataFetched">
      <DeviceV2 class="mt-8" :device-info="deviceInfo" />

      <WarrantyV3
        class="mt-8"
        :warranty-data="warrantyData"
        :warranty-data-ring-replaced-by="warrantyDataRingReplacedBy"
        :warranty-data-ring-replacement-for="warrantyDataRingReplacementFor"
        :zendesk-note-for-ring="zendeskNoteForRing"
      />

      <ConnectedAccounts class="mt-8" :ring-serial="ringOrChargerSerial" />
    </template>
  </v-container>

  <!-- Not any kind of data about ring is available -->
  <v-container v-if="!dataWait && !someDataFound && initialDataFetched" class="viewport px-12 mt-7">
    <v-card>
      <v-card-title primary-title class="headline mb-4">Ring or charger not found</v-card-title>
      <v-card-text>
        <p>
          No ring or charger found with this serial number:
          <strong>{{ ringOrChargerSerial }}</strong>
        </p>
        <p>Please check serial and try again.</p>
      </v-card-text>
      <v-card-actions class="px-4 pt-5">
        <v-spacer />
        <v-btn variant="text" @click="$router.back()">Back</v-btn>
        <v-btn variant="text" :to="{ path: '/' }">Home</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>

  <ReturnLabel ref="returnLabel" :return-label-data="selectedReturnLabelData" />

  <ReturnDialog
    :dialog="returnDialog"
    :warranty-data="warrantyData"
    :return-dialog-data="returnDialogData"
    :refresh-device-serial="ringOrChargerSerial"
    @dialog-open="updateDialogOpen($event)"
  />
</template>

<script lang="ts">
  import { ReturnDialogData, ReturnLabelData, WarrantyData } from './types'

  import { Component, Prop, Watch, mixins, toNative } from 'vue-facing-decorator'

  import { DateTime } from '#mixins/dateTime'

  import { ReturnLabel } from '#views/rings/ReturnLabel.vue'

  import { extractDeviceInfoFromRingAndWarrantyDetails } from '#utils/device-info/extract'
  import {
    extractMarkAsReturnedDataFromWarrantyData,
    extractReturnDialogDataFromMarkAsReturnedData,
    extractReturnLabelDataFromWarrantyData,
  } from '#utils/mark-returned/extract'

  import { AppStore, RingsStore } from '#stores'

  import { DeviceInfo, Nullable, RingDetails, RingSearchResponseSingleRing } from '#types'

  @Component
  class RingDetailsView extends mixins(DateTime) {
    public declare $refs: {
      returnLabel: ReturnLabel
    }
    /**
     * This property contains either ring or charger serial number.
     * We can fetch device/warranty details with either.
     */
    @Prop() public ringOrChargerSerial!: string
    public appStore = new AppStore()
    public ringsStore = new RingsStore()
    public zendeskNoteForRing: Nullable<string> = null
    public returnLabelData: ReturnLabelData[] = []
    public selectedReturnLabelData: Nullable<ReturnLabelData> = null
    public returnDialog = false
    public chargerSerial = ''
    public deviceInfo: Nullable<DeviceInfo> = null
    public initialDataFetched: boolean = false
    public returnDialogData: Nullable<ReturnDialogData> = null

    public updateDialogOpen(value: boolean) {
      this.returnDialog = value
    }

    public get previousRoute() {
      return this.appStore.previousRoute
    }

    public get showPrintReturnLabelButton(): boolean {
      return this.returnLabelData.length > 0
    }

    public printReturnLabel() {
      this.$refs.returnLabel.openPrintPreview()
    }

    public printReturnLabelSelection(printLabelIndex: number) {
      this.selectedReturnLabelData = this.returnLabelData[printLabelIndex]
      this.$refs.returnLabel.openPrintPreview()
    }

    public get dataWait(): boolean {
      return this.ringsStore.dataWait || this.ringsStore.waitingForData()
    }

    public get ring(): Nullable<RingDetails> {
      return this.ringsStore.ring
    }

    public get warrantyData(): Nullable<WarrantyData> {
      return this.ringsStore.warrantyData
    }

    public get warrantyDataRingReplacedBy(): Nullable<WarrantyData> {
      return this.ringsStore.warrantyDataRingReplacedBy
    }

    public get warrantyDataRingReplacementFor(): Nullable<WarrantyData> {
      return this.ringsStore.warrantyDataRingReplacementFor
    }

    /**
     * Either warranty or ring device/account related data is found
     *
     * It's possible that member has purchased a ring (we have warranty data) but they have not yet paired
     * it with their phone (ring/device data is missing).
     */
    public get someDataFound(): boolean {
      return !!this.warrantyData || !!this.ring
    }

    public get markAsReturnedButtonEnabled() {
      if (this.dataWait) {
        return false
      }

      if (!this.returnDialogData) {
        return false
      }

      if (
        this.returnDialogData.ring.allowReturn ||
        this.returnDialogData.charger.allowReturn ||
        this.returnDialogData.cable.allowReturn
      ) {
        return true
      }

      return false
    }

    protected updateZendeskNote() {
      if (this.ringSearchResults.length > 0) {
        if (
          (this.ringSearchResults[0].ringSerialNumber === this.warrantyData?.ringset.ringSerialNumber ||
            this.ringSearchResults[0].chargerSerialNumber === this.warrantyData?.ringset.chargerSerialNumber) &&
          this.ringSearchResults[0].zendeskNotes.length > 0
        ) {
          this.zendeskNoteForRing = this.ringSearchResults[0].zendeskNotes[0]
        }
      }
    }

    public get ringSearchResults(): RingSearchResponseSingleRing[] {
      return this.ringsStore.ringSearchResult
    }

    @Watch('ringOrChargerSerial', { immediate: true })
    protected async onRingOrChargerSerialChanged() {
      this.initialDataFetched = false
      this.resetMarkReturned()

      this.updateInfo()

      /**
       * Currently only way to get Zendesk notes based on ring or charger serial is to use the ring search
       * endpoint. This is a ugly hack, and in the future we should improve this so that backend returns
       * data in easily digestible format.
       */
      let searchType: 'ringSerial' | 'chargerSerial' | null = null
      let searchTerm: string | null = null
      if (this.warrantyData?.ringset.chargerSerialNumber) {
        searchType = 'chargerSerial'
        searchTerm = this.warrantyData.ringset.chargerSerialNumber
      }
      if (this.warrantyData?.ringset.ringSerialNumber) {
        searchType = 'ringSerial'
        searchTerm = this.warrantyData.ringset.ringSerialNumber
      }
      if (searchType && searchTerm) {
        await this.ringsStore.search({
          search: searchTerm,
          searchType: searchType,
          partialSearch: false,
          pageSize: 500,
        })
        this.updateZendeskNote()
      }

      if (this.ring || this.warrantyData) {
        this.deviceInfo = extractDeviceInfoFromRingAndWarrantyDetails(this.ring, this.warrantyData)
      }
      if (this.warrantyData) {
        this.returnLabelData = extractReturnLabelDataFromWarrantyData(this.warrantyData, this.zendeskNoteForRing)
      }
      this.initialDataFetched = true
    }

    @Watch('warrantyData')
    protected async onWarrantyDataChanged() {
      if (this.warrantyData) {
        this.returnDialogData = extractReturnDialogDataFromMarkAsReturnedData(
          extractMarkAsReturnedDataFromWarrantyData(this.warrantyData),
        )
        this.deviceInfo = extractDeviceInfoFromRingAndWarrantyDetails(this.ring, this.warrantyData)
        this.returnLabelData = extractReturnLabelDataFromWarrantyData(this.warrantyData, this.zendeskNoteForRing)
      } else {
        this.returnDialogData = null
      }
    }

    public get errorMarkReturned(): Nullable<string> {
      return this.ringsStore.errorMarkReturned
    }

    public async updateInfo() {
      await this.ringsStore.getRingDetails(this.ringOrChargerSerial)
      await this.ringsStore.getWarrantyData({ serial: this.ringOrChargerSerial, initial: true })
    }

    public resetMarkReturned() {
      this.returnDialog = false
      this.ringsStore.errorMarkReturned = ''
    }
  }

  export default toNative(RingDetailsView)
</script>

<style lang="scss" scoped>
  .print {
    td {
      padding: 0 !important;
      height: 1.4rem !important;
      border: 1px solid rgba(88, 87, 87, 0.25) !important;
      font-size: 1rem !important;
      line-height: 1rem !important;
    }
  }

  :deep(.v-dialog) {
    .v-card {
      box-shadow: none !important;
    }
  }
</style>
