<template>
  <div ref="fullScreenContainer" class="fullscreenContainer">
    <v-card :loading="dataWait">
      <v-card-title>
        <div v-if="title" class="text-overline" style="position: relative; z-index: 1">{{ title }}</div>
      </v-card-title>
      <div ref="plotWrapper">
        <div ref="plotlygraph" class="mt-n12 w-100" :style="{ height: isFullScreen ? '100vh' : `${graphHeight}px` }" />
      </div>
    </v-card>
  </div>
</template>

<script lang="ts">
  import Plotly from 'plotly.js-strict-dist'

  import { Component, Prop, Watch, mixins, toNative } from 'vue-facing-decorator'

  import { Debounce } from '@jouzen/outo-apps-toolkit'

  import { DateTime } from '#mixins/dateTime'

  import { plotlyIconFullscreen, plotlyIconToggleHover } from '#views/users/constants'

  import { EMPTY_GRAPH, combinePlotData, getAxisData } from '#utils/generic-graph/generic-graph'

  import { GenericGraphStore, PrefsStore } from '#stores'

  @Component
  class GenericGraph extends mixins(DateTime) {
    @Prop() public title!: any
    @Prop({ default: 600 }) public graphHeight!: number
    @Prop() private userUuid!: any
    @Prop() private ring!: any
    @Prop() private graphs!: string[]
    @Prop() private startDate!: string
    @Prop() private endDate!: string
    @Prop({ default: true }) private fullscreenSupported!: boolean
    @Prop({ default: false }) private autoscale!: boolean

    public isFullScreen: boolean = false
    public plotlyChart: any
    private PlotResizeObserver = new ResizeObserver(this.renderPlot)
    private hoverMode: string = 'x unified'
    private combinedPlotData: { layout: any; data: any[] } = { layout: {}, data: [] }

    public declare $refs: {
      plotWrapper: Element
      fullScreenContainer: Element
    }

    public startDateFormatted = new Date(this.startDate)

    public genericGraphStore = new GenericGraphStore()
    public prefsStore = new PrefsStore()

    @Watch('prefsTimezoneSetting')
    protected onTimezoneSettingChanged() {
      this.loadData()
    }

    @Watch('userUuid')
    private onUserUuidChanged() {
      this.loadData(true)
    }

    @Watch('ring')
    private onRingChanged() {
      this.loadData()
    }

    @Watch('startDate')
    private onStartDateChanged() {
      this.loadData()
    }

    @Watch('endDate')
    private onEndDateChanged() {
      this.loadData()
    }

    @Watch('graphs')
    private onGraphsChanged() {
      this.loadData()
    }
    @Watch('hoverMode')
    private onHoverModeChange() {
      this.updateGraph()
    }

    @Watch('graphMode')
    private onGraphModeChanged() {
      this.loadData()
    }

    public mounted() {
      this.PlotResizeObserver.observe(this.$refs.plotWrapper)

      this.$refs.fullScreenContainer.addEventListener('fullscreenchange', () => {
        this.isFullScreen = !this.isFullScreen
      })

      if (this.graphs.length > 0) {
        this.loadData()
      }
    }

    protected get plotData() {
      const data = this.genericGraphStore.genericGraphParts
      return data[this.parentComponentName] ? data[this.parentComponentName] : null
    }

    protected get graphMode() {
      return this.prefsStore.graphMode
    }

    protected getPlotDataForGraphs(graphs: string[]) {
      const allPlotData = this.plotData
      const filteredPlotData: any = {}
      for (const graph of graphs) {
        // What if allPlotData[graph] does not exist? It could happen if graph data is deleted.
        // This should be checked and handled gracefully
        filteredPlotData[graph] = allPlotData[graph]
      }
      return filteredPlotData
    }

    protected get graphOptions() {
      return this.genericGraphStore.graphOptions
    }

    public get dataWait() {
      const data = this.genericGraphStore.dataWait
      if (data[this.parentComponentName]) {
        return data[this.parentComponentName] ? data[this.parentComponentName] : false
      } else {
        return false
      }
    }

    private toggleFullscreen() {
      if (!document.fullscreenElement) {
        this.$refs.fullScreenContainer.requestFullscreen()
      } else {
        document.exitFullscreen()
      }
    }

    /***
     * Returns parent component name.
     * Used to save multiple plots to state when rendering the same plot with different data in multiple
     * components.
     * @private
     */
    private get parentComponentName(): string {
      return this.$parent?.$parent?.$options?.name ? this.$parent?.$parent?.$options?.name : 'default'
    }

    /***
     * Loads selected generic graphs
     * @private
     */
    @Debounce(500)
    private async loadData(flushBeforeLoad = false) {
      // Making copy of selected graphs to prevent mutation while chart is being rendered
      const graphs = [...this.graphs]
      if (flushBeforeLoad || !this.graphs.length) {
        this.combinedPlotData = EMPTY_GRAPH
        this.renderPlot()
        for (const graph of graphs) {
          this.genericGraphStore.cancelRequests({
            source: graph,
            callingComponent: this.parentComponentName,
          })
          this.genericGraphStore.removeGraphData({
            source: graph,
            callingComponent: this.parentComponentName,
          })
        }
        return
      }

      if (!this.graphOptions) {
        this.genericGraphStore.setDataWait({
          component: this.parentComponentName,
          value: true,
        })
        await this.genericGraphStore.getGraphOptions({ uuid: this.userUuid })
      }
      await this.genericGraphStore.removeUnselectedGenericGraphParts({
        graphs: graphs,
        callingComponent: this.parentComponentName,
      })

      this.startDateFormatted = new Date(this.startDate)
      if (this.startDateFormatted <= new Date(this.endDate)) {
        await this.loadGraphs(graphs)
      }
    }

    private async loadGraphs(graphs: string[]) {
      this.genericGraphStore.setDataWait({
        component: this.parentComponentName,
        value: true,
      })
      const promises: Promise<any>[] = []

      for (const graph of graphs) {
        promises.push(
          this.genericGraphStore.loadGenericGraphParts({
            graph: graph,
            uuid: this.userUuid,
            ringSerial: this.ring?.serialNumber,
            from: this.startDate,
            to: this.endDate,
            timeZone: this.getTimezone(),
            callingComponent: this.parentComponentName,
          }),
        )
      }
      await Promise.all(promises)
        .then(() => {
          if (this.graphOptions && graphs.length > 0) {
            this.combinedPlotData = combinePlotData(
              this.getPlotDataForGraphs(graphs),
              getAxisData(this.graphOptions, graphs),
              this.startDate,
              this.endDate,
              this.graphMode,
            )
          }
          this.renderPlot()
          this.genericGraphStore.setDataWait({
            component: this.parentComponentName,
            value: false,
          })
        })
        .catch((error) => {
          console.error(error)
        })
    }

    private updateGraph() {
      this.combinedPlotData.layout['hovermode'] = this.hoverMode

      Plotly.relayout((this.$refs as any).plotlygraph, this.combinedPlotData.layout)
    }

    /***
     * Renders the chart. Used to initially render the chart and re-render it on resize event.
     * @private
     */
    private renderPlot() {
      let plotConfig = {}
      if (this.fullscreenSupported) {
        plotConfig = {
          modeBarButtonsToAdd: [
            {
              name: `Toggle hover modes`,
              icon: plotlyIconToggleHover,
              direction: 'up',
              click: () =>
                (this.hoverMode =
                  this.hoverMode == 'x unified' ? (this.hoverMode = 'closest') : (this.hoverMode = 'x unified')),
            },
            {
              name: 'Fullscreen',
              icon: plotlyIconFullscreen,
              direction: 'up',
              click: () => this.toggleFullscreen(),
            },
          ],
          modeBarButtonsToRemove: ['autoScale2d'],
        }
      }

      if ((this.$refs as any)?.plotlygraph) {
        //this sets text on plotly graphs on creation
        this.plotlyChart = Plotly.newPlot(
          (this.$refs as any).plotlygraph,
          this.combinedPlotData.data,
          this.combinedPlotData.layout,
          plotConfig,
        )

        if (this.autoscale) {
          Plotly.relayout((this.$refs as any).plotlygraph, {
            'xaxis.autorange': true,
            'yaxis.autorange': true,
          })
        }
      }
    }
  }

  export default toNative(GenericGraph)
</script>

<style lang="scss" scoped>
  .btn-group {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -4%;
  }

  :fullscreen {
    background: white;
  }

  .fullscreenContainer {
    margin-top: 10px;
    padding-top: 10px;
  }
</style>
