<template>
  <div>
    <v-card>
      <v-card-title class="d-flex justify-space-between">
        <p class="text-overline">Feature flags</p>
        <v-btn
          class="overline"
          color="blue"
          variant="text"
          href="https://app.getguru.com/card/Tepd5yBc"
          target="_blank"
        >
          GURU Card
        </v-btn>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="featureFlags"
        :items-per-page="10"
        :sort-by="[{ key: 'lastChanged', order: 'asc' }]"
        no-data-text="No feature flags information available"
        sort-desc
        class="elevation 1"
      />
    </v-card>
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Watch, mixins, toNative } from 'vue-facing-decorator'

  import { DateTime } from '#mixins/dateTime'

  import { featuresHeaders } from '#views/users/constants'

  import { TimelineStore } from '#stores'

  @Component
  class Features extends mixins(DateTime) {
    @Prop() public uuid!: any

    public timelineStore = new TimelineStore()

    public headers = featuresHeaders

    public get featureFlags() {
      const featureFlags = this.timelineStore.features
      featureFlags.forEach((featureFlag: { name: string; enabled: string; extraInfo: any }) => {
        featureFlag.name = featureFlag.name.charAt(0).toUpperCase() + featureFlag.name.slice(1).replaceAll('_', ' ')
        featureFlag.enabled = featureFlag.enabled ? 'Yes' : 'No'
        if (featureFlag.extraInfo) {
          featureFlag.extraInfo = JSON.stringify(featureFlag.extraInfo).replace(/\\n/g, ' ').replace(/\\/g, ' ')
        }
      })
      return featureFlags
    }

    @Watch('uuid')
    protected onUuidChanged(_val: any, _prevVal: any) {
      this.timelineStore.getFeatureFlags({
        uuid: this.uuid,
      })
    }

    public mounted() {
      if (this.uuid) {
        this.onUuidChanged(this.uuid, this.uuid)
      }
    }
  }

  export default toNative(Features)
</script>

<style lang="scss" scoped>
  .active {
    color: #2196f3;
  }
</style>
