<template>
  <v-card min-width="100%">
    <v-card-title>
      <p class="text-overline">Warranty info</p>
      <v-spacer />
      <v-tooltip v-if="warrantyInfo && warrantyInfoTitles" location="top">
        <template #activator="{ props }">
          <v-btn
            v-if="copyContentsFeature"
            v-bind="props"
            variant="plain"
            class="mr-n4"
            icon="mdi-content-copy"
            color="grey"
            @click="copyContents()"
          />
        </template>
        {{ useClipboard.copied ? 'Copied' : 'Copy to clipboard' }}
      </v-tooltip>
    </v-card-title>

    <v-card-text v-if="warrantyInfo && warrantyInfoTitles" class="px-5 pb-5">
      <v-row>
        <v-col cols="12" md="6">
          <v-list dense>
            <v-list-item>
              <v-row>
                <v-col>{{ warrantyInfoTitles.warrantyStatus }}:</v-col>
                <v-col
                  class="align-end"
                  :class="warrantyInfo.warrantyStatus === 'In warranty' ? 'text-green' : 'text-red'"
                >
                  {{ warrantyInfo.warrantyStatus }}
                </v-col>
              </v-row>
            </v-list-item>
            <v-list-item>
              <v-row>
                <v-col>{{ warrantyInfoTitles.warrantyStartDate }}:</v-col>
                <v-col class="align-end">
                  {{
                    warrantyInfo.warrantyStartDate
                      ? formatDateTime(warrantyInfo.warrantyStartDate, 'DD MMM YYYY')
                      : 'Not yet available'
                  }}
                </v-col>
              </v-row>
            </v-list-item>
            <v-list-item>
              <v-row>
                <v-col>{{ warrantyInfoTitles.escStartDate }}:</v-col>
                <v-col class="align-end">
                  {{
                    warrantyInfo.escStartDate ? formatDateTime(warrantyInfo.escStartDate, 'DD MMM YYYY') : 'Not found'
                  }}
                </v-col>
              </v-row>
            </v-list-item>
            <v-list-item>
              <v-row class="align-center">
                <v-col cols="6">{{ warrantyInfoTitles.soTransactionId }}:</v-col>
                <v-col cols="4" class="align-end">
                  {{ warrantyInfo.soTransactionId ?? 'Not available' }}
                  <v-tooltip location="top">
                    <template #activator="{ props }">
                      <v-btn
                        v-if="warrantyInfo.soTransactionId"
                        v-bind="props"
                        variant="plain"
                        icon="mdi-content-copy"
                        color="grey"
                        @click="useClipboard.copy(warrantyInfo.soTransactionId)"
                      />
                    </template>
                    {{ useClipboard.copied ? 'Copied' : 'Copy SO transaction ID to clipboard' }}
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-list-item>
          </v-list>
        </v-col>

        <v-col cols="12" md="6">
          <v-list dense>
            <v-list-item>
              <v-row>
                <v-col>{{ warrantyInfoTitles.fulfillmentTimestamp }}:</v-col>
                <v-col class="align-end">
                  {{
                    warrantyInfo.fulfillmentTimestamp
                      ? formatDateTime(warrantyInfo.fulfillmentTimestamp, 'DD MMM YYYY')
                      : 'Not yet available'
                  }}
                </v-col>
              </v-row>
            </v-list-item>

            <v-list-item>
              <v-row>
                <v-col>{{ warrantyInfoTitles.warrantyEndDate }}:</v-col>
                <v-col class="align-end">
                  {{
                    warrantyInfo.warrantyEndDate
                      ? formatDateTime(warrantyInfo.warrantyEndDate, 'DD MMM YYYY')
                      : 'Not yet available'
                  }}
                </v-col>
              </v-row>
            </v-list-item>
            <v-list-item>
              <v-row>
                <v-col>{{ warrantyInfoTitles.escEndDate }}:</v-col>
                <v-col class="align-end">
                  {{ warrantyInfo.escEndDate ? formatDateTime(warrantyInfo.escEndDate, 'DD MMM YYYY') : 'Not found' }}
                </v-col>
              </v-row>
            </v-list-item>
            <v-list-item>
              <v-row>
                <v-col>{{ warrantyInfoTitles.retailPartner }}:</v-col>
                <v-col class="align-end">
                  <strong>
                    {{ warrantyInfo.retailPartner ?? 'Not found' }}
                  </strong>
                </v-col>
              </v-row>
            </v-list-item>
            <v-list-item>
              <v-row>
                <v-col>{{ warrantyInfoTitles.retailChannel }}:</v-col>
                <v-col class="align-end">
                  <strong>
                    {{ warrantyInfo.retailChannel ?? 'Not found' }}
                  </strong>
                </v-col>
              </v-row>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
      <WarrantyReturnInfoV3 :warranty-return-info="warrantyReturnInfoMulti[0]" :open-link-target="openLinkTarget" />
      <WarrantyReturnInfoV3
        v-if="warrantyReturnInfoMulti[1]"
        :warranty-return-info="warrantyReturnInfoMulti[1]"
        :open-link-target="openLinkTarget"
      />
    </v-card-text>

    <v-card-text v-else class="mt-n3">
      <div class="d-flex pa-4 align-center">
        <v-icon color="blue">mdi-alert</v-icon>
        <p class="mb-0 ml-3">No warranty info available for this ring</p>
      </div>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
  import { useClipboard } from '@vueuse/core'

  import { Component, Prop, Watch, mixins, toNative } from 'vue-facing-decorator'

  import { DateTime } from '#mixins/dateTime'

  import { WarrantyData } from '#views/rings/types'

  import { copyContentsFeature } from '#utils/darwinFeatureFlags'
  import {
    extractWarrantyInfoFromWarrantyData,
    getWarrantyInfoTitles,
    warrantyInfoToString,
  } from '#utils/warranty/extract.warrantyInfo'
  import { extractWarrantyReturnInfoFromWarrantyData } from '#utils/warranty/extract.warrantyReturnInfo'

  import { Nullable, WarrantyInfo, WarrantyInfoTitles, WarrantyReturnInfo } from '#types'

  @Component
  class WarrantyV3 extends mixins(DateTime) {
    @Prop() public warrantyData!: WarrantyData | null
    @Prop() public warrantyDataRingReplacedBy!: WarrantyData | null
    @Prop() public warrantyDataRingReplacementFor!: WarrantyData | null
    @Prop() public returns!: boolean
    @Prop() public zendeskNoteForRing!: string | null
    @Prop() public zendeskNoteForCharger!: string | null
    @Prop({ default: '_self' }) public openLinkTarget: '_blank' | '_self' = '_self'

    public useClipboard = useClipboard()

    public warrantyReturnInfoMulti: WarrantyReturnInfo[] = []
    public warrantyInfo: Nullable<WarrantyInfo> = null
    public warrantyInfoTitles: Nullable<WarrantyInfoTitles> = null

    public get copyContentsFeature() {
      return copyContentsFeature()
    }

    @Watch('warrantyData', { immediate: true })
    @Watch('warrantyDataRingReplacedBy')
    @Watch('warrantyDataRingReplacementFor')
    @Watch('zendeskNoteForRing')
    @Watch('zendeskNoteForCharger')
    public onDataChanged(): void {
      if (this.warrantyData) {
        this.warrantyReturnInfoMulti = extractWarrantyReturnInfoFromWarrantyData(
          this.warrantyData,
          this.zendeskNoteForRing,
          this.zendeskNoteForCharger,
          this.warrantyDataRingReplacementFor,
          this.warrantyDataRingReplacedBy,
        )
        this.warrantyInfo = extractWarrantyInfoFromWarrantyData(this.warrantyData)
        this.updateWarrantyInfoTitles()
      } else {
        this.warrantyReturnInfoMulti = []
        this.warrantyInfo = null
      }
    }

    public created() {
      this.updateWarrantyInfoTitles()
    }

    public updateWarrantyInfoTitles() {
      this.warrantyInfoTitles = getWarrantyInfoTitles(this.warrantyInfo)
    }

    public copyContents() {
      if (this.warrantyInfo && this.warrantyInfoTitles) {
        const dateFormat = 'DD MMM YYYY'
        const copyWarrantyInfo: WarrantyInfo = {
          ...this.warrantyInfo,
          ...{
            warrantyStartDate: this.warrantyInfo.warrantyStartDate
              ? this.formatDateTime(this.warrantyInfo.warrantyStartDate, dateFormat)
              : 'Not yet available',
            warrantyEndDate: this.warrantyInfo.warrantyEndDate
              ? this.formatDateTime(this.warrantyInfo.warrantyEndDate, dateFormat)
              : 'Not yet available',
            escStartDate: this.warrantyInfo.escStartDate
              ? this.formatDateTime(this.warrantyInfo.escStartDate, dateFormat)
              : 'Not found',
            escEndDate: this.warrantyInfo.escEndDate
              ? this.formatDateTime(this.warrantyInfo.escEndDate, dateFormat)
              : 'Not found',
            fulfillmentTimestamp: this.warrantyInfo.fulfillmentTimestamp
              ? this.formatDateTime(this.warrantyInfo.fulfillmentTimestamp, dateFormat)
              : 'Not yet available',
            retailPartner: this.warrantyInfo.retailPartner ?? 'Not found',
            retailChannel: this.warrantyInfo.retailChannel ?? 'Not found',
          },
        }
        const contents = warrantyInfoToString(copyWarrantyInfo, this.warrantyInfoTitles)
        this.useClipboard.copy(contents)
      }
    }
  }

  export default toNative(WarrantyV3)
</script>
