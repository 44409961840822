<template>
  <div v-show="showMessages" :max-width="maxWidth">
    <v-expansion-panels
      v-model="panels"
      static
      :color="bgColor"
      :readonly="singleMessageOnly"
      :expand-icon="expandIcon"
    >
      <v-expansion-panel value="errors">
        <v-expansion-panel-title style="font-size: 1rem">
          <template #default="{ expanded }">
            <v-row no-gutters>
              <v-col cols="1" class="text-left">
                <v-btn
                  icon="mdi-close"
                  color="transparent"
                  background-color="transparent"
                  variant="flat"
                  class="mt-n7 mb-n6"
                  slim
                  @click="closeButton()"
                />
              </v-col>
              <v-col>
                <span>{{ defaultMessage }}</span>
              </v-col>
              <v-col cols="2" class="ml-6">
                <span v-if="remainingMessages.length > 0 && !expanded">
                  {{ remainingMessages.length }} additional errors hidden
                </span>
              </v-col>
            </v-row>
          </template>
        </v-expansion-panel-title>
        <v-expansion-panel-text :class="bgColorClass" class="position-absolute mt-n2" style="width: 100%">
          <v-row no-gutters class="bg-surface-variant">
            <v-col cols="12">
              <v-sheet>
                <div
                  v-for="(error, index) in remainingMessages"
                  :key="index"
                  style="width: 100%"
                  :class="bgColorClass"
                  class="py-2"
                >
                  {{ error }}
                </div>
              </v-sheet>
            </v-col>
          </v-row>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { Notifications, Nullable } from '#types'

  /**
   * Notification panel.
   *
   * This component can be used do display any number of notifications. Currently only supported notification
   * type is error.
   *
   * Bugs:
   * - If first message is more than 1 line, expand icon is in the middle, trash can and message are on top.
   * - Not sure how this panel behaves with lots of errors, for example 50-100+
   * - Background colors of the panel and trash can are bit off.
   * - Trash can button doesn't actually delete the notifications, but just hides the notification panel.
   *
   * Feature ideas:
   * - Show short message by default, add separate "verboseMessage" for more details
   * - Add details to each error, such as HTTP response error codes, some trace ID etc which can be then more
   *   easily to track root cause of the error
   * - Button to copy full error to clipboard
   * - Add more notification types with suitable color scheme. Now we have only error (red).
   * - Each notification should have timestamp / age, and notifications should be sorted by age.
   * - There should be way to dismiss individual notifications
   * - If same error happens multiple times, we should show number of times this error occured.
   */
  @Component
  class NotificationPanel extends Vue {
    @Prop({ default: {} }) public notifications!: Notifications
    @Prop({ default: 1400 }) public maxWidth!: number

    public defaultMessage: Nullable<string> = null
    public remainingMessages: string[] = []
    public showMessages = false
    public bgColor = 'red'
    public bgColorClass = 'bg-red'
    public panels: string[] = []

    public get singleMessageOnly(): boolean {
      return this.remainingMessages.length == 0
    }

    public get expandIcon(): string {
      if (this.singleMessageOnly) {
        return ''
      }
      return 'mdi-chevron-down'
    }

    @Watch('notifications', { deep: true, immediate: true })
    public onNotificationsUpdated() {
      this.refreshNotifications()
    }

    public refreshNotifications() {
      let firstMessage = true
      let remainingMessages: string[] = []
      for (const [_key, notification] of Object.entries(this.notifications)) {
        if (firstMessage) {
          this.defaultMessage = notification.message
        } else {
          remainingMessages.push(notification.message)
        }
        firstMessage = false
      }
      if (remainingMessages.length > 0) {
        this.remainingMessages = remainingMessages
      }
      if (this.defaultMessage) {
        this.showMessages = true
      }
    }

    public closeButton() {
      this.showMessages = false
      this.remainingMessages = []
      this.defaultMessage = null
    }

    public mounted() {
      this.refreshNotifications()
    }
  }

  export default toNative(NotificationPanel)
</script>
