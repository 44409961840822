<template>
  <v-container class="viewport">
    <v-tabs v-model="tab" class="mt-3">
      <v-tab v-if="user" value="reproductive-hormone-answers" exact>Reproductive hormone answers</v-tab>
      <v-tab v-if="user && arrhythmiaToolEnabled" value="arrhythmia" exact>Arrhythmia Tool</v-tab>
    </v-tabs>
  </v-container>

  <div style="overflow: hidden">
    <v-window v-model="tab" direction="vertical">
      <v-window-item v-if="user" value="reproductive-hormone-answers">
        <CycleInsights v-model:ring="ring" class="viewport" :user="user" />
      </v-window-item>
      <v-window-item v-if="arrhythmiaToolEnabled && user" value="arrhythmia">
        <ArrhythmiaTool v-model:ring="ring" :user="user" />
      </v-window-item>
    </v-window>
  </div>
</template>

<script lang="ts">
  import { Component, Prop, mixins, toNative } from 'vue-facing-decorator'

  import { DateTime } from '#mixins/dateTime'

  import { arrhythmiaToolEnabled } from '#utils/darwinFeatureFlags'

  import { AppStore } from '#stores'

  @Component
  class Health extends mixins(DateTime) {
    @Prop() public user!: any
    @Prop() public ring!: any

    public tab = 'reproductive-hormone-answers'

    private appStore = new AppStore()

    public get arrhythmiaToolEnabled() {
      return arrhythmiaToolEnabled() && this.rights.includes('allowArrhythmiaToolAccess')
    }

    public get rights() {
      return this.appStore.getRights || []
    }
  }

  export default toNative(Health)
</script>
