<template>
  <!-- eslint-disable vue/v-on-handler-style -->
  <v-app-bar title="Oura shop orders" />

  <v-container>
    <v-row>
      <v-col cols="12" md="9">
        <div class="text-h5 font-weight-light">Access Oura shop ring orders information</div>

        <div class="text-subtitle-2 text-medium-emphasis font-weight-light">
          Search and view information / status for Oura ring orders
        </div>
      </v-col>

      <v-col md="3" cols="12" class="text-right">
        <v-btn color="primary" text="Run search" :disabled="!search || waitingForData" @click="performSearch()" />
      </v-col>
    </v-row>

    <v-row class="mt-4">
      <v-col cols="7">
        <v-text-field
          v-model.trim="search"
          autofocus
          append-inner-icon="mdi-database-search"
          label="Search ring orders"
          :placeholder="`Enter keyword and press enter to search...`"
          :error="!searchIsValid"
          :error-messages="searchErrors"
          hide-details="auto"
          @keyup.enter="performSearch()"
        />
      </v-col>
      <v-col cols="5">
        <v-select v-model="searchType" :items="searchTypes" label="Search type" />
      </v-col>
    </v-row>

    <v-sheet class="mt-8">
      <v-data-table
        :items="searchResults"
        :items-per-page="itemsPerPage"
        :no-data-text="waitingForData ? 'Loading search results...' : 'No matches / results found'"
        :headers="headers"
        hide-default-footer
        :loading="waitingForData"
        item-value="object.orderId"
        :row-props="
          (row) => {
            const isSelectedRow = row.item.object.orderId === selectedOrder?.orderId
            return isSelectedRow ? { class: 'bg-pink-lighten-5' } : {}
          }
        "
        @click:row="
          (_: any, row: any) => {
            selectedOrder = row.item.object
          }
        "
      >
        <template #[`item.object.createdAt`]="{ item }">
          {{ $dayjs(item.object.createdAt).format('HH:mm - DD MMM YYYY') }}
        </template>
      </v-data-table>
    </v-sheet>

    <v-card v-if="selectedOrder" class="mt-8">
      <div class="text-subtitle-2 py-4">
        <JSONView :data="selectedOrder" />
      </div>
    </v-card>
  </v-container>
</template>

<script lang="ts">
  import { validate as isUUIDValid } from 'uuid'

  import { Component, mixins, toNative } from 'vue-facing-decorator'

  import { RouteParams } from '@jouzen/outo-apps-toolkit'

  import { OrderSearchType, orderSearchHeaders, orderSearchTypes } from '#views/order/constants'

  import { isEmailValid } from '#utils/user/emailValidation'

  import { OrderStore } from '#stores'
  import { OrderRaw } from '#stores/order.types'

  import { Nullable } from '#types'

  @Component
  class OrderSearch extends mixins(RouteParams) {
    public search: Nullable<string> = null
    public searchIsValid: boolean = true
    public searchErrors: string[] = []

    public searchTypes = orderSearchTypes
    public searchType: OrderSearchType = 'email'

    public headers = orderSearchHeaders
    public itemsPerPage = 50
    public routeParams: any = {
      search: '',
      searchType: '',
    }
    public selectedOrder: any = null

    public orderStore = new OrderStore()

    private env = import.meta.env.VITE_APP_ENV

    public get searchResults(): OrderRaw[] {
      return this.orderStore.orders
    }

    public get waitingForData(): boolean {
      return this.orderStore.waitingForData()
    }

    public mounted() {
      this.orderStore.orders = []
      this.parseRouteParams()
      this.performSearch()
    }

    /**
     * Validates given search keyword.
     * @private
     */
    public validateSearch() {
      this.searchErrors = []

      if (this.search && this.searchType === 'email' && !isEmailValid(this.search)) {
        this.searchIsValid = false
        this.searchErrors.push('Invalid email.')
        return
      }
      if (this.search && this.searchType === 'orderId' && !isUUIDValid(this.search)) {
        this.searchIsValid = false
        this.searchErrors.push('Invalid order ID.')
        return
      }

      this.searchIsValid = true
    }

    public performSearch() {
      this.validateSearch()
      if (!this.searchIsValid || !this.search) {
        return
      }

      this.orderStore.getOrders(this.search.toLowerCase(), this.searchType)

      this.selectedOrder = null
      this.routeParams.search = this.search
      this.routeParams.searchType = this.searchType
    }

    private parseRouteParams() {
      if (this.routeParams.search) {
        this.search = this.routeParams.search
      }
      if (this.routeParams.searchType) {
        this.searchType = this.routeParams.searchType
      }
    }
  }

  export default toNative(OrderSearch)
</script>
