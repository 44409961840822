<template>
  <v-card min-width="100%">
    <v-card-title>
      <p class="text-overline">Connected Accounts</p>
    </v-card-title>
    <v-container v-if="connectedAccountChangeEvents.length > 0">
      <v-timeline v-if="!dataWait" class="ml-4" density="comfortable" side="end">
        <template v-for="(accountChange, index) in connectedAccountChangeEvents" :key="index">
          <v-timeline-item size="small" :dot-color="accountChange.eventColor">
            <v-row class="d-flex justify-space-between flex-grow-1">
              <div>
                {{ accountChange.message }}
                <br />
                <em>{{ accountChange.helpText }}</em>
                <br />
              </div>
              <div class="flex-shrink-0 mr-10">
                {{ formatDateTime(accountChange.time, 'DD MMM YYYY') }}
              </div>
            </v-row>
          </v-timeline-item>
        </template>
      </v-timeline>
    </v-container>

    <v-card v-if="!dataWait && connectedAccountChangeEvents.length < 1">
      <v-card-text class="mt-n3">
        <div class="d-flex pa-4">
          <v-icon color="blue">mdi-alert</v-icon>
          <p class="mb-0 ml-3">No connected accounts found for this ring</p>
        </div>
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script lang="ts">
  import { Component, Prop, Watch, mixins, toNative } from 'vue-facing-decorator'

  import { DateTime } from '#mixins/dateTime'

  import { ConnectedAccount, ConnectedAccountChangeEvent } from '#views/rings/types'

  import { getConnectedAccountChangeEvents } from '#utils/ring/connectedAccounts'

  import { RingsStore, UserStore } from '#stores'

  @Component
  class ConnectedAccounts extends mixins(DateTime) {
    @Prop() public ringSerial!: string

    public ringsStore = new RingsStore()
    public userStore = new UserStore()

    public get dataWait() {
      return this.ringsStore.dataWait
    }

    private get connectedAccounts(): ConnectedAccount[] | null {
      return this.ringsStore.connectedAccounts
    }

    @Watch('ringSerial', { immediate: true })
    protected onRingChanged() {
      if (this.ringSerial) {
        this.ringsStore.getConnectedAccounts(this.ringSerial)
      }
    }

    public formatMessage(syncMessage: string) {
      return syncMessage.replace('-', 'Last synced to this account')
    }

    public get connectedAccountChangeEvents(): ConnectedAccountChangeEvent[] {
      return getConnectedAccountChangeEvents(this.connectedAccounts, this.userStore.user?.uuid ?? null)
    }
  }

  export default toNative(ConnectedAccounts)
</script>

<style lang="scss" scoped></style>
