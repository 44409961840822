<template>
  <v-container class="viewport mb-4">
    <v-alert v-if="!sensitiveDataVisible" elevation="2" type="info" class="mb-4 position-relative">
      You can enable ASSA data browser by enabling the showing of sensitive data from the top bar
    </v-alert>
    <v-card :loading="dataWait" class="pa-4" style="overflow: hidden; width: 100%" :disabled="!sensitiveDataVisible">
      <v-card-title class="pa-0">
        <div class="text-overline">ASSA data Browser</div>
      </v-card-title>
      <v-row>
        <v-col cols="4">
          <v-autocomplete
            v-model="selected"
            :items="models"
            label="Select an ASSA model"
            class="mt-3"
            variant="solo-inverted"
            density="compact"
            outlined
            hide-details
            dense
            clearable
          />
        </v-col>
        <v-col cols="5">
          <DateRangePicker
            v-model:start-date="startDate"
            v-model:end-date="endDate"
            :max-day-range="7"
            @valid="datesAreValid = $event"
          />
        </v-col>
        <v-col cols="2">
          <v-btn :disabled="searchDisabled" color="primary" class="mt-3" @click="search()">Search</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-alert
            v-if="error"
            variant="outlined"
            border="start"
            colored-border
            elevation="2"
            type="error"
            class="mt-n4 mb-8"
            style="position: relative"
          >
            {{ error }}
          </v-alert>

          <JSONView v-else :data="sortedData" />
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script lang="ts">
  import sortKeys from 'sort-keys'

  import { Component, Prop, mixins, toNative } from 'vue-facing-decorator'

  import { DateTime } from '#mixins/dateTime'

  import { AppStore, DataBrowserStore } from '#stores'

  @Component
  class UserDataBrowser extends mixins(DateTime) {
    @Prop() public uuid!: string
    @Prop() public ringSerial!: string

    public datesAreValid = true

    public startDate = ''
    public endDate = ''

    public selected: string = ''

    public dataBrowserStore = new DataBrowserStore()
    public appStore = new AppStore()

    public get dataWait(): boolean {
      return this.dataBrowserStore.dataWait
    }

    public get models(): string[] {
      return this.dataBrowserStore.assaModels
    }

    public get data() {
      return this.dataBrowserStore.assaData[this.uuid]
    }

    public get sortedData(): Object | null {
      return this.data ? sortKeys(this.data, { deep: true }) : null
    }

    public get error() {
      return this.dataBrowserStore.assaDataError
    }

    public mounted() {
      this.startDate = this.$dayjs().format('YYYY-MM-DD')
      this.endDate = this.$dayjs().format('YYYY-MM-DD')

      this.dataBrowserStore.getModels()
      this.dataBrowserStore.assaDataError = ''
    }

    public async search() {
      await this.dataBrowserStore.getData(this.selected, {
        timestampFrom: this.startDate,
        timestampTo: this.endDate,
        userUuids: this.uuid,
      })
    }

    public get searchDisabled() {
      return !this.selected || !this.datesAreValid || this.dataWait
    }

    public get sensitiveDataVisible() {
      return this.appStore.sensitiveDataVisible
    }
  }

  export default toNative(UserDataBrowser)
</script>
