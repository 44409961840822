<template>
  <v-container class="viewport">
    <Info v-if="user" v-model:ring="ring" :user="user" :rings="user.rings" />
    <NoRingBanner v-if="!user.rings.length" class="mt-8" />
    <LastSyncBanner v-if="ring" :ring="ring" class="mt-8" />
    <DiagnosticsV3
      v-if="diagnosticsV3FeatureEnabled"
      :user="user"
      :ring="ring"
      :sidecar="false"
      class="viewport mt-8"
    />
    <DiagnosticsV2 v-else :user="user" :ring="ring" class="mt-8" />
  </v-container>

  <template v-if="user">
    <div v-if="summaryV2FeatureEnabled" class="mt-8 mb-4">
      <v-row class="viewport pr-8 flex d-flex flex-row-reverse">
        <v-switch v-model="showSummaryV2" label="Use Summary Data Report V2" class="reversed" />
      </v-row>
    </div>
    <SummaryV2 v-if="showSummaryV2 && summaryV2FeatureEnabled" :user-uuid="user.uuid" />
    <Summary v-else :uuid="user.uuid" :ring="ring" :rings="user.rings" :summary-fields="summaryFields" />
  </template>
</template>

<script lang="ts">
  import { Component, Prop, Watch, mixins, toNative } from 'vue-facing-decorator'

  import { logEvent } from 'firebase/analytics'

  import { DateTime } from '#mixins/dateTime'

  import { summaryFieldsDashboardv2 } from '#views/users/constants'

  import { diagnosticsV3Feature, summaryV2Feature } from '#utils/darwinFeatureFlags'

  import { DiagnosticsStore } from '#stores'

  import { Member, Ring } from '#types'

  @Component
  class DashboardV2 extends mixins(DateTime) {
    @Prop() public user!: Member
    @Prop() public ring!: Ring

    public diagnosticsStore = new DiagnosticsStore()
    public showSummaryV2 = false

    public created() {
      logEvent(this.$analytics, 'page_view', {
        page_title: 'Dashboard',
        page_location: window.location.toString().split('?')[0],
      })
    }

    /**
     * Which summary data report fields to show
     */
    public get summaryFields(): string[] {
      return summaryFieldsDashboardv2
    }

    public get diagnosticsV3FeatureEnabled() {
      return diagnosticsV3Feature()
    }

    public get summaryV2FeatureEnabled() {
      return summaryV2Feature()
    }

    @Watch('summaryV2FeatureEnabled', { immediate: true })
    protected onSummaryV2FeatureEnabledChanged(val: boolean) {
      this.showSummaryV2 = val
    }
  }

  export default toNative(DashboardV2)
</script>

<style lang="scss" scoped>
  :deep(.legacy) {
    .v-select__selection {
      color: red !important;
    }
  }
  :deep(.v-banner__content) {
    font-size: large;
  }
</style>
