<template>
  <v-card min-width="100%">
    <v-card-title>
      <p class="text-overline">Device info</p>
      <v-spacer />
      <v-tooltip location="top">
        <template #activator="{ props }">
          <v-btn
            v-bind="props"
            color="grey"
            variant="plain"
            class="mr-n4"
            icon="mdi-content-copy"
            @click="copyContents()"
          />
        </template>
        {{ useClipboard.copied ? 'Copied' : 'Copy to clipboard' }}
      </v-tooltip>
    </v-card-title>
    <v-card-text v-if="deviceInfo" class="pt-0">
      <SingleDeviceInfo :single-device-info="deviceInfo.ring" />
      <SingleDeviceInfo :single-device-info="deviceInfo.charger" />
      <div v-if="deviceInfo.description" class="ml-n2 mt-n3">
        <span class="text-subtitle-1">Description: {{ deviceInfo.description }}</span>
      </div>
    </v-card-text>
    <v-card-text v-else>
      <div class="d-flex pa-4">
        <v-icon color="blue">mdi-alert</v-icon>
        <p class="mb-0 ml-3">
          No device info available for this ring. Possible reason could be that ring is not yet paired to a phone.
        </p>
      </div>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
  import { useClipboard } from '@vueuse/core'

  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { BaseDeviceInfo, DeviceInfo, DeviceInfoCharger, DeviceInfoRing } from '#types'

  @Component
  class DeviceV2 extends Vue {
    @Prop() public deviceInfo!: DeviceInfo

    public useClipboard = useClipboard()

    public copyContents() {
      const ring = this.deviceInfo.ring
      const charger = this.deviceInfo.charger

      const baseFields: [string, keyof BaseDeviceInfo][] = [
        ['Factory', 'factory'],
        ['HW Major', 'hwMajor'],
        ['HW Minor', 'hwMinor'],
        ['Product', 'product'],
        ['Size', 'size'],
        ['Color', 'color'],
        ['Design', 'design'],
        ['MFG year', 'manufacturingYear'],
        ['MFG week', 'manufacturingWeek'],
      ]

      const ringFields: [string, keyof DeviceInfoRing][] = [
        ['Ring S/N', 'serialNumber'],
        ['MAC', 'macAddress'],
        ...baseFields,
      ]

      const chargerFields: [string, keyof DeviceInfoCharger][] = [['Charger S/N', 'serialNumber'], ...baseFields]

      const devicesAndFields = [
        [ring, ringFields],
        [charger, chargerFields],
      ] as const

      let contents = ''
      for (const [device, fields] of devicesAndFields) {
        if (device) {
          for (const [name, key] of fields) {
            contents += `${name}: ${device[key] ?? 'Unknown'}\n`
          }
        }
      }

      this.useClipboard.copy(contents)
    }
  }

  export default toNative(DeviceV2)
</script>
