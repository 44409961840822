<template>
  <div>
    <v-card>
      <v-card-title class="d-flex justify-space-between">
        <p class="text-overline">Credit memos (Refunds/Compensations)</p>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="creditMemos"
        :items-per-page="10"
        :sort-by="[{ key: 'issueStartDate', order: 'asc' }]"
        sort-desc
        item-key="date"
        class="elevation 1"
        no-data-text="No Credit memo data available"
      >
        <template #[`item.view-credit-memo`]="{ item }">
          <v-btn icon @click="showCreditMemo(item.id)">
            <v-icon>mdi-receipt</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <PDFDialog v-model="creditMemoVisible" :pdf="creditMemo" />
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { creditMemoHeaders } from '#views/users/constants'

  import { SubscriptionStore } from '#stores'

  @Component
  class CreditMemos extends Vue {
    @Prop() public user!: any

    public subscriptionStore = new SubscriptionStore()
    public headers = creditMemoHeaders
    public creditMemoVisible = false

    public get subscriptions() {
      return this.subscriptionStore.subscriptions
    }

    public get creditMemos() {
      return this.subscriptionStore.creditMemos
    }

    public get creditMemo() {
      const creditMemo = this.subscriptionStore.creditMemo
      if (!creditMemo) {
        return null
      }
      return URL.createObjectURL(creditMemo)
    }

    @Watch('subscriptions')
    private subscriptionsChanged() {
      if (this.user) {
        this.subscriptionStore.getCreditMemos({ userId: this.user?.uuid })
      }
    }

    public async showCreditMemo(memoId: string) {
      await this.subscriptionStore.getCreditMemo({ userId: this.user?.uuid, memoId: memoId })
      this.creditMemoVisible = true
    }
  }

  export default toNative(CreditMemos)
</script>
<style lang="scss" scoped></style>
