<template>
  <div class="pa-0">
    <v-card>
      <v-card-title>
        <p v-if="title" class="text-overline">
          {{ title }}
        </p>
      </v-card-title>

      <v-data-table
        :items="events"
        :headers="headers"
        :items-per-page="1000"
        :sort-by="[{ key: 'date', order: 'desc' }]"
        item-key="index"
        :loading="dataWait"
        disable-pagination
        hide-default-footer
        class="elevation-1"
      >
        <template #[`item.date`]="{ item }">
          <div v-if="item.date" class="text-no-wrap">
            {{ formatDateTime(item.date, 'D MMM YYYY', 0) }}
          </div>
        </template>
        <template #[`item.Battery_consumption_(day)%`]="{ item }">
          <span :class="{ hightlight: item[formatKey('Battery consumption (day)%')] > 2 }">
            {{ item[formatKey('Battery consumption (day)%')] }}
          </span>
        </template>
        <template #[`item.Battery_consumption_(night)%`]="{ item }">
          <span :class="{ hightlight: item[formatKey('Battery consumption (night)%')] > 2.5 }">
            {{ item[formatKey('Battery consumption (night)%')] }}
          </span>
        </template>
        <template #[`item.Charging_slope`]="{ item }">
          <span :class="{ hightlight: item[formatKey('Charging slope')] > chargingSlopeThreshold }">
            {{ item[formatKey('Charging slope')] ? parseFloat(item[formatKey('Charging slope')]).toFixed(2) : '' }}
          </span>
        </template>
        <template #[`item.Battery_health`]="{ item }">
          <span :class="{ hightlight: item[formatKey('Battery health')] < fgHealthThreshold }">
            {{ item[formatKey('Battery health')] }}
          </span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script lang="ts">
  import { groupBy, map } from 'lodash-es'

  import { Component, Prop, Watch, mixins, toNative } from 'vue-facing-decorator'

  import { getValue } from '@firebase/remote-config'

  import { DateTime } from '#mixins/dateTime'
  import { EventData } from '#mixins/eventData'

  import { chargingSlopeFailThreshold, fgHealthFailThreshold, summaryFields } from '#views/users/constants'

  import { AppStore, TimelineStore, UserStore } from '#stores'

  @Component({
    mixins: [EventData],
  })
  class Table extends mixins(DateTime, EventData) {
    @Prop() public data!: any[]
    @Prop() public end!: any[]
    @Prop() public start!: any[]
    @Prop() public rings!: any[]
    @Prop() public title!: string
    @Prop() public filters!: string[];
    // hack for mixins
    [x: string]: any

    public timelineStore = new TimelineStore()
    public userStore = new UserStore()
    public appStore = new AppStore()

    public events: any[] = []
    public headers: any[] = []

    public get dataWait() {
      return this.timelineStore.dataWait
    }

    public get rights() {
      return this.appStore.getRights
    }

    public get access() {
      return this.userStore.user?.ownerAccess || this.userStore.user?.sharedAccess || this.userStore.user?.supportAccess
    }

    public chargingSlopeThreshold = chargingSlopeFailThreshold
    public fgHealthThreshold = fgHealthFailThreshold

    @Watch('data')
    protected onDataChanged(_val: any[], _oldVal: any[]) {
      this.updateData()
    }

    @Watch('userTimezone')
    protected onUserTimezoneChanged(_val: string, _oldVal: string) {
      this.updateData()
    }

    @Watch('prefsTimezoneSetting')
    protected onTimezoneSettingChanged(_val: string, _oldVal: string) {
      this.updateData()
    }

    @Watch('filters')
    protected onFiltersChanged(_val: any[], _oldVal: any[]) {
      this.updateData()
    }

    public mounted() {
      this.updateData()
    }

    private updateData() {
      let filtersSorted = [...this.filters].sort((a, b) => {
        if (summaryFields.includes(a) && summaryFields.includes(b)) {
          return summaryFields.indexOf(a) - summaryFields.indexOf(b)
        }
        return 0
      })

      if (!getValue(this.$remoteConfig, 'batteryHealthSummaryInfo').asBoolean()) {
        filtersSorted = filtersSorted.filter((x) => {
          return x !== 'Battery health'
        })
      }

      if (!this.data) {
        return
      }

      const data = this.splitSummaries(this.data, filtersSorted, 'table')

      const days = groupBy(data, (data) => this.$dayjs(data.timestamp).startOf('day').format())

      let index = 0

      const headers: string[] = ['date']

      if (filtersSorted.length) {
        if (this.personal || this.rights.includes('allowSensitiveDataAccess')) {
          headers.push('Timezone')
        } else {
          headers.push('Timezone change')
        }
      }

      this.events = map(days, (day, _key) => {
        const groups = groupBy(day, (day) => day.field)

        const time = new Date(day[0].timestamp)

        const data: any = { index: index++, date: time.getTime() }

        map(groups, (group, _key) => {
          const field = group[0].field
            .replace('Version', 'Updated')
            .replace('osUpdated', 'deviceUpdated')
            .replace('ringRegistrations', 'devicesEvents')
            .replace('accountEmails', 'accountEvents')

          if (!headers.includes(group[0].field) && (filtersSorted.includes(field) || group[0].summary)) {
            headers.push(group[0].field)
          }

          if (!headers.includes(group[0].field)) {
            headers.push(group[0].field)
          }

          if (field === 'Debug info') {
            data[group[0].field] = group
              .filter((g) => g.value)
              .map((g) => g.value)
              .join(', ')
          } else if (field === 'batteryHealth' || field === 'chargeCycles' || field === 'dischargeCycles') {
            data[group[0].field] = group
              .filter((g) => g.value)
              .map((g) => this.getValue(g, g, this.rings, 'table'))
              .join(', ')
          } else {
            data[group[0].field] = this.getValue(group[0], group[group.length - 1], this.rings, 'table')
          }
        })

        return data
      })

      const order = filtersSorted

      this.headers = headers
        .map((header) => {
          return {
            title: this.getLabel({ field: header }),
            key: header,
          }
        })
        .sort((a: any, b: any) => {
          const ai = order.findIndex((o) => a.key.includes(this.formatKey(o)))
          const bi = order.findIndex((o) => b.key.includes(this.formatKey(o)))

          if (
            ai > bi ||
            (a.key.includes('end') && b.key.includes('start')) ||
            (a.key.includes('night') && b.key.includes('day'))
          ) {
            return 1
          } else if (
            ai < bi ||
            (a.key.includes('start') && b.key.includes('end')) ||
            (a.key.includes('day') && b.key.includes('night'))
          ) {
            return -1
          } else {
            return 0
          }
        })
    }
  }

  export default toNative(Table)
</script>

<style lang="scss" scoped>
  :deep(.v-data-table .v-data-footer__select) {
    display: none;
  }

  .hightlight {
    color: red;
    font-weight: 700;
  }
</style>
