<template>
  <v-container class="viewport">
    <Info v-model:ring="ring" class="viewport" :user="user" :rings="user.rings" />

    <Timeline
      v-if="user"
      ref="timeline"
      class="mt-8"
      :uuid="user.uuid"
      :ring="ring"
      :title="'Timeline'"
      :rings="user.rings"
      :end="end"
      :start="start"
      @open-diff="openDiff($data)"
    />

    <div ref="endofpage" />
  </v-container>
</template>

<script lang="ts">
  import { Component, Emit, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { logEvent } from '@firebase/analytics'

  import { Ring } from '#types'

  @Component
  class Troubleshooter extends Vue {
    @Prop() public user!: any
    @Prop() public ring!: any
    @Prop() public diffEnd!: any
    @Prop() public diffStart!: any
    @Prop() public showDiff!: any

    public declare $refs: {
      timeline: HTMLElement
      endofpage: HTMLElement
    }

    public end: number | null = null
    public start: number | null = null

    @Emit('update:diffStart')
    public updateDiffStart(time: string) {
      return time
    }

    @Emit('update:diffEnd')
    public updateDiffEnd(time: string) {
      return time
    }

    @Emit('update:ring')
    public updateRing(ring: Ring) {
      return ring
    }

    @Emit('update:showDiff')
    public updateShowDiff(diff: boolean) {
      return diff
    }

    @Watch('ring')
    protected onRingChanged(_val: any, _prevVal: any) {
      this.updateRing(this.ring)
    }

    public created() {
      logEvent(this.$analytics, 'page_view', {
        page_title: 'Troubleshooter',
        page_location: window.location.toString().split('?')[0],
      })
    }

    public openDiff(data: any) {
      if (data) {
        this.updateDiffStart(data[0].previousTimestamp)
        this.updateDiffEnd(data[data.length - 1].timestamp)
      } else {
        this.updateDiffStart('')
        this.updateDiffEnd('')
      }

      this.updateShowDiff(true)
    }
  }

  export default toNative(Troubleshooter)
</script>

<style lang="scss" scoped></style>
