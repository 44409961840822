<template>
  <v-container :class="{ viewport: !expanded }">
    <v-card :loading="dataWait">
      <v-icon
        :icon="expanded ? 'mdi-arrow-collapse' : 'mdi-arrow-expand'"
        style="position: absolute; right: 8px; top: 16px; z-index: 1"
        @click="expanded = !expanded"
      />
      <v-card-title>
        <p class="text-overline">Arrhythmia</p>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="5">
            <DatePicker v-model="toDate" label="To date" @valid="toDateValid = $event" />
          </v-col>
          <v-col cols="2" offset="5">
            <v-btn :disabled="!toDateValid" @click="runArrhythmiaTool()">Run</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <PlotlyView :data="ibiPlotData" :full-screen-supported="true" title="IBI Data" />
          </v-col>
        </v-row>
        <v-row>
          <v-col v-for="(graph, index) in ppgPlotData" :key="index" cols="12">
            <PlotlyView :data="graph" :full-screen-supported="true" title="PPG Data" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script lang="ts">
  import { Component, Prop, mixins, toNative } from 'vue-facing-decorator'

  import { DateTime } from '#mixins/dateTime'

  import { EMPTY_GRAPH } from '#utils/generic-graph/generic-graph'

  import { ArrhythmiaStore } from '#stores'

  @Component
  class ArrhythmiaTool extends mixins(DateTime) {
    @Prop() public user!: any
    public toDate = ''
    public toDateValid = false
    public expanded = false

    public arrhythmiaStore = new ArrhythmiaStore()

    public get dataWait() {
      return this.arrhythmiaStore.dataWait
    }

    public get ibiPlotData() {
      return this.arrhythmiaStore.ibiPlotData ? this.arrhythmiaStore.ibiPlotData : EMPTY_GRAPH
    }

    public get ppgPlotData() {
      return this.arrhythmiaStore.ppgPlotData?.length ? this.arrhythmiaStore.ppgPlotData : [EMPTY_GRAPH]
    }

    public mounted() {
      this.toDate = this.$dayjs().format('YYYY-MM-DD')

      this.arrhythmiaStore.resetData()
    }

    public async runArrhythmiaTool() {
      await this.arrhythmiaStore.getIbiPlotData(this.user.uuid, this.toDate)
      await this.arrhythmiaStore.getPPGPlotData(this.user.uuid, this.toDate)
    }
  }

  export default toNative(ArrhythmiaTool)
</script>
