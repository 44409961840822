<template>
  <v-container>
    <div v-if="reproductiveHormoneAnswerPermission">
      <v-card>
        <v-card-title>
          <p class="text-overline">Reproductive Hormone Answer</p>
        </v-card-title>
        <v-data-table
          :items="reproductiveHormoneAnswer ? [reproductiveHormoneAnswer] : []"
          :headers="headers"
          no-data-text="No data available"
          hide-default-footer
          hide-default-page-select
        >
          <template #item="{ item }">
            <tr>
              <td>{{ item.day }}</td>
              <td>
                <v-icon :class="getIconInfo(item.fertilityTreatments).class">
                  {{ getIconInfo(item.fertilityTreatments).icon }}
                </v-icon>
              </td>
              <td>{{ capitalize(item.hormonalContraception) }}</td>
              <td>
                <v-icon :class="getIconInfo(item.hormoneReplacementTherapy).class">
                  {{ getIconInfo(item.hormoneReplacementTherapy).icon }}
                </v-icon>
              </td>
              <td>
                <v-icon :class="getIconInfo(item.other).class">
                  {{ getIconInfo(item.other).icon }}
                </v-icon>
              </td>
              <td>
                <v-icon :class="getIconInfo(item.preferNotToAnswer).class">
                  {{ getIconInfo(item.preferNotToAnswer).icon }}
                </v-icon>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </div>
    <div v-else class="pt-8">
      <v-alert
        variant="outlined"
        border="start"
        colored-border
        elevation="2"
        type="info"
        class="mt-n4 mb-8"
        style="position: relative"
      >
        <p>You do not have access rights to view sensitive data.</p>
      </v-alert>
    </div>
  </v-container>
</template>

<script lang="ts">
  import { Component, Prop, mixins, toNative } from 'vue-facing-decorator'

  import { DateTime } from '#mixins/dateTime'

  import { reproductiveHormoneAnswerHeaders } from '#views/users/constants'

  import { getIconAndClass } from '#utils/user/cycleInsights'

  import { AppStore, UserStore } from '#stores'

  import { ReproductiveHormoneAnswer } from '#types'

  @Component
  class CycleInsights extends mixins(DateTime) {
    @Prop() public user!: any
    @Prop() public ring!: any

    public userStore = new UserStore()
    public appStore = new AppStore()

    public headers = reproductiveHormoneAnswerHeaders

    public get reproductiveHormoneAnswer(): ReproductiveHormoneAnswer | null {
      return this.userStore.reproductiveHormoneAnswer || null
    }

    public get rights(): string[] {
      return this.appStore.getRights
    }
    public get access() {
      return this.userStore.user?.ownerAccess || this.userStore.user?.sharedAccess || this.userStore.user?.supportAccess
    }

    public get sensitiveDataVisible(): boolean {
      return this.appStore.sensitiveDataVisible
    }

    public get reproductiveHormoneAnswerPermission(): boolean {
      return !!(this.rights.includes('allowSensitiveDataAccess') || this.access) && this.sensitiveDataVisible
    }

    public mounted() {
      if (this.reproductiveHormoneAnswerPermission) {
        this.userStore.getReproductiveHormoneAnswer(this.user.uuid)
      }
    }

    public getIconInfo(value: boolean | null): { icon: string; class: string } {
      return getIconAndClass(value)
    }

    public capitalize(value: string | null): string {
      if (value) {
        return value.charAt(0).toUpperCase() + value.slice(1)
      }
      return '-'
    }
  }

  export default toNative(CycleInsights)
</script>
