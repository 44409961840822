<template>
  <v-container class="viewport">
    <Info v-if="user" v-model:ring="ring" :user="user" :rings="user.rings" />

    <div class="d-flex justify-center">
      <v-progress-circular v-show="dataWait" indeterminate color="pink" :size="60" />
    </div>

    <div v-show="!dataWait">
      <ClientV2 v-if="ring && user" class="mt-8" :member="user" :ring="ring" />

      <DeviceV2 class="mt-8" :device-info="deviceInfo" />

      <WarrantyV3
        v-if="warrantyData"
        class="mt-8"
        :warranty-data="warrantyData"
        :warranty-data-ring-replaced-by="warrantyDataRingReplacedBy"
        :warranty-data-ring-replacement-for="warrantyDataRingReplacementFor"
        :zendesk-note-for-ring="zendeskNotes.ringNote"
        :zendesk-note-for-charger="zendeskNotes.chargerNote"
        open-link-target="_blank"
      />

      <ConnectedAccounts v-if="ring && ring.serialNumber" class="mt-8" :ring-serial="ring.serialNumber" />
    </div>
  </v-container>
</template>

<script lang="ts">
  import { Component, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { logEvent } from 'firebase/analytics'

  import { WarrantyData } from '#views/rings/types'

  import { extractDeviceInfoFromRingAndWarrantyDetails } from '#utils/device-info/extract'

  import { RingsStore, TimelineStore, UserStore } from '#stores'
  import { UserZendeskTicketContents } from '#stores/user.types'

  import { DeviceInfo, Member, Nullable, Ring, RingDetails } from '#types'

  @Component
  class DevicesInfo extends Vue {
    @Prop() public user!: Member
    @Prop() public ring!: Ring | null
    @Prop() public ringDetails!: RingDetails | null

    public timelineStore = new TimelineStore()
    public ringsStore = new RingsStore()
    public userStore = new UserStore()

    public get warrantyData(): Nullable<WarrantyData> {
      return this.ringsStore.warrantyData
    }

    public get dataWait(): boolean {
      return this.ringsStore.waitingForData(['getWarrantyData', 'getConnectedAccounts', 'getRingDetails'])
    }

    public get warrantyDataRingReplacedBy(): Nullable<WarrantyData> {
      return this.ringsStore.warrantyDataRingReplacedBy
    }

    public get warrantyDataRingReplacementFor(): Nullable<WarrantyData> {
      return this.ringsStore.warrantyDataRingReplacementFor
    }

    public get userZDTickets(): UserZendeskTicketContents[] | [] {
      return this.userStore.userZDTickets?.contents || []
    }

    public get deviceInfo(): Nullable<DeviceInfo> {
      return extractDeviceInfoFromRingAndWarrantyDetails(this.ringDetails, this.warrantyData)
    }

    public get zendeskNotes() {
      const ringTrId = this.warrantyData?.ringReturn?.returnAuthorization?.transactionId
      const chargerTrId = this.warrantyData?.chargerReturn?.returnAuthorization?.transactionId
      const ringNote = this.userZDTickets.find((e) => e.netsuiteRaTranid === ringTrId)?.notes ?? null
      const chargerNote = this.userZDTickets.find((e) => e.netsuiteRaTranid === chargerTrId)?.notes ?? null
      return { ringNote, chargerNote }
    }

    @Watch('ring', { immediate: true })
    protected onRingChanged() {
      this.updateWarrantyData()
    }

    protected updateWarrantyData() {
      if (this.ring) {
        this.ringsStore.getWarrantyData({ serial: this.ring.serialNumber, initial: true })
      }
    }

    public created() {
      logEvent(this.$analytics, 'page_view', {
        page_title: 'DevicesInfo',
        page_location: window.location.toString().split('?')[0],
      })
    }

    public mounted() {
      this.userStore.getUserZDTickets(this.user.uuid)
    }
  }

  export default toNative(DevicesInfo)
</script>

<style lang="scss"></style>
