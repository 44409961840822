<template>
  <v-alert
    tile
    border="start"
    elevation="2"
    variant="outlined"
    density="compact"
    :type="alert.type === 'not_enough_data' ? 'info' : alert.type"
    style="
      position: relative;
      height: 100%;
      padding: 15px 0 12px 6px;
      border-radius: 0 !important;
      box-shadow:
        rgba(0, 0, 0, 0.2) 0px 0px 0px -1px,
        rgba(0, 0, 0, 0.14) 0px 0px 1px,
        rgba(0, 0, 0, 0.12) 1px 0px 1px !important;
    "
  >
    <v-hover v-if="showComment !== alert.id">
      <v-row align="center" class="pa-0 pl-3" :class="sidecar ? 'mt-1 mb-n2 ' : 'my-n1'">
        <v-col
          class="flex-grow-1 pa-0 pr-4 alert-text"
          style="color: rgba(0, 0, 0, 0.87); white-space: nowrap !important"
          cols="12"
          md="auto"
        >
          <span style="white-space: normal">{{ alert.message }}</span>
          <span
            v-if="alert.tooltip && alert.tooltip.items.length"
            small
            class="mb-1 ml-2 details-button"
            @click.stop="
              updateExtraInfo(extraInfo === index ? null : index),
                (expand = !expand),
                logAnalyticsEvent($analytics, `${sidecar ? 'sidecar' : 'dashboard'}_diagnostics_info`, {
                  action: 'Click i icon',
                  category: `Alert: ${sidecar ? 'Sidecar' : 'Dashboard'}`,
                  label: `Click i (info) Diagnostics ${sidecar ? 'Sidecar' : 'Dashboard'}`,
                })
            "
          >
            Details
          </span>
        </v-col>

        <v-col
          v-if="alert.tooltip || diagnosticsInValidation.includes(alert.id)"
          class="flex-shrink-0 pa-0"
          cols="12"
          md="auto"
        >
          <div style="white-space: nowrap; text-align: right" :class="{ sidecar: sidecar }">
            <v-tooltip location="top">
              <template #activator="{ props }">
                <v-btn
                  v-if="alert.link"
                  color="blue"
                  variant="text"
                  :href="alert.link"
                  target="_blank"
                  @click.stop="
                    logAnalyticsEvent($analytics, `${sidecar ? 'sidecar' : 'dashboard'}_diagnostics_links`, {
                      action: 'Click Guru link',
                      category: `Alert: ${sidecar ? 'Sidecar' : 'Dashboard'}`,
                      label: `Click Guru link in ${sidecar ? 'Sidecar' : 'Dashboard'}`,
                    })
                  "
                >
                  <span v-if="!sidecar">GURU Card</span>
                  <span v-else>
                    <v-icon :style="{ color: alert.macro.id ? 'green' : '' }" v-bind="props">
                      mdi-alpha-g-box-outline
                    </v-icon>
                  </span>
                </v-btn>
              </template>
              {{ alert.link ? alert.link : 'Guru card' }}
            </v-tooltip>
            <v-tooltip location="top">
              <template #activator="{ props }">
                <v-btn
                  v-if="alert.macro && alert.macro.id && sidecar"
                  color="blue-lighten-1"
                  variant="text"
                  size="large"
                  class="sidecar_left"
                  target="_blank"
                  v-bind="props"
                  @click.stop="
                    applyMacro(alert.macro.id, alert.macro.actions),
                      logAnalyticsEvent($analytics, 'sidecar_macro_applied', {
                        action: 'sidecar macro selected',
                        category: `Macro: ${alert.macro.id}`,
                        label: `Macro: ${alert.macro.id}`,
                      })
                  "
                >
                  <v-icon>mdi-arrow-left-bottom-bold</v-icon>
                </v-btn>
              </template>
              {{ alert.macro.title ? alert.macro.title : 'Apply macro' }}
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
    </v-hover>
  </v-alert>
</template>

<script lang="ts">
  import { Component, Emit, Prop, mixins, toNative } from 'vue-facing-decorator'

  import { logEvent } from 'firebase/analytics'
  import { getValue } from 'firebase/remote-config'

  import { DateTime } from '#mixins/dateTime'

  import { AppStore } from '#stores'

  @Component
  class AlertV2 extends mixins(DateTime) {
    @Prop() public user!: any
    @Prop() public alert!: any
    @Prop() public index!: number
    @Prop() public sidecar!: boolean
    @Prop() public extraInfo!: any

    public appStore = new AppStore()

    public expand = false

    public textComment = ''

    public logAnalyticsEvent = logEvent

    public showComment: null | string = null

    @Emit('update:extraInfo')
    public updateExtraInfo(index: null | number) {
      return index
    }

    public get rights() {
      return this.appStore.getRights || []
    }

    public get diagnosticsInValidation() {
      return getValue(this.$remoteConfig, 'diagnosticsInValidation').asString().split(',')
    }

    public applyMacro(id: string, actions: string | any[]) {
      this.$zafClient.invoke('macro', parseInt(id))
      if (actions && actions.length > 0) {
        for (const action of actions) {
          if (action && action[0] == 'set') {
            this.$zafClient.set(action[1], action[2])
          }
        }
      }
      this.$zafClient.on('ticket.submit.start', () => {
        this.addTagTracker()
      })
    }
    public addTagTracker() {
      this.$zafClient.invoke('ticket.tags.add', 'zaf_macro')
    }
  }

  export default toNative(AlertV2)
</script>

<style lang="scss" scoped>
  :deep(.v-input.v-text-field) {
    color: #757575 !important;
  }
  :deep(.v-alert.v-alert--border.v-alert--border-start) {
    border: 0 !important;
    box-shadow:
      0 3px 1px -2px #0003,
      0 2px 2px #00000024,
      0 1px 5px #0000001f !important;
  }

  :deep(.v-alert__prepend) {
    margin-inline-end: -2px;
  }

  :deep(.action) {
    display: inline-block;
    transition: opacity 200ms;
    &.comment {
      color: #2196f3;
    }
    &.positive {
      color: #4caf50;
    }
    &.negative {
      color: #f44336;
    }
  }

  :deep(.feedback-notification) {
    color: #f44336;
    font-size: 0.9rem;
  }

  :deep(.details-button) {
    color: #2196f3;
    font-size: 0.9rem;
    cursor: pointer;
  }

  :deep(.sidecar) {
    // text-align: left !important;
    .v-btn--icon.v-btn--density-default {
      width: calc(var(--v-btn-height) + 0px);
      height: calc(var(--v-btn-height) + 0px);
    }
  }
  :deep(.sidecar_left) {
    float: left;
    padding-left: 0px;
  }
  :deep(.v-btn.v-icon) {
    --v-icon-size-multiplier: 1;
  }

  :deep(#extPanContent > *) {
    padding: 0;
  }
  :deep(.expPanel > .v-expansion-panel-content__wrap) {
    padding: 0;
  }
  :deep(.v-btn:not(.v-btn--round).v-size--default) {
    min-width: 25px;
    padding: 3px;
  }

  .v-btn--icon.v-btn--density-default {
    width: calc(var(--v-btn-height) + 0px);
    height: calc(var(--v-btn-height) + 0px);
    padding-right: 30px;
  }
</style>
