<template>
  <!-- eslint-disable vue/v-on-handler-style -->
  <v-app-bar title="Oura rings / chargers" />

  <v-container>
    <v-row>
      <v-col cols="12" md="9">
        <div class="text-h5 font-weight-light">Access Oura rings and chargers information</div>

        <div class="text-subtitle-2 text-medium-emphasis font-weight-light">
          View order info / warranty status / return status and linked accounts
        </div>
      </v-col>

      <v-col md="3" cols="12" class="text-right">
        <v-btn
          text="Run search"
          color="primary"
          :disabled="!keyword || dataWait"
          @click="searchRing()"
          @keyup.enter="searchRing()"
        />
      </v-col>
    </v-row>

    <v-row class="mt-4">
      <v-col cols="5">
        <v-text-field
          v-model.trim="keyword"
          autofocus
          append-inner-icon="mdi-database-search"
          label="Search for devices"
          :hide-details="false"
          :error="!searchIsValid"
          :error-messages="searchErrors"
          :placeholder="`Enter keyword to search and press enter...`"
          @keyup.enter="searchRing()"
        />
      </v-col>

      <v-col cols="2">
        <v-select
          v-model="searchType"
          :items="searchTypes"
          label="Search type"
          @update:model-value="validateSearch()"
        />
      </v-col>

      <v-col cols="3">
        <v-select
          v-model="searchFilter"
          label="Status filter"
          placeholder="Select extra filtering..."
          :items="searchFilters"
          clearable
          @update:model-value="validateSearch()"
        />
      </v-col>
      <v-col class="d-flex align-start justify-end ga-4" cols="2">
        <v-checkbox
          v-model="partialSearch"
          :disabled="!partialSearchEnabled()"
          class="mt-n2"
          label="Use partial match"
          @update:model-value="validateSearch()"
          @keyup.enter="searchRing()"
        >
          <v-tooltip activator="parent" location="top" :disabled="false">
            Match ring and charger serials partially from the start of the string.
          </v-tooltip>
        </v-checkbox>
      </v-col>
    </v-row>

    <v-sheet class="mt-4">
      <v-data-table
        :items="ringSearchResults"
        :items-per-page="itemsPerPage"
        :no-data-text="dataWait ? 'Loading search results...' : 'No matches / results found'"
        :no-results-text="keyword ? 'Press enter to perform search' : 'No matching devices found'"
        :headers="headers"
        :loading="dataWait"
        item-class="type"
        @click:row="(event: any, item: any) => redirectToRingDetails(event, item)"
      >
        <template #[`item.ringSerialNumber`]="{ item }">
          {{ item.ringSerialNumber || 'Not available' }}
        </template>

        <template #[`item.netsuiteSalesOrderId`]="{ item }">
          {{ item.netsuiteSalesOrderId || 'Not available' }}
        </template>

        <template #[`item.chargerSerialNumber`]="{ item }">
          {{ item.chargerSerialNumber || 'Not available' }}
        </template>

        <template #[`item.orderStatus`]="{ item }">
          {{ item.orderStatus || 'Not available' }}
        </template>

        <template #[`item.orderTimestamp`]="{ item }">
          {{ item.orderTimestamp || 'Not available' }}
        </template>

        <template #[`item.returnReason`]="{ item }">
          {{ item.returnReason || 'Not available' }}
        </template>

        <template #[`item.returnStatus`]="{ item }">
          {{ item.returnStatus || 'Not available' }}
        </template>
      </v-data-table>
    </v-sheet>
  </v-container>
</template>

<script lang="ts">
  import { Component, mixins, toNative } from 'vue-facing-decorator'

  import { RouteParams } from '@jouzen/outo-apps-toolkit'

  import {
    ringSearchFilters,
    ringSearchHeaders,
    ringSearchMaxLength,
    ringSearchMinLength,
    ringSearchTypeName,
    ringSearchTypes,
  } from '#views/rings/constants'

  import { RingsStore } from '#stores'
  import { RingSearchRequest } from '#stores/rings.types'

  import { RingSearchResponseSingleRing } from '#types'

  @Component
  class RingSearch extends mixins(RouteParams) {
    public ringsStore = new RingsStore()

    public keyword: string = ''

    public searchIsValid: boolean = true
    public searchErrors: string[] = []

    public headers = ringSearchHeaders

    public searchFilter: 'warrantyPending' | 'warrantyReceived' | 'warrantyClosed' | undefined
    public searchFilters = ringSearchFilters
    public ringSearchTypeName = ringSearchTypeName

    public searchType: 'ringSerial' | 'chargerSerial' | 'netsuiteOrderId' | 'netsuiteReturnId' = 'ringSerial'
    public searchTypes = ringSearchTypes
    public itemsPerPage = 100
    public searchRequestPageSize = 500

    public partialSearch: boolean = false

    public routeParams: any = {
      search: '',
      searchType: '',
      partialSearch: '',
      searchFilter: '',
    }

    public get ringSearchResults(): RingSearchResponseSingleRing[] {
      return this.ringsStore.ringSearchResult
    }

    public get dataWait() {
      return this.ringsStore.dataWait
    }

    public get ringsCount() {
      return (
        (this.ringSearchResults?.length >= this.searchRequestPageSize ? '>= ' : '') +
        (this.ringSearchResults?.length || '0')
      )
    }

    public partialSearchEnabled() {
      if (this.searchType === 'netsuiteOrderId' || this.searchType == 'netsuiteReturnId') {
        return false
      }
      return true
    }

    public mounted() {
      this.ringsStore.ringSearchResult = []

      this.parseRouteParams()

      this.searchRing()
    }

    /**
     * Validates given search keyword.
     * Keyword must be at least `ringSearchMinLength` characters long and ringSearchMinLength` characters short.
     * Keyword must also match our safe-string regex.
     * @private
     */
    public validateSearch() {
      this.searchErrors = []
      this.searchIsValid = true

      if (!this.partialSearchEnabled()) {
        this.partialSearch = false
      }

      if (!this.keyword) {
        return
      }

      // Test safe-string regex match
      const searchRegex = new RegExp(/^[0-9a-zA-Z_,.-]+$/)
      const searchRegexInvalid = !searchRegex.test(this.keyword)
      if (searchRegexInvalid) {
        this.searchIsValid = false
        this.searchErrors.push('Search keyword has invalid characters.\n')
        return
      }

      // Test length too short
      if (this.keyword.length < ringSearchMinLength) {
        this.searchIsValid = false
        this.searchErrors.push(`Search keyword is too short. Min ${ringSearchMinLength} characters.\n`)
        return
      }

      // Test length too long
      if (this.keyword.length > ringSearchMaxLength) {
        this.searchIsValid = false
        this.searchErrors.push(`Search keyword is too long. Max ${ringSearchMaxLength} characters.\n`)
        return
      }
    }

    public searchRing() {
      this.validateSearch()

      if (!this.searchIsValid || !this.keyword) {
        return
      }

      let searchRequest: RingSearchRequest = {
        search: this.keyword,
        searchType: this.searchType,
        partialSearch: this.partialSearch,
        pageSize: this.searchRequestPageSize,
      }
      if (this.searchFilter) {
        searchRequest['searchFilter'] = this.searchFilter
      }
      this.fillRouteParamsFromSearchRequest(searchRequest)
      const filteredSearchRequest = this.filterSearchRequest(searchRequest)
      this.ringsStore.search(filteredSearchRequest)
    }

    private fillRouteParamsFromSearchRequest(searchRequest: RingSearchRequest) {
      this.routeParams['search'] = searchRequest.search
      this.routeParams['searchType'] = searchRequest.searchType
      this.routeParams['partialSearch'] = searchRequest.partialSearch
      this.routeParams['searchFilter'] = searchRequest.searchFilter
    }

    private filterSearchRequest(searchRequest: RingSearchRequest) {
      return {
        ...searchRequest,
        search: searchRequest.search.split(',')[0], // Y0722601249,07 -> Y0722601249
      }
    }

    private parseRouteParams() {
      if (this.routeParams.search) {
        this.keyword = this.routeParams.search
      }
      if (this.routeParams.searchType) {
        this.searchType = this.routeParams.searchType
      } else {
        this.routeParams.searchType = 'ringSerial'
      }
      if (this.routeParams.partialSearch) {
        this.partialSearch = !!this.routeParams.partialSearch
      }
      if (this.routeParams.searchFilter) {
        this.searchFilter = this.routeParams.searchFilter
      }
    }

    public addFilter(_item: any) {
      this.searchRing()
    }

    public redirectToRingDetails(_event: any, ring: any) {
      const ringSerial = ring.item?.ringSerialNumber
      const chargerSerial = ring.item?.chargerSerialNumber
      let serial = ringSerial
      if (!serial) {
        serial = chargerSerial
      }

      if (serial) {
        this.$router
          .push({
            name: 'ring-details',
            params: { ringOrChargerSerial: serial },
          })
          .catch((_err) => {})
      }
    }
  }

  export default toNative(RingSearch)
</script>

<style lang="scss" scoped>
  :deep(.v-data-table) {
    tr:hover {
      cursor: pointer;
    }

    .hidden td {
      display: none;
    }

    .v-data-footer__select {
      visibility: hidden;
    }
  }
</style>
