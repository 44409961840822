// sort-imports-ignore

import App from '#App.vue'

import router from '#router'

import { createPinia } from 'pinia'
import { createVuetify } from 'vuetify'
import { createApp, markRaw } from 'vue'

import { initializeApp } from 'firebase/app'

import { getStorage } from 'firebase/storage'
import { getAnalytics } from 'firebase/analytics'
import { getFirestore } from 'firebase/firestore'
import { getFunctions } from 'firebase/functions'

import { getRemoteConfig } from 'firebase/remote-config'

import { DatePicker, DateRangePicker } from '@jouzen/outo-apps-toolkit'

import ZAFClient from 'zendesk_app_framework_sdk'

import { AppStore, PrefsStore } from '#stores'

import 'vuetify/styles'

import '@jouzen/outo-apps-toolkit/style.css'
import '@mdi/font/css/materialdesignicons.css'
import 'diff2html/bundles/css/diff2html.min.css'

import '@jouzen/outo-apps-toolkit/vuetify.scss'
import '@jouzen/outo-apps-toolkit/markdown.scss'

import {
  defaults,
  storeReset,
  activateRemoteConfig,
  createVueDayjsPlugin,
  createVueSentryPlugin,
  createVueConfirmPlugin,
  createVueFirebasePlugin,
  createVueAtlassianPlugin,
  createPiniaAxiosPlugin,
  createPiniaFirebasePlugin,
} from '@jouzen/outo-apps-toolkit'

const defaultRemoteConfig = {
  maintenance: false,
  diagnosticsInValidation: '',
  batteryHealthSummaryInfo: false,
  zendeskTicketCheck: false,
}

export const defaultFeatureFlags = {
  anonymizedFileDownload: false, // Anonymized JZLog file download supported (MXPRODS-2131)
  assaBatteryData: false, // Use battery data from ASSA (MXPRODS-3051)
  sidecarMacroAssist: false, // Used for Zendesk's Sidecar macro suggested from diagnostics (MXPRODS-3152)
  stressDiagnostics: false, // Stress diagnostics (MXPRODS-3284)
  stressErrorDiagnostics: false, // Stress error diagnostic (MXPRODS-3466)
  signupFlowDiagnostic: false, // Signup flow diagnostic (MXPRODS-3285)
  multipleRingsInUseDiagnostic: false, // Multiple rings in use diagnostic (MXPORDS-3120)
  automaticActivityDiagnostic: false, // Automatic Activity Detections (AAD 2.0) diagnostics (MXPRODS-3665)
  troubleshooterWeights: false, // Use graph weights as a limit for selection (MXPRODS-3491)
  resilienceDiagnostics: false, // Resilience diagnostics (MXPRODS-3877)
  hideUnreleasedRingNames: false, // Hide unreleased ring names (MXPRODS-3844)
  ringLastSyncBanner: false, // Hide ring last synced banner on Dashboard (MXPRODS-3103)
  partnershipIntegrationsDiagnostic: false, // Show partnership integrations diagnostic (MXPRODS-4077)
  connectivityDiagnostics: false, // Show connectivity diagnostics (MXPRODS-4145)
  assaBrowser: false, // Show assa data browser (MXPRODS-4145)
  arrhythmiaTool: false, // Arrhythmia tool (MXPRODS-4055)
  ringReplacementsDiagnostics: false, // Ring replacements diagnostics (MXPRODS-4240)
  diagnosticsV3Component: false, // Diagnostics v3 instead of v2 (MXPRODS-4300)
  warrantyDatesOnDashboard: false, // Show warranty dates on dashboard (MXPRODS-3909)
  oldRingInUseDiagnostic: false, // old-ring in use diagnostic (MXPRODS-4366)
  previouslyOwnedRingDiagnostics: false, // Previously owned ring diagnostic update (MXPRODS-3941)
  warrantyBanner: false, // ring's warranty status displayed on sidecar (MXPRODS-3910)
  resetDunningHistory: false, // abiility for agents to reset subscription dunning history (MXPRODS-4423)
  assaBatteryLifeEstimate: false, // Use assa battery life estimate for battery diagnostics (MXPRODS-4561)
  showHipaaStatus: false, // Show hipaa status on dashboard and sidecar (MXPRODS-4652)
  darwinDarkMode: false,
  featureFlagDisplay: false, // feature flag display feature (MXPRODS-4881)
  orders: false, // Show orders on Darwin main menu (MXPRODS-4200)
  remoteAppActions: false, // Remote app actions triggered from Darwin, DONT REMOVE (MXPRODS-3762)
  subscriptionCancelImmediatelyFeature: false, // Allow to cancel subscription immediately + new endpoint request format (MXPRODS-4862)
}

const app = createApp(App)
const pinia = createPinia()

const vuetify = createVuetify({
  icons: {
    defaultSet: 'mdi',
  },
  theme: {
    defaultTheme: 'light',
    themes: {
      dark: {
        dark: true,
        colors: {
          info: '#42A5F5', // blue-lighten-1 (material palette)
          error: '#EF5350', // red-lighten-1 (material palette)
          success: '#66BB6A', // green-lighten-1 (material palette)
          warning: '#FF9800', // orange-lighten-1 (material palette)
          surface: '#373737', // lighten(#181818, 12%) (custom color)
          primary: '#EC407A', // <color>-lighten-1 (pink | purple | indigo)
          secondary: '#310614', // color-mix(#000000, 100%, <primary>, 50%)
          background: '#211B1C', // color-mix(#181818, 100%, <primary>, 5%)
        },
        variables: {
          'high-emphasis-opacity': 1.0,
          'theme-on-secondary': '0, 0, 0, 0.55',
        },
      },
      light: {
        dark: false,
        colors: {
          info: '#42A5F5', // blue-lighten-1 (material palette)
          error: '#EF5350', // red-lighten-1 (material palette)
          success: '#66BB6A', // green-lighten-1 (material palette)
          warning: '#FF9800', // orange-lighten-1 (material palette)
          surface: '#FFFFFF', // lighten(#F8F8F8, 2.5%) (custom color)
          primary: '#EC407A', // <color>-lighten-1 (pink | purple | indigo)
          secondary: '#FFDCE3', // color-mix(#ffffff, <primary-color-hex>, 25%)
          background: '#F9F4F5', // color-mix(#f8f8f8, <primary-color-hex>, 2.5%)
        },
        variables: {
          'high-emphasis-opacity': 1.0,
          'theme-on-secondary': '0, 0, 0, 0.55',
        },
      },
    },
  },
  defaults,
})

const zendesk = ZAFClient.init()

const firebase = initializeApp({
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
})

app.use(pinia)
app.use(router)
app.use(vuetify)

app.use(createVueDayjsPlugin())
app.use(
  createVueSentryPlugin({
    router,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    release: process.env.APP_RELEASE_VERSION,
    environment: import.meta.env.VITE_APP_ENV,
    tracingOrigin: import.meta.env.VITE_API_URL,
  }),
)
app.use(
  createVueFirebasePlugin({
    firebase: firebase,

    analytics: getAnalytics(firebase),
    remoteConfig: getRemoteConfig(firebase),
  }),
)
app.use(
  createVueAtlassianPlugin({
    component: import.meta.env.VITE_JIRA_COMPONENT_ID,
    environment: import.meta.env.VITE_APP_ENV,
  }),
)
app.use(createVueConfirmPlugin(), vuetify)

pinia.use(storeReset)

pinia.use(({ store }) => {
  store.$router = markRaw(router)
})

pinia.use(
  createPiniaAxiosPlugin({
    throwErrors: true,
    url: import.meta.env.VITE_API_URL,
    envs: {
      test: import.meta.env.VITE_API_URL_TEST,
      stage: import.meta.env.VITE_API_URL_STAGE,
    },
    version: process.env.APP_RELEASE_VERSION,
  }),
)
pinia.use(
  createPiniaFirebasePlugin({
    firebase: firebase,

    fbstorage: getStorage(firebase),
    firestore: getFirestore(firebase),

    remoteConfig: getRemoteConfig(firebase),

    functions: getFunctions(firebase, 'europe-west1'),
  }),
)

app.component('DatePicker', DatePicker)
app.component('DateRangePicker', DateRangePicker)

app.config.globalProperties.$zafClient = zendesk

app.config.globalProperties.productionTip = false

activateRemoteConfig({
  remoteConfig: getRemoteConfig(firebase),

  configDefaults: {
    ...defaultRemoteConfig,
    ...defaultFeatureFlags,
  },
})
  .then(() => {
    console.log('Firebase initialized')
  })
  .catch(() => {
    console.log('Failed to initialize firebase')
  })
  .finally(() => {
    const appStore = new AppStore()
    const prefsStore = new PrefsStore()

    appStore.init()
    prefsStore.init()

    app.mount('#app')
  })
