<template>
  <v-card>
    <v-alert v-if="!!newSubscriptionError" tile class="px-5" type="error" color="red">
      <div class="px-3">
        {{ newSubscriptionError }}
      </div>
    </v-alert>

    <v-card-text v-if="newSubscription && org && defaultPlan">
      <v-container class="pa-8">
        <p class="text-overline">New Subscription</p>

        <v-row>
          <v-col cols="6">
            <v-text-field v-model="org.name" label="Organization name" readonly>
              <template #append>
                <v-tooltip location="top">
                  <template #activator="{ props }">
                    <v-icon v-bind="props">mdi-pencil-lock-outline</v-icon>
                  </template>
                  Organization name is not editable
                </v-tooltip>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-select v-model="org.type" :items="orgTypes" item-title="text" label="Organization type" readonly>
              <template #append>
                <v-tooltip location="top">
                  <template #activator="{ props }">
                    <v-icon v-bind="props">mdi-pencil-lock-outline</v-icon>
                  </template>
                  Organization type is not editable
                </v-tooltip>
              </template>
            </v-select>
          </v-col>
        </v-row>

        <v-row class="mt-4">
          <v-col cols="6">
            <v-select
              v-model="defaultPlan.name"
              :items="planOptions"
              item-title="text"
              label="Plan"
              readonly
              append="mdi-pencil-lock-outline"
            >
              <template #append>
                <v-tooltip location="top">
                  <template #activator="{ props }">
                    <v-icon v-bind="props">mdi-pencil-lock-outline</v-icon>
                  </template>
                  Plan option is not editable
                </v-tooltip>
              </template>
            </v-select>
          </v-col>
          <v-col cols="6">
            <v-select
              v-model="newSubscription.status"
              :items="subscriptionStatuses"
              item-title="text"
              label="Subscription status"
            />
          </v-col>
        </v-row>

        <v-row>
          <DateRangePicker
            v-model:start-date="newSubscription.startDate"
            v-model:end-date="newSubscription.endDate"
            :min-date="minDate.format('YYYY-MM-DD')"
            :max-date="null"
            :end-empty-allowed="true"
            width="300px"
            @valid="datesAreValid = $event"
          />
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              v-model.number="newSubscription.seatCount"
              type="number"
              label="Seat count"
              placeholder="(unlimited)"
            />
          </v-col>
          <v-col v-if="org.type !== 'health_private' && org.type !== 'illness_detection'">
            <v-checkbox v-model="newSubscription.blackoutMode" label="Enable Remote App Management" />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn variant="text" class="mr-2" @click="close()">Cancel</v-btn>
      <v-btn
        variant="text"
        color="blue"
        :disabled="
          !newSubscription || !newSubscription.status || !newSubscription.startDate || dataWait || !datesAreValid
        "
        @click="saveSubscription()"
      >
        Create
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
  import { Dayjs } from 'dayjs'

  import { Component, Emit, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { orgTypes, planOptions, responseHandler, subscriptionStatuses } from '#views/teams/constants'

  import { TeamsStore } from '#stores'

  import { CreateSubscriptionData, TeamsOrgSubscription } from '#types'

  @Component
  class NewSubscription extends Vue {
    @Prop() public org!: any
    @Prop() public defaultPlan!: any
    @Prop() public subscriptions!: any

    public teamsStore = new TeamsStore()

    public newSubscriptionError = ''
    public orgTypes = orgTypes
    public planOptions = planOptions
    public subscriptionStatuses = subscriptionStatuses
    public datesAreValid = false

    public newSubscription: CreateSubscriptionData | null = null

    public get dataWait(): boolean {
      return this.teamsStore.dataWait
    }

    public get minDate(): Dayjs {
      return this.latestSubscription?.valid_to ? this.$dayjs(this.latestSubscription.valid_to) : this.$dayjs(0)
    }

    private get latestSubscription(): TeamsOrgSubscription {
      return this.subscriptions[this.subscriptions.length - 1]
    }

    @Emit('close')
    public close() {
      return null
    }

    @Watch('latestSubscription')
    protected onLatestSubscriptionChange() {
      const startDate = this.latestSubscription?.valid_to
        ? this.$dayjs(this.latestSubscription.valid_to)
        : this.$dayjs()

      const endDate = this.latestSubscription?.valid_to
        ? this.$dayjs(startDate).add(1, 'year')
        : this.$dayjs().add(30, 'days')

      this.newSubscription = {
        organizationID: this.org.uid,
        planID: this.defaultPlan.plan_uid,
        status: this.latestSubscription ? 'active' : 'trial',
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD'),
        seatCount: this.latestSubscription?.options[0]?.data.participant_seat_count,
        blackoutMode: this.latestSubscription?.options[0]?.data.blackout_mode,
      }
    }

    public mounted() {
      this.onLatestSubscriptionChange()
    }

    public saveSubscription() {
      if (!this.newSubscription) {
        throw new Error('No subscription data when saveSubscription was called')
      }

      this.newSubscriptionError = ''

      const startDate = this.$dayjs.utc(this.newSubscription.startDate).toISOString()
      const endDate = this.newSubscription.endDate ? this.$dayjs.utc(this.newSubscription.endDate).toISOString() : null

      const payload: CreateSubscriptionData = {
        ...this.newSubscription,
        startDate,
        endDate,
      }

      this.teamsStore.createSubscription(payload).then(
        responseHandler({
          onError: (error) => {
            this.newSubscriptionError = error
          },
          onSuccess: () => {
            this.close()
          },
        }),
      )
    }
  }

  export default toNative(NewSubscription)
</script>
