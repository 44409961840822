<template>
  <v-container class="viewport">
    <Info v-if="user" v-model:ring="ring" class="viewport" :user="user" :rings="user.rings" />

    <!-- TODO: Change visible diagnostic to correct ones when all backend diagnostics are done -->
    <DiagnosticsV3
      v-if="diagnosticsV3FeatureEnabled"
      class="mt-8"
      :user="user"
      :ring="ring"
      :sidecar="false"
      :visible-diagnostics="['battery', 'ring-connectivity-diagnostic']"
      :sub-components="diagnosticsSubComponents"
    />
    <DiagnosticsV2 v-else class="mt-8" :user="user" :ring="ring" :sub-components="diagnosticsSubComponents" />

    <template v-if="user && ring">
      <GenericGraph
        class="mt-8"
        title="Battery charge level"
        :graphs="['batteryLevelAssa', 'batteryHealth', 'spo2usage', 'whrUsage']"
        :user-uuid="user.uuid"
        :ring="ring"
        :end-date="graphEndDate"
        :start-date="batteryChargeLevelGraphStartDate"
      />

      <GenericGraph
        v-if="ringLifetimeFromAssaFeatureEnabled"
        class="mt-8"
        title="Battery life estimate"
        :graphs="['batteryLifeTrend']"
        :user-uuid="user.uuid"
        :ring="ring"
        :end-date="graphEndDate"
        :start-date="batteryLifeGraphStartDate"
        :autoscale="true"
        :graph-height="400"
      />
      <Lifetime v-else class="mt-8" :data="events" :ring="ring" />
    </template>

    <ClientV2 v-if="ring && user" class="mt-8" :member="user" :ring="ring" />
  </v-container>

  <Summary
    v-if="user"
    :uuid="user.uuid"
    :ring="ring"
    :rings="user.rings"
    :summary-fields="batteryInfoFields"
    :show-filters="false"
  />
</template>

<script lang="ts">
  import dayjs from 'dayjs'

  import { Component, Prop, Watch, mixins, toNative } from 'vue-facing-decorator'

  import { DateTime } from '#mixins/dateTime'

  import { batteryInfoSummaryFields } from '#views/users/constants'

  import { DiagnosticsSubComponents } from '#views/users/DiagnosticsV2.vue'

  import { diagnosticsV3Feature, ringLifetimeFromAssaFeature } from '#utils/darwinFeatureFlags'

  import { TimelineStore } from '#stores'

  import { Member, Ring } from '#types'

  @Component
  class BatteryInfo extends mixins(DateTime) {
    @Prop() public user!: Member
    @Prop() public ring!: Ring
    @Prop() public rings!: Ring[]

    private readonly dateFormat = 'YYYY-MM-DD'

    public timelineStore = new TimelineStore()

    public graphEndDate = dayjs().format(this.dateFormat)
    public batteryChargeLevelGraphStartDate = dayjs().subtract(14, 'day').format(this.dateFormat)

    public openDiagnostics = false

    public batteryInfoFields = batteryInfoSummaryFields

    @Watch('ring', { immediate: true })
    protected onRingChanged() {
      this.fetchBatteryHealth()
    }

    public get batteryLifeGraphStartDate() {
      return dayjs(this.ring?.registrationDate).format(this.dateFormat)
    }

    public get diagnosticsSubComponents() {
      return [
        DiagnosticsSubComponents.BatteryHealthy,
        DiagnosticsSubComponents.Warranty,
        DiagnosticsSubComponents.Version,
        DiagnosticsSubComponents.SelfTestHealth,
      ]
    }

    public get diagnosticsV3FeatureEnabled() {
      return diagnosticsV3Feature()
    }

    public get ringLifetimeFromAssaFeatureEnabled() {
      return ringLifetimeFromAssaFeature()
    }

    public get events() {
      return this.timelineStore.lifetime
    }

    private fetchBatteryHealth() {
      if (this.user?.uuid && this.ring?.serialNumber) {
        this.timelineStore.getLifetimeEvents({
          uuid: this.user.uuid,
          ringSerial: this.ring.serialNumber,
          registrationDate: this.ring.registrationDate,
        })
      }
    }
  }

  export default toNative(BatteryInfo)
</script>
