<template>
  <v-dialog :model-value="dialog" width="800" min-height="600" @after-leave="close()">
    <v-card>
      <v-progress-linear v-show="actionPending" style="position: relative" />
      <v-card-title class="headline">Mark devices as returned</v-card-title>
      <v-card-text class="mb-n8">
        <div>
          <v-checkbox
            v-model="userChoices.returnRing"
            :disabled="!returnDialogData?.ring.allowReturn || actionPending"
            label="Mark ring as returned"
            class="shrink mr-0 mt-0 ma-0 pa-0"
            hide-details="auto"
          />
          <v-tooltip v-if="returnDialogData?.ring.tooltipText" activator="parent" location="top" :disabled="false">
            {{ returnDialogData?.ring.tooltipText }}
          </v-tooltip>
        </div>
        <div>
          <v-checkbox
            v-model="userChoices.returnCharger"
            :disabled="!returnDialogData?.charger.allowReturn || actionPending"
            label="Mark charger as returned"
            class="shrink mr-0 mt-0 ma-0 pa-0"
            hide-details="auto"
          />
          <v-tooltip v-if="returnDialogData?.charger.tooltipText" activator="parent" location="top" :disabled="false">
            {{ returnDialogData?.charger.tooltipText }}
          </v-tooltip>
        </div>
        <v-text-field
          v-if="userChoices.returnCharger"
          v-model.trim="userChoices.confirmChargerSerial"
          class="ml-6 mt-n2 mb-4"
          label="Enter charger serial number"
        />
        <v-checkbox
          v-model="userChoices.returnCable"
          :disabled="!returnDialogData?.cable.allowReturn || actionPending"
          label="Mark charger cable as returned"
          class="shrink mr-0 mt-0 ma-0 pa-0"
          hide-details="auto"
        />
        <div v-if="returnDialogData?.ring.allowReturn">
          <p class="headline ml-0">Ring factory reset status:</p>
          <v-radio-group v-model="userChoices.resetStatus">
            <v-radio
              :disabled="!userChoices.returnRing || actionPending"
              label="Ring was factory reset by agent at the office"
              value="true"
            />
            <v-radio
              :disabled="!userChoices.returnRing || actionPending"
              label="Ring could not be factory reset"
              value="false"
            />
            <v-radio
              :disabled="!userChoices.returnRing || actionPending"
              label="Ring was not factory reset"
              value="none"
            />
          </v-radio-group>
        </div>
        <v-text-field
          v-if="userChoices.resetStatus === 'none'"
          v-model="userChoices.reasonForNoReset"
          :disabled="actionPending"
          class="ml-3 mt-n6 mb-2"
          label="Reason"
        />
        <v-alert v-if="!!extractError" tile type="error" color="red">
          {{ extractError }}
        </v-alert>
        <v-alert v-if="!!errorMarkReturned" tile type="error" color="red">
          {{ errorMarkReturned }}
        </v-alert>
      </v-card-text>

      <v-card-actions class="px-4">
        <v-spacer />
        <v-btn variant="text" class="mr-2" :disabled="actionPending" @click.stop="close()">Cancel</v-btn>
        <v-btn variant="text" color="primary" :disabled="!confirmButtonEnabled()" @click="markAsReturned()">
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { Component, Emit, Prop, mixins, toNative } from 'vue-facing-decorator'

  import { DateTime } from '#mixins/dateTime'

  import { ReturnDialogData, ReturnDialogUserChoices, WarrantyData } from '#views/rings/types'

  import { extractMarkReturnedRequests } from '#utils/mark-returned/extract'

  import { AppStore, RingsStore } from '#stores'

  import { Nullable } from '#types'

  @Component({
    emits: ['dialogOpen'],
  })
  class ReturnDialog extends mixins(DateTime) {
    @Prop() public dialog: boolean = false
    @Prop() public dialogOpen!: boolean
    @Prop() public warrantyData!: WarrantyData | null
    @Prop() public refreshDeviceSerial!: string
    @Prop() public returnDialogData!: null | ReturnDialogData

    public appStore = new AppStore()
    public ringsStore = new RingsStore()
    public extractError: Nullable<string> = null

    public userChoices: ReturnDialogUserChoices = {
      returnRing: false,
      returnCharger: false,
      returnCable: false,
      resetStatus: 'true',
      reasonForNoReset: '',
      confirmChargerSerial: null,
    }

    public actionPending = false

    public get errorMarkReturned() {
      return this.ringsStore.errorMarkReturned
    }

    public close() {
      this.emitDialogOpen(false)
    }

    public confirmButtonEnabled() {
      if (this.actionPending) {
        return false
      }
      if (!this.userChoices.returnRing && !this.userChoices.returnCharger && !this.userChoices.returnCable) {
        return false
      }
      if (
        this.userChoices.returnCharger &&
        this.userChoices.confirmChargerSerial !== this.returnDialogData?.charger.serial
      ) {
        return false
      }
      return true
    }

    public async markAsReturned() {
      this.actionPending = true
      let allRequestsOk = true
      if (this.returnDialogData) {
        const userEmail = this.appStore.auth?.email
        try {
          const requests = extractMarkReturnedRequests(this.userChoices, this.returnDialogData, userEmail)
          for (const request of requests) {
            const status = await this.ringsStore.markDeviceReturned({ request: request })
            if (!status) {
              // if any (first) "mark as returned" request fails, we don't want to make consecutive requests
              allRequestsOk = false
              break
            }
          }
        } catch (e: unknown) {
          if (e instanceof Error) {
            this.extractError = e.toString()
          }
          this.actionPending = false
          return
        }
      }
      /**
       * What will happen here is that if some "mark as returned" requests succeed but some fail, we don't
       * refresh the data (because the whole UI flickers annoyingly) so user might see stale data.
       * We should figure out a better way to handle this kind of scenario. We should probably also communicate to
       * user that their actions partially succeeded, and what actually happened. Now we just show generic error
       * message which doesn't really tell what state the data is in.
       *
       * Also when all requests succeed, we just simply close the dialog and refresh the data, but don't tell
       * user what actually happened.
       */
      if (allRequestsOk) {
        this.resetDialog()
        this.close()
        await this.ringsStore.getRingDetails(this.refreshDeviceSerial)
        await this.ringsStore.getWarrantyData({ serial: this.refreshDeviceSerial, initial: true })
      }
      this.actionPending = false
    }

    public resetDialog() {
      this.ringsStore.errorMarkReturned = null
      this.extractError = null
    }

    @Emit('dialogOpen')
    public emitDialogOpen(val: boolean) {
      return val
    }
  }

  export default toNative(ReturnDialog)
</script>

<style lang="scss" scoped>
  :deep(.v-dialog) {
    .v-card {
      box-shadow: none !important;
    }
  }
</style>
