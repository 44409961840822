import { isEqual } from 'lodash-es'

import { Component, mixins } from 'vue-facing-decorator'

import { DateTime } from '#mixins/dateTime'

import { summaryFields } from '#views/users/constants'

@Component
export class EventData extends mixins(DateTime) {
  public getLabel(event: any) {
    if (event.field === 'osVersion') {
      return 'Operating system updated'
    } else if (event.field === 'clientVersion') {
      return 'Application version updated'
    } else if (event.field === 'bootloaderVersion') {
      return 'Ring bootloader updated'
    } else if (event.field === 'firmwareVersion') {
      return 'Ring firmware updated'
    } else if (event.field === 'batteryData') {
      return 'Battery charge level'
    } else if (event.field === 'accountEmails') {
      return 'Account email registered'
    } else if (event.field === 'ringRegistrations') {
      return 'Ring change registered'
    } else if (event.field === 'batteryHealth') {
      return 'Battery health'
    } else if (event.field === 'dischargeCycles') {
      return 'Battery discharging cycle'
    } else if (event.field === 'chargeCycles') {
      return 'Battery charging cycle'
    } else if (event.field === 'ringResets') {
      return 'Ring reset detected'
    } else if (event.field === 'bluetoothSync') {
      return 'Bluetooth connection status'
    } else if (event.field === 'appStatus') {
      return 'Application state changed'
    } else if (event.field === 'appMessages') {
      return 'Application notifications'
    } else if (event.field === 'batteryDegPattern') {
      return 'Battery degradation event'
    } else if (event.field === 'userDevice') {
      return 'Device change'
    } else if (event.field === 'date') {
      return 'Date'
    }

    return event.field[0].toUpperCase() + event.field.slice(1).replace(/_/g, ' ')
  }

  public getValue(firstEvent: any, lastEvent: any, rings: any, type: string): string {
    let lastValue = lastEvent.value
    let firstValue = firstEvent.previousValue ?? firstEvent.value

    if (firstEvent.field === 'batteryData') {
      if (lastValue.value === 0 && lastValue.previousValue === null && type === 'table') {
        return 'Possible ring shutdown'
      }
      lastValue = lastValue + '%'
      firstValue = firstValue + '%'
    } else if (firstEvent.field === 'batteryHealth') {
      return this.formatDuration(firstEvent.value)
    } else if (firstEvent.field === 'chargeCycles' || firstEvent.field === 'dischargeCycles') {
      if (type === 'table') {
        firstEvent.previousValue = firstEvent.value - firstEvent.cycleValueChange
        firstValue = firstEvent.previousValue + '%'
        lastValue = firstEvent.value + '%'
      } else {
        return this.formatDuration(lastEvent.cycleDuration)
      }
    } else if (firstEvent.field === 'accountEmails') {
      return firstValue.previousValue
    } else if (firstEvent.field === 'ringRegistrations') {
      const lastIndex = rings?.findIndex((ring: any) => ring.macAddress === lastValue)
      const firstIndex = rings?.findIndex((ring: any) => ring.macAddress === firstValue)

      if (lastIndex >= 0) {
        lastValue = lastIndex === 0 ? 'Current' : `Old ${lastIndex}`
      }

      if (firstIndex >= 0) {
        firstValue = firstIndex === 0 ? 'Current' : `Old ${firstIndex}`
      }
    } else if (firstEvent.key === 'featureFlags') {
      const previousValue = lastEvent.previousValue
      let text = ''

      Object.keys(lastValue).forEach((key, index) => {
        if (!previousValue) {
          text += `${this.getFeatureFlagsName(key)}: ${this.getFeatureFlagsValue(lastValue[key])}${
            index === Object.keys(lastValue).length - 1 ? '' : ', '
          }`
        } else {
          text += !isEqual(previousValue, lastValue)
            ? `${this.getFeatureFlagsName(key)}: ${this.getFeatureFlagsValue(
                previousValue[key],
              )} -> ${this.getFeatureFlagsValue(lastValue[key])}${
                index === Object.keys(lastValue).length - 1 ? '' : ', '
              }`
            : ''
        }
      })

      return text
    }

    if (firstEvent.summary) {
      return lastValue
    } else {
      return firstEvent.previousValue == null && firstEvent === lastEvent ? firstValue : firstValue + ' -> ' + lastValue
    }
  }

  public formatDuration(duration: number) {
    const d = this.$dayjs.duration(duration, 'seconds')

    return d.days() + 'd ' + d.hours() + 'h ' + d.minutes() + 'm'
  }

  public getFeatureFlagsValue(name: any) {
    return typeof name === 'boolean' ? `${name ? 'enabled' : 'disabled'}` : name
  }

  public getFeatureFlagsName(key: any) {
    return key === 'enabled' ? 'Status' : (key.charAt(0).toUpperCase() + key.slice(1)).replaceAll('_', ' ')
  }

  public splitSummaries(data: any[], filters: string[], type: string): any[] {
    const events: any[][] = data.map((event) => {
      if (event.field === 'summaryData') {
        const summaries: any[] = []

        if (type === 'table') {
          if (event.value['TimeZone'] != null) {
            summaries.push({
              ...event,
              summary: true,
              field: 'Timezone',
              value: event.value['TimeZone'],
            })
          } else if (event.value['TimeZoneChange'] != null) {
            summaries.push({
              ...event,
              summary: true,
              field: 'Timezone change',
              value: event.value['TimeZoneChange'],
            })
          }
        }

        const summariesFields = filters.filter((name: string) => summaryFields.includes(name))

        summariesFields.forEach((summary) => {
          Object.keys(event.value).forEach((key: string) => {
            if (key.includes(summary)) {
              summaries.push({
                ...event,
                summary: true,
                field: type === 'table' ? this.formatKey(key) : key,
                value: event.value[key],
              })
            }
          })
        })

        return summaries
      } else if (event.field === 'featureFlags') {
        const featureFlags: any[] = []

        const featureFilterKeys = Object.keys(event.value)

        featureFilterKeys.forEach((key: string) => {
          if (event.value[key] && (!event.previousValue || event?.difference[key])) {
            featureFlags.push({
              ...event,
              key: event.field,
              field: key,
              value: event.value[key],
              timestamp: event.timestamp,
              previousValue: event.previousValue ? event.previousValue[key] : null,
            })
          }
        })

        return featureFlags
      } else {
        return [
          {
            ...event,
            timestamp: this.getTimestamp(event.timestamp),
          },
        ]
      }
    })

    return events.flat()
  }

  public formatKey(txt: string) {
    return txt.replace(/\s+/g, '_')
  }

  public getTimestamp(timestamp: string) {
    return new Date(timestamp).getTime() + this.getTimezone() * 60 * 60 * 1000
  }
}
