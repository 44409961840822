<template>
  <div class="pa-0">
    <v-card>
      <v-card-title>
        <p v-if="title" class="text-overline">{{ title }}</p>
      </v-card-title>

      <v-data-table
        :items="events"
        :headers="headers"
        :items-per-page="100"
        :expanded="expanded"
        :sort-by="[{ key: 'time', order: 'desc' }]"
        :group-by="[{ key: 'date', order: 'desc' }]"
        show-expand
        single-expand
        item-key="index"
        class="elevation-1"
      >
        <template #[`item.icon`]="{ item }">
          <v-tooltip v-if="checkWarning(item)" location="top">
            <template #activator="{ props }">
              <v-icon icon="mdi-alert-outline" color="warning" v-bind="props" />
            </template>
            <span>{{ checkWarning(item) }}</span>
          </v-tooltip>
        </template>
        <template #[`item.time`]="{ item }">
          {{ formatDateTime(item.time, 'HH:mm') }}
          {{ formatDateTime(item.date, 'dddd Do MMMM YYYY') }}
        </template>
        <template #group-header="{ item, columns, toggleGroup, isGroupOpen }">
          <tr class="group-header">
            <td :colspan="columns.length" @click="toggleGroup(item)">
              <v-btn variant="text" color="grey-darken-1" :icon="isGroupOpen(item) ? 'mdi-minus' : 'mdi-plus'" />
              {{ formatDateTime(item.value, 'dddd Do MMMM YYYY') }}
            </td>
          </tr>
        </template>
        <template #[`item.data-table-expand`]="{ item, isExpanded }">
          <v-tooltip v-if="item.diff && rights.includes('allowDataDownloadAccess')" location="top">
            <template #activator="{ props }">
              <v-icon icon="mdi-database" v-bind="props" @click="showDiff(item.diff)" />
            </template>
            <span>Show config changes</span>
          </v-tooltip>

          <v-icon v-if="item.more.length && !isExpanded" icon="mdi-chevron-down" @click="expand(true)" />
          <v-icon v-if="item.more.length && isExpanded" icon="mdi-chevron-up" @click="expand(false)" />
        </template>

        <template #expanded-row="{ item }">
          <td colspan="6" class="pa-0">
            <v-data-table
              v-if="item.more.length"
              :items="item.more"
              :headers="headers.filter((header) => header.key !== 'date')"
              :items-per-page="item.more.length"
              :sort-by="[{ key: 'time', order: 'desc' }]"
              :loading="dataWait"
              sort-desc
              hide-default-footer
              hide-default-header
              style="background: transparent"
            >
              <!-- eslint-disable-next-line vue/no-template-shadow -->
              <template #item.time="{ item }">
                {{ formatDateTime(item.time, 'HH:mm') }}
              </template>
              <template #[`item.data-table-expand`] />
            </v-data-table>
          </td>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script lang="ts">
  import { groupBy, isEqual, map, method, some } from 'lodash-es'

  import { Component, Emit, Prop, Watch, mixins, toNative } from 'vue-facing-decorator'

  import { getValue } from '@firebase/remote-config'

  import { DateTime } from '#mixins/dateTime'
  import { EventData } from '#mixins/eventData'

  import { chargingSlopeFailThreshold, eventsHeaders, fgHealthFailThreshold } from '#views/users/constants'

  import { AppStore, TimelineStore } from '#stores'

  @Component({
    mixins: [EventData],
  })
  class List extends mixins(DateTime, EventData) {
    @Prop() public data!: any[]
    @Prop() public end!: any[]
    @Prop() public start!: any[]
    @Prop() public rings!: any[]
    @Prop() public title!: string
    @Prop() public filters!: any[];

    // hack for mixins
    [x: string]: any

    public AppsStore = new AppStore()
    public timelineStore = new TimelineStore()

    public expanded = []

    public events: { diff: any[] | null; date: number; time: number; more: any[] }[] = []

    public headers = eventsHeaders

    private averageDrainage = 0

    public get dataWait() {
      return this.timelineStore.dataWait
    }

    public get rights() {
      return this.AppsStore.getRights
    }

    @Emit('show-diff')
    public showDiff(diff: any) {
      return diff
    }

    @Watch('data')
    protected onDataChanged(_val: any[], _oldVal: any[]) {
      this.updateData()
    }

    @Watch('userTimezone')
    protected onUserTimezoneChanged(_val: string, _oldVal: string) {
      this.updateData()
    }

    @Watch('prefsTimezoneSetting')
    protected onTimezoneSettingChanged(_val: string, _oldVal: string) {
      this.updateData()
    }

    @Watch('filters')
    protected onFiltersChanged(_val: any[], _oldVal: any[]) {
      this.updateData()
    }

    public mounted() {
      this.updateData()
    }

    public checkWarning(event: any) {
      if (event.type === 'batteryData') {
        if (event.data.drainage > 1.5 * this.averageDrainage) {
          return 'High battery draining'
        }
      }
      if (event.type === 'Charging slope') {
        if (event?.data?.value && event.data.value > chargingSlopeFailThreshold) {
          return 'Increased charging speed'
        }
      }
      if (event.type === 'Battery health') {
        if (event?.data?.value && event.data.value < fgHealthFailThreshold) {
          return 'Lowered battery health'
        }
      }

      return null
    }

    private updateData() {
      let filters = this.filters
      if (!getValue(this.$remoteConfig, 'batteryHealthSummaryInfo').asBoolean()) {
        filters = filters.filter((x) => {
          return x !== 'Battery health'
        })
      }

      const data = this.splitSummaries(this.data, filters, 'list')

      const groups = groupBy(
        data,
        (data) =>
          data.field +
          (data.field === 'batteryData' ? '' : data.timestamp) +
          '|' +
          this.$dayjs(data.timestamp).startOf('day').format(),
      )

      let index = 0
      const batteryData: any[] = []

      const cccFilters = [
        'accountEmails',
        'ringRegistrations',
        'bootloaderVersion',
        'firmwareVersion',
        'osVersion',
        'clientVersion',
      ]

      this.events = map(groups, (group, _key) => {
        let data: any = null

        const last = group.length - 1

        const time = new Date(group[last].timestamp)

        if (group[0].field === 'batteryData') {
          const values = group.map((event) => event.value)

          const max = values.lastIndexOf(Math.max(...values))
          const min = max + values.slice(max).indexOf(Math.min(...values.slice(max)))

          const change = group[max].value - group[min].value
          const duration = new Date(group[min].timestamp).getTime() - new Date(group[max].timestamp).getTime()

          data = {
            change,
            duration,
            drainage: duration ? change / duration : 0,
          }

          if (data.drainage) {
            batteryData.push(data)
          }
        }

        if (group[0].field === 'Charging slope' && group[0].value) {
          group[0].value = group[0].value.toFixed(2)
          const values = group.map((event) => event.value)
          data = {
            value: values[0],
          }
        }

        if (group[0].field === 'Battery health') {
          const values = group.map((event) => event.value)
          data = {
            value: values[0],
          }
        }

        const more = group.map((event) => {
          return {
            type: event.field,
            time: new Date(event.timestamp).getTime(),
            name: this.getLabel(event),
            note: this.getNotes(event, event),
            info: this.getValue(event, event, this.rings, 'list'),
          }
        })

        return {
          index: index++,
          data,
          time: time.getTime(),
          date: new Date(this.formatDateTime(group[last].timestamp, '').substring(0, 10)).getTime(),
          type: group[0].field,
          name: this.getLabel(group[0]),
          note: this.getNotes(group[0], group[last]),
          info: this.getValue(group[0], group[last], this.rings, 'list'),
          more: more.length > 1 ? more : [],
          diff: cccFilters.includes(group[0].field) ? group : null,
        }
      })

      if (batteryData.length) {
        this.averageDrainage = batteryData.map((data) => data.drainage).reduce((a, b) => a + b) / batteryData.length
      }
    }

    private getNotes(firstEvent: any, lastEvent: any): string {
      if (firstEvent.field === 'chargeCycles') {
        return 'Charge cycle: ' + this.formatDuration(lastEvent.cycleDuration)
      } else if (firstEvent.field === 'dischargeCycles') {
        return ' Discharge cycle: ' + this.formatDuration(lastEvent.cycleDuration)
      } else if (firstEvent.field === 'batteryData') {
        if (lastEvent.value === 0 && lastEvent.prevousValue === null) {
          return 'Possible ring shutdown'
        } else {
          return firstEvent === lastEvent
            ? 'Hourly change: ' + (lastEvent.value - firstEvent.previousValue) + '%'
            : 'Daily change: ' + (lastEvent.value - firstEvent.previousValue) + '%'
        }
      } else if (firstEvent.field === 'accountEmails') {
        return firstEvent.previousValue == null && firstEvent === lastEvent
          ? 'Oura account created'
          : 'Old e-mail: ' + lastEvent.previousValue
      } else if (firstEvent.field === 'ringRegistrations') {
        return firstEvent.previousValue == null && firstEvent === lastEvent
          ? 'First ring registration'
          : 'New MAC: ' + lastEvent.value
      } else if (firstEvent.field.includes('Version')) {
        return firstEvent.previousValue == null && firstEvent === lastEvent
          ? 'First version record'
          : 'Updated to newer version'
      } else if (firstEvent.field.includes('Charging slope') || firstEvent.field.includes('Battery health')) {
        return 'No additional info'
      } else if (some(this.filters, method('includes', firstEvent.field))) {
        if (!firstEvent.previousValue) {
          return 'First feature record'
        }

        const firstValue = firstEvent.value
        const lastValue = lastEvent.previousValue

        return isEqual(firstValue, lastValue)
          ? 'No changes'
          : firstValue.enabled !== lastValue.enabled
            ? 'Status changed'
            : 'Settings changed'
      }

      return 'No additional info'
    }
  }

  export default toNative(List)
</script>

<style lang="scss" scoped>
  :deep(.v-data-table .v-data-footer__select) {
    display: none;
  }
</style>
