<template>
  <v-row class="viewport mt-4 px-4 flex-sm-column flex-md-row">
    <v-col v-if="showFilters" cols="12" md="4">
      <v-text-field v-model="filter" placeholder="Filter for events..." />
    </v-col>
    <v-col class="d-flex" cols="12" md="8">
      <DateRangePicker
        v-model:start-date="startDateString"
        v-model:end-date="endDateString"
        :min-date="minDate || null"
        :max-day-range="180"
        @valid="datesAreValid = $event"
      />
      <v-spacer style="min-width: 32px" />

      <v-row class="flex-nowrap" align="baseline">
        <v-col v-if="showFilters">
          <v-btn-toggle v-model="edit" divided multiple>
            <v-tooltip location="top">
              <template #activator="{ props }">
                <v-btn color="white" icon="mdi-filter-outline" v-bind="props" />
              </template>
              <span>Toggle filters showing</span>
            </v-tooltip>
            <v-menu
              v-model="menu"
              transition="scale-transition"
              offset-y
              bottom
              location="end"
              origin="top right"
              max-width="250px"
              min-width="250px"
            >
              <template #activator="{ props }">
                <v-btn
                  color="white"
                  icon="mdi-menu-down-outline"
                  style="min-width: 24px; max-width: 24px"
                  v-bind="props"
                />
              </template>
              <v-list>
                <v-list-item @click="selectFilters('summary')">
                  <v-list-item-title>All summary data</v-list-item-title>
                </v-list-item>

                <v-divider />

                <v-list-item @click="saveFilters()">
                  <v-list-item-title>Save as default</v-list-item-title>
                </v-list-item>
                <v-list-item @click="clearFilters()">
                  <v-list-item-title>Clear filters</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-btn-toggle>
        </v-col>

        <v-col>
          <v-btn-toggle v-model="view" mandatory>
            <v-tooltip location="top">
              <template #activator="{ props }">
                <v-btn color="white" icon="mdi-format-list-bulleted" v-bind="props" />
              </template>
              <span>List view</span>
            </v-tooltip>
            <v-tooltip location="top">
              <template #activator="{ props }">
                <v-btn color="white" icon="mdi-table-large" v-bind="props" />
              </template>
              <span>Table view</span>
            </v-tooltip>
          </v-btn-toggle>
        </v-col>
      </v-row>
    </v-col>
  </v-row>

  <div>
    <v-expand-transition v-show="edit.includes(0)" class="viewport" style="overflow: hidden" multiple>
      <v-row class="ma-6">
        <v-col v-for="summaryField in summaryFields" :key="summaryField" class="my-n4 px-4" cols="3">
          <v-switch
            v-model="summaries"
            color="#e91e63"
            class="text-no-wrap"
            :value="summaryField"
            :label="summaryField"
          />
        </v-col>
      </v-row>
    </v-expand-transition>

    <v-container class="mt-n8" style="position: relative" :class="{ viewport: !expanded }">
      <v-icon
        :icon="expanded ? 'mdi-arrow-collapse' : 'mdi-arrow-expand'"
        style="position: absolute; right: 40px; top: 40px; z-index: 1"
        @click="expanded = !expanded"
      />
      <List
        v-if="view === 0"
        :data="summary"
        :rings="rings"
        :title="'Summary data report'"
        :filters="summaries"
        :start="startDateString"
        :end="endDateString"
      />
      <Table
        v-if="view === 1"
        :data="summary"
        :rings="rings"
        :title="'Summary data report'"
        :filters="summaries"
        :start="startDateString"
        :end="endDateString"
      />
    </v-container>
  </div>

  <v-snackbar v-model="snackbar" bottom right :timeout="5000">Filters and view saved as default</v-snackbar>
</template>

<script lang="ts">
  import { Component, Prop, Watch, mixins, toNative } from 'vue-facing-decorator'

  import { logEvent } from 'firebase/analytics'

  import { Debounce } from '@jouzen/outo-apps-toolkit'

  import { DateTime } from '#mixins/dateTime'

  import { allowSensitiveDataFields, summaryFields } from '#views/users/constants'

  import { AppStore, TimelineStore, UserStore } from '#stores'

  import { Ring } from '#types'

  @Component({})
  class Summary extends mixins(DateTime) {
    @Prop() public uuid!: string
    @Prop() public ring!: Ring
    @Prop() public rings!: Ring[]
    @Prop() public end!: number
    @Prop() public start!: number
    @Prop() public title!: string
    @Prop({ default: true }) public showFilters!: boolean
    @Prop({
      default: () => summaryFields,
    })
    public summaryFields!: string[]

    public view = 2

    public filter = ''

    public menu = false
    public snackbar = false
    public expanded = false

    public edit: number[] = [0]

    public summaries: string[] = []

    public minDate = ''
    public endDateString = ''
    public startDateString = ''

    public datesAreValid = false

    public appStore = new AppStore()

    public userStore = new UserStore()

    public timelineStore = new TimelineStore()

    public get rights() {
      return this.appStore.getRights || []
    }

    public get access() {
      return this.userStore.user?.ownerAccess || this.userStore.user?.sharedAccess || this.userStore.user?.supportAccess
    }

    public get summary() {
      const data = this.timelineStore.summary || []

      if (data.length) {
        logEvent(this.$analytics, 'troubleshooter_available', {
          category: `User:Timeline:Dashboard Summary data available`,
          action: 'Filters in Timeline:Dashboard Summary available',
          label: 'Filters in Timeline:Dashboard Summary available',
          page_title: 'Oura user',
          page_location: window.location.toString().split('?')[0],
        })
      }

      return data
    }

    @Watch('uuid')
    protected onUuidChanged() {
      this.updateData()
    }

    @Watch('ring')
    protected onRingChanged() {
      this.updateData()
    }

    @Watch('menu')
    protected onMenuChanged(val: boolean) {
      if (!val) {
        this.edit = this.edit.filter((selected) => selected !== 1)
      }
    }

    @Watch('expanded')
    protected onExpandedChanged(val: string) {
      const expands = JSON.parse(localStorage.OuraExpandedViews || '{}')

      expands.summary = val

      localStorage.OuraExpandedViews = JSON.stringify(expands)
    }

    @Watch('endDateString')
    protected onEndDateChanged() {
      logEvent(this.$analytics, 'troubleshooter_date_changed', {
        category: 'Timeline:Dashboard Summary end Date',
        action: 'Click endDate in Timeline:Dashboard Summary',
        label: `Click endDate in Timeline:Dashboard Summary`,
        page_title: 'Oura user',
        page_location: window.location.toString().split('?')[0],
      })
      this.updateData()
    }

    @Watch('startDateString')
    protected onStartDateChanged() {
      logEvent(this.$analytics, 'troubleshooter_date_changed', {
        category: `User:Timeline:Dashboard Summary start Date`,
        action: 'Click startDate in Timeline:Dashboard Summary',
        label: `Click startDate in Timeline:Dashboard Summary`,
        page_title: 'Oura user',
        page_location: window.location.toString().split('?')[0],
      })

      this.updateData()
    }

    @Watch('datesAreValid')
    protected ondDatesAreValidChanged() {
      this.updateData()
    }

    @Watch('filter')
    protected onFilterChanged(val: string) {
      const findField = summaryFields.filter((field: string) => field.toLowerCase().includes(val.toLowerCase().trim()))

      this.summaries = findField.length ? findField : summaryFields
    }

    @Watch('sensitiveDataVisible')
    protected onSensitiveDataVisibleChanged(_val: string) {
      this.updateData()
    }

    private get sensitiveDataVisible() {
      return this.appStore.sensitiveDataVisible
    }

    public mounted() {
      this.edit = []

      this.minDate = this.$dayjs().subtract(2, 'years').format('YYYY-MM-DD')
      this.endDateString = this.$dayjs().format('YYYY-MM-DD')
      this.startDateString = this.$dayjs().subtract(14, 'days').format('YYYY-MM-DD')

      this.expanded = JSON.parse(localStorage.OuraExpandedViews || '{}').summary

      if (localStorage['OuraSummaryView']) {
        this.view = parseInt(localStorage['OuraSummaryView'])
      } else if (this.summary) {
        this.view = 1
      }

      const config = JSON.parse(localStorage['OuraSummaryFilters'] || '{}')

      this.summaries = this.getAvailableFields(config.summaries)

      // Filter out sensitive summaries if no access or allow sensitive data
      if (!this.access && !this.rights.includes('allowSensitiveDataAccess')) {
        this.summaries = this.summaries.filter((field: string) => !allowSensitiveDataFields.includes(field))
      }

      this.updateData()
    }

    public setView(view: number) {
      this.edit = []

      this.view = view
    }

    public saveFilters() {
      this.snackbar = true

      localStorage['OuraSummaryView'] = this.view

      localStorage['OuraSummaryFilters'] = JSON.stringify({
        timeRange: [this.startDateString, this.endDateString],
        filters: 'SummaryData',
        summaries: this.summaries,
      })
    }

    public clearFilters() {
      this.edit = [0]
      this.summaries = []
    }

    public selectFilters(preset: string) {
      logEvent(this.$analytics, 'troubleshooter_use_presets', {
        category: `User:Timeline:Dashboard Summary preset`,
        action: 'Click Timeline:Dashboard Summary preset',
        label: `Click Timeline:Dashboard Summary preset`,
        page_title: 'Oura user',
        page_location: window.location.toString().split('?')[0],
      })

      if (preset === 'summary') {
        this.summaries = this.getAvailableFields()
      }
    }

    public summariesChanged(status: boolean) {
      logEvent(this.$analytics, `troubleshooter_${status ? 'enabled' : 'disabled'}`, {
        category: `User:Timeline:Dashboard Summary filter switch ${status ? 'enabled' : 'disabled'}`,
        action: `Click Timeline:Dashboard Summary filter switch ${status ? 'enabled' : 'disabled'}`,
        label: `Click Timeline:Dashboard Summary filter switch ${status ? 'enabled' : 'disabled'}`,
        page_title: 'Oura user',
        page_location: window.location.toString().split('?')[0],
      })
    }

    @Debounce(1000)
    private updateData() {
      if (this.uuid && this.datesAreValid) {
        this.timelineStore.getSummaryData({
          uuid: this.uuid,
          ring: this.ring,
          from: this.startDateString,
          to: this.endDateString,
        })
      }
    }

    private getAvailableFields(userChosenFields?: string[]): string[] {
      let availableFields = this.summaryFields

      // TODO: validate userChosenFields to only allow field names we actually support.
      if (userChosenFields) {
        availableFields = userChosenFields.filter((field: string) => this.summaryFields.includes(field))
      }

      const ringTypesWithSilaBattery: string[] = ['Gen3(S)']
      const ringType = this.ring?.ringSerialInfo?.hwMajor ?? ''

      // Remove 'Charging slope' field if ring has sila battery
      if (ringTypesWithSilaBattery.includes(ringType)) {
        const removeIndex = availableFields.indexOf('Charging slope')
        if (removeIndex > -1) {
          availableFields.splice(removeIndex, 1)
        }
      }
      return availableFields
    }
  }

  export default toNative(Summary)
</script>

<style lang="scss" scoped>
  :deep(.v-input--switch) {
    transform: scale(0.8);
  }

  :deep(.v-switch .v-label) {
    white-space: nowrap;
  }
</style>
