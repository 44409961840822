<template>
  <v-banner
    v-if="lastSyncBanner && ringLastSyncBannerFeature"
    v-show="syncMessage"
    single-line
    :color="lastSyncBanner.alertColor"
    density="compact"
    :icon="lastSyncBanner.clockIcon"
    class="viewport pt-3"
    :text="syncMessage"
  />
</template>

<script lang="ts">
  import { Component, Prop, Watch, mixins, toNative } from 'vue-facing-decorator'

  import { DateTime } from '#mixins/dateTime'

  import { ringLastSyncBannerFeature } from '#utils/darwinFeatureFlags'

  import { DiagnosticsStore } from '#stores'

  import { Ring } from '#types'

  @Component
  class LastSyncbanner extends mixins(DateTime) {
    @Prop() public ring!: Ring
    public diagnosticsStore = new DiagnosticsStore()

    public syncMessage: string = ''

    @Watch('lastSyncBanner')
    protected onLastSyncDateChanged(_val: string, _oldVal: string) {
      if (this.lastSyncBanner?.syncMessage) {
        this.syncMessage = this.lastSyncBanner?.lastSyncDate
          ? `Last sync:  ${this.formatDateTime(this.lastSyncBanner?.lastSyncDate, 'DD MMM YYYY, HH:mm:ss')} `
          : ''
        this.syncMessage += this.lastSyncBanner?.syncMessage
      }
    }

    public created() {
      if (this.ring?.serialNumber) {
        this.diagnosticsStore.getLastSyncDiagnosticsFromRingHardwareInfo(this.ring.serialNumber)
      }
    }

    public get ringLastSyncBannerFeature(): boolean {
      return ringLastSyncBannerFeature()
    }

    public get lastSyncBanner() {
      return this.diagnosticsStore.lastSyncBanner
    }
  }

  export default toNative(LastSyncbanner)
</script>
