<template>
  <div v-if="singleDeviceInfo" class="px-4">
    <v-row class="align-center mt-n6">
      <v-col class="d-flex align-center" cols="12" :md="singleDeviceInfo.macAddress ? '6' : '12'">
        <div class="d-flex text-no-wrap">
          <span class="pr-2">{{ singleDeviceInfo.serialNumberTitle }}</span>
          <span>
            {{ singleDeviceInfo.serialNumber }}
          </span>
        </div>

        <v-tooltip location="top">
          <template #activator="{ props }">
            <v-btn
              v-bind="props"
              variant="plain"
              color="grey"
              icon="mdi-content-copy"
              class="mr-n4"
              @click="useClipboard.copy(singleDeviceInfo.serialNumber)"
            />
          </template>
          {{ useClipboard.copied ? 'Copied' : 'Copy to clipaboard' }}
        </v-tooltip>
      </v-col>

      <v-col v-if="singleDeviceInfo.macAddress" class="d-flex align-center" cols="12" md="6">
        <span class="user-select-none">MAC: {{ singleDeviceInfo.macAddress }}</span>
        <v-tooltip location="top">
          <template #activator="{ props }">
            <v-btn
              v-bind="props"
              color="grey"
              variant="plain"
              class="mr-n4"
              icon="mdi-content-copy"
              @click="useClipboard.copy(singleDeviceInfo.macAddress)"
            />
          </template>
          {{ useClipboard.copied ? 'Copied' : 'Copy to clipboard' }}
        </v-tooltip>
      </v-col>
    </v-row>

    <v-row class="align-end mt-n4">
      <v-col cols="12" class="d-flex flex-row ga-2">
        <v-text-field label="Factory" :model-value="singleDeviceInfo.factory" readonly placeholder="Unknown" />
        <v-text-field label="HW Major" :model-value="singleDeviceInfo.hwMajor" readonly placeholder="Unknown" />
        <v-text-field label="HW Minor" :model-value="singleDeviceInfo.hwMinor" readonly placeholder="Unknown" />
        <v-text-field label="Product" :model-value="singleDeviceInfo.product" readonly placeholder="Unknown" />
        <v-text-field
          style="max-width: 60px"
          label="Size"
          :model-value="singleDeviceInfo.size"
          readonly
          placeholder="Unknown"
        />
        <v-text-field label="Color" :model-value="singleDeviceInfo.color" readonly placeholder="Unknown" />
        <v-text-field label="Design" :model-value="singleDeviceInfo.design" readonly placeholder="Unknown" />
        <v-text-field
          label="MFG year"
          :model-value="singleDeviceInfo.manufacturingYear"
          readonly
          placeholder="Unknown"
        />
        <v-text-field
          label="MFG week"
          :model-value="singleDeviceInfo.manufacturingWeek"
          readonly
          placeholder="Unknown"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
  import { useClipboard } from '@vueuse/core'

  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { DeviceInfoCharger, DeviceInfoRing } from '#types'

  @Component
  class SingleDeviceInfo extends Vue {
    @Prop() public singleDeviceInfo!: DeviceInfoRing | DeviceInfoCharger

    public useClipboard = useClipboard()
  }

  export default toNative(SingleDeviceInfo)
</script>

<style lang="scss" scoped>
  .active {
    color: #2196f3;
  }
  .user-select-none {
    user-select: none;
  }
</style>
