<template>
  <v-container class="viewport">
    <Info v-if="user" v-model:ring="ring" :user="user" :rings="user.rings" />

    <EmailV2 class="mt-8" :member="user" :account-info="true" />

    <Subscription class="mt-8" :user="user" />

    <Payments
      v-if="rights.includes('rolesPaymentViewer') || rights.includes('rolesPaymentAdmin')"
      class="mt-8"
      :user="user"
    />

    <PaymentMethods
      v-if="rights.includes('rolesPaymentViewer') || rights.includes('rolesPaymentAdmin')"
      class="mt-8"
      :user="user"
    />

    <Invoices
      v-if="rights.includes('rolesPaymentViewer') || rights.includes('rolesPaymentAdmin')"
      class="mt-8"
      :user="user"
    />

    <CreditMemos
      v-if="rights.includes('rolesPaymentViewer') || rights.includes('rolesPaymentAdmin')"
      class="mt-8"
      :user="user"
    />

    <Entitlements class="mt-8" :ring-serial="ring ? ring.serialNumber : ''" />

    <Features class="mt-8" :uuid="user?.uuid" />
  </v-container>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { AppStore } from '#stores'

  import { Member, Ring } from '#types'

  @Component
  class AccountInfo extends Vue {
    @Prop() public user!: Member
    @Prop() public ring!: Ring
    @Prop() public rings!: Ring[]

    /**
     * Use email V2 (=cloud management user details)
     */

    public appStore = new AppStore()

    public get rights() {
      return this.appStore.getRights || []
    }
  }

  export default toNative(AccountInfo)
</script>
