<template>
  <!-- eslint-disable vue/v-on-handler-style -->
  <v-app-bar title="Oura users / accounts">
    <v-spacer />

    <v-menu v-if="rights.includes('allowDataDeletionAccess')" left offset="8">
      <template #activator="{ props }">
        <v-tooltip location="bottom">
          <template #activator="{ props: tooltipProps }">
            <v-btn v-bind="{ ...props, ...tooltipProps }" icon="mdi-account-details-outline" />
          </template>
          Account actions
        </v-tooltip>
      </template>
      <v-list>
        <v-list-item v-if="rights.includes('allowDataDeletionAccess')" @click="brazeDelete = true">
          <v-list-item-title class="red--text">Delete user data from Braze</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>

  <v-container>
    <v-row>
      <v-col cols="12" md="9">
        <div class="text-h5 font-weight-light">Access Oura user accounts for troubleshooting</div>

        <div class="text-subtitle-2 text-medium-emphasis font-weight-light">
          Remember that you always need concent to access other users accounts!
        </div>
      </v-col>

      <v-col md="3" cols="12" class="text-right">
        <v-btn color="primary" :disabled="!searchButtonActive" @click="loadUsers()">Run search</v-btn>
      </v-col>
    </v-row>

    <v-row class="mt-4">
      <v-col class="d-flex align-start" cols="12" sm="12" md="7">
        <v-text-field
          ref="search"
          v-model.trim="search"
          autofocus
          clearable
          label="Search for users"
          append-inner-icon="mdi-account-search"
          :hide-details="false"
          :hint="searchInputHint"
          :disabled="searchInputDisabled"
          :error-messages="searchInputErrorMessages"
          :placeholder="'Type search term and press enter to search users...'"
          @click:clear="reset()"
          @click:append="loadUsers()"
          @keydown.enter="loadUsers()"
          @keyup="searchConfigCheck()"
        />
      </v-col>

      <v-col class="d-flex align-start ga-8" cols="12" sm="12" md="5">
        <v-spacer />

        <v-checkbox
          v-model="fuzzyMatching"
          class="mt-n2"
          label="Fuzzy matching"
          :disabled="!fuzzyMatchingCheckboxEnabled"
          @change="searchConfigCheck()"
        />

        <v-checkbox
          v-model="deletedAccountsOnly"
          class="mt-n2"
          label="Deleted accounts only"
          :disabled="!deletedAccountsOnlyCheckboxEnabled"
          @change="searchConfigCheck()"
        />
      </v-col>
    </v-row>

    <v-alert
      v-for="notification in searchResultNotifications"
      :key="notification"
      type="info"
      width="100%"
      class="my-4"
      dismissible
      transition="slide-x-transition"
    >
      <span>{{ notification }}</span>
    </v-alert>

    <v-alert
      v-if="searchUserError"
      tile
      variant="tonal"
      type="error"
      color="red"
      class="px-6"
      style="position: relative"
    >
      Error occured while trying to search a member: {{ searchUserError }}
    </v-alert>

    <v-sheet class="mt-4">
      <v-data-table
        :search="filterTableData"
        :headers="userSearchTableHeader"
        :items="users"
        :items-per-page="-1"
        :group-by="[{ key: 'Group', order: 'desc' }]"
        :sort-by="[{ key: 'email', order: 'asc' }]"
        :sort-desc="[true, false]"
        :loading="dataWait"
        group-desc
        disable-sort
        disable-pagination
        hide-default-footer
        item-value="type"
        :no-data-text="dataWait ? 'Loading search results...' : 'No matches / results found'"
        :no-results-text="filter ? 'Press enter to perform search' : 'No matching accounts found'"
      >
        <template #item="{ item }">
          <tr :class="{ disabled: isMemberCompletelyDeleted(item) }" @click="editUser(item)">
            <td>{{ '' }}</td>
            <td>
              <div v-if="!item.email">Email address deleted</div>
              <HoverCopy v-else :data="item.email" :message="'Copy email to clipboard'" />
            </td>
            <td><HoverCopy :data="item.uuid" :message="'Copy UID to clipboard'" /></td>
            <td>
              <v-tooltip location="top">
                <template #activator="{ props }">
                  <v-icon
                    small
                    class="pr-2"
                    :color="
                      item.flags?.markedForDeletion
                        ? 'red'
                        : item.flags?.queuedForDeletion
                          ? 'black'
                          : item.flags?.userRequestedDeletion
                            ? 'blue'
                            : 'grey lighten-1'
                    "
                    v-bind="props"
                  >
                    mdi-trash-can-outline
                  </v-icon>
                </template>
                {{
                  item.flags?.userRequestedDeletion
                    ? 'Deletion requested'
                    : item.flags?.markedForDeletion
                      ? 'Pending for deletion'
                      : 'Queued for deletion'
                }}
              </v-tooltip>
              <v-tooltip location="top">
                <template #activator="{ props }">
                  <v-icon
                    small
                    class="pr-2"
                    :color="item.flags?.jzlogProcessingDisabled ? 'red' : 'grey lighten-1'"
                    v-bind="props"
                  >
                    mdi-alert-outline
                  </v-icon>
                </template>
                JZLog processing is disabled
              </v-tooltip>
              <v-tooltip location="top">
                <template #activator="{ props }">
                  <v-icon
                    small
                    class="pr-2"
                    :color="item.flags?.ucsfIllnessStudy ? 'blue' : 'grey lighten-1'"
                    v-bind="props"
                  >
                    mdi-thermometer
                  </v-icon>
                </template>
                Participating in UCSF study
              </v-tooltip>
              <v-tooltip location="top">
                <template #activator="{ props }">
                  <v-icon
                    small
                    class="pr-2"
                    :color="item && item.flags?.usingExperimentalApp ? 'purple' : 'grey lighten-1'"
                    v-bind="props"
                  >
                    mdi-test-tube
                  </v-icon>
                </template>
                Using experimental app
              </v-tooltip>
            </td>
            <td>{{ item.ringCount }}</td>
            <td>
              {{ item.registrationDate ? formatDateTime(item.registrationDate, 'D MMM YYYY', 0) : 'No data available' }}
            </td>
            <td v-if="item.deletionTickets">
              {{ isLatestDeletedTicket(item.deletionTickets)?.status ?? '' }}
            </td>
            <td v-if="item.deletionTickets">
              {{ isLatestDeletedTicket(item.deletionTickets)?.initiationType ?? '' }}
            </td>
            <td v-if="item.deletionTickets">
              {{
                isLatestDeletedTicket(item.deletionTickets)?.createdAt
                  ? formatDateTime(isLatestDeletedTicket(item.deletionTickets).createdAt, 'D MMM YYYY', 0)
                  : ''
              }}
            </td>
          </tr>
        </template>

        <template #group-header="{ item, columns, toggleGroup, isGroupOpen }">
          <tr
            :ref="
              (el) => {
                if (!loaded && !isGroupOpen(item)) {
                  loaded = true
                  toggleGroup(item)
                }
              }
            "
            class="group-header"
          >
            <td :colspan="columns.length" class="pa-0" @click="toggleGroup(item)">
              <v-btn variant="text" color="grey-darken-1" :icon="isGroupOpen(item) ? 'mdi-minus' : 'mdi-plus'" />
              {{ item.value }} ({{ item.items.length }})
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-sheet>

    <v-dialog v-model="brazeDelete" width="600">
      <v-card>
        <v-card-title>Delete user data from Braze</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="brazeEmail"
            label="Braze user email to search for"
            :loading="brazeLoading"
            :prepend-icon="'mdi-account-search'"
            @keyup.enter="searchBrazeUsers()"
            @click:append-outer="searchBrazeUsers()"
          />
          <div>
            <div v-for="user in brazeUsers" :key="user.brazeId" class="d-flex flex-row align-baseline">
              <v-checkbox v-model="brazeUsersSelections" :item-value="user.brazeId" class="mt-0 pt-0">
                <template #label>
                  <div :style="{ color: `${user.externalId ? 'red' : ''}` }">
                    {{ user.brazeId }}
                    <span v-if="user.externalId">(is connected to an Oura account)</span>

                    <span v-if="!user.externalId">(was connected to an Oura account)</span>
                  </div>
                </template>
              </v-checkbox>
            </div>
          </div>
          <div>
            Braze user ID's can be seen above by searching with email. Due to Braze API limitations if you want to make
            sure that the user was actually deleted you need to check that manually by searching for the user in the
            Braze console.
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="brazeDelete = false">Cancel</v-btn>
          <v-btn
            color="error"
            class="ml-4"
            :disabled="!brazeUsersSelections.length || brazeLoading"
            @click="deleteBrazeUsers()"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="brazeSnackbar" bottom right :timeout="5000">
      {{ brazeResponse }}
    </v-snackbar>
  </v-container>
</template>

<script lang="ts">
  import { sortBy } from 'lodash-es'

  import { useClipboard } from '@vueuse/core'

  import { Component, mixins, toNative } from 'vue-facing-decorator'

  import { logEvent } from 'firebase/analytics'

  import { DateTime } from '#mixins/dateTime'

  import { userListDeleteHeaders, usersListFilters, usersListHeaders } from '#views/users/constants'

  import {
    MemberSearchDeletionTicketResponse,
    SearchActionParameters,
    SearchConfig,
    SearchType,
    UserSearch,
    UserSearchResponseSingleUser,
    isSearchError,
  } from '#utils/user/search'

  import { AppStore, UserStore } from '#stores'

  @Component
  class UsersView extends mixins(DateTime) {
    public search = ''
    public displaySearchResults = false
    public brazeEmail = ''
    public brazeResponse = ''

    // "filter" can be removed when old search is no longer used
    public filter = false
    public loaded = false
    public brazeDelete = false
    public brazeLoading = false
    public brazeSnackbar = false

    public headers = usersListHeaders
    public allFilters = usersListFilters

    public brazeUsersSelections: any[] = []
    public searchInputError: string = ''
    public searchAllowed = true
    public searchConfig: SearchConfig | null = null
    public searchErrorKeyword = ''
    public searchInputHintMessage = ''
    public fuzzyMatching: boolean | null | undefined = null
    public fuzzyMatchingCheckboxEnabled: boolean = false
    public deletedAccountsOnly: boolean | null | undefined = null
    public deletedAccountsOnlyCheckboxEnabled: boolean = false
    public filterTableData: string = ''
    public userSearchTableHeader = usersListHeaders

    public useClipboard = useClipboard()

    public userStore = new UserStore()
    public appStore = new AppStore()

    public get users() {
      let users: UserSearchResponseSingleUser[] = []
      if (this.displaySearchResults) {
        users = this.userStore.searchResultUsers
        // TODO: Remove this once vuetify has support for expanding groups by default
        users = users.map((user) => {
          delete user['Group']
          return user
        })
      } else {
        users = this.userStore.sharedUsers
      }

      return users
    }

    public get numberOfResults() {
      return this.userStore.searchUserCount
    }

    public get maxResults() {
      return this.userStore.searchUserMaxResults
    }

    public get searchButtonActive() {
      return this.search && this.searchAllowed
    }

    public get searchResultNotifications() {
      const notifications: string[] = []
      if (this.maxResults && this.numberOfResults && this.numberOfResults >= this.maxResults) {
        notifications.push('You reached search result limit of ' + this.maxResults + ' users.')
      }
      return notifications
    }

    public get rights() {
      return this.appStore.getRights
    }

    public get dataWait() {
      return this.userStore.dataWait
    }

    public get brazeUsers() {
      return this.userStore.brazeUsers
    }

    public get searchInputDisabled(): boolean {
      return false
    }

    public get searchInputHint(): string {
      return this.searchInputHintMessage
    }

    public get searchInputErrorMessages(): string[] {
      if (this.searchInputError) {
        return [this.searchInputError]
      }
      return []
    }

    public get searchUserError() {
      return this.userStore.searchUserError
    }

    public mounted() {
      // this.$emit('user-selected', null)

      this.search = decodeURIComponent((this.$route.query.search as string) || '')
      this.fuzzyMatching = decodeURIComponent((this.$route.query.fuzzyMatching as string) || 'false') == 'true'
      this.deletedAccountsOnly =
        decodeURIComponent((this.$route.query.deletedAccountsOnly as string) || 'false') == 'true'

      // On page load perform search config check + actual search
      if (this.search) {
        this.searchConfigCheck()
        if (this.searchConfig) {
          this.loadUsers(true)
        }
      } else {
        this.loadPersonalAccounts()
        this.loadSupportAccounts()
        this.loadSharedAccounts()
      }
    }

    public loadPersonalAccounts() {
      this.userStore.getPersonalAccounts()
    }

    public loadSupportAccounts() {
      this.userStore.getSupportAccounts()
    }

    public loadSharedAccounts() {
      this.userStore.getSharedAccounts()
    }

    public editUser(row: any) {
      const isCompletelyDeleted = this.isMemberCompletelyDeleted(row)
      if (!isCompletelyDeleted) {
        const uuid = row.uuid
        this.$router.push({ path: `/users/${uuid}` }).catch((_err) => {})
      }
    }

    public reset() {
      this.userStore.resetSearch()
      this.searchInputError = ''
      this.search = ''
      this.displaySearchResults = false
      this.fuzzyMatching = false
      this.fuzzyMatchingCheckboxEnabled = false
      this.deletedAccountsOnly = false
      this.deletedAccountsOnlyCheckboxEnabled = false
      this.userStore.searchUserError = ''
      this.userSearchTableHeader = usersListHeaders
      this.$router.push({}).catch((_err) => {})
      this.loadPersonalAccounts()
      this.loadSupportAccounts()
      this.loadSharedAccounts()
    }

    public loadUsers(forceSearch: boolean = false): any {
      return this.searchUsers(forceSearch)
    }

    private get searchType(): SearchType | undefined {
      let searchType: SearchType | undefined = undefined

      if (this.fuzzyMatching) {
        searchType = SearchType.SEARCH_TYPE_EMAIL_FUZZY
      }

      if (this.deletedAccountsOnly) {
        searchType = SearchType.SEARCH_TYPE_EMAIL_DELETED_EXACT
      }

      return searchType
    }

    public searchConfigCheck(): SearchConfig | undefined {
      if (this.search != this.searchErrorKeyword) {
        this.searchAllowed = true
      }
      // This ensures that search box hint is visible even when user clicks on checkbox
      ;(this.$refs.search as HTMLElement).focus()

      const userSearch = new UserSearch()
      const searchConfig = userSearch.getSearchOptions(this.search, this.rights, this.searchType)

      if (isSearchError(searchConfig)) {
        this.searchInputError = searchConfig.errorMessage
        this.searchAllowed = false
        this.deletedAccountsOnlyCheckboxEnabled = false
        this.deletedAccountsOnly = false
        this.searchErrorKeyword = searchConfig.keyword
        this.searchConfig = null
        return
      }

      this.searchInputError = ''
      this.searchAllowed = true
      this.searchErrorKeyword = ''

      if (searchConfig.confirmationMessage) {
        this.searchInputHintMessage = searchConfig.confirmationMessage
      }

      this.fuzzyMatchingCheckboxEnabled = searchConfig.checkboxes.fuzzyMatching.enabled
      this.deletedAccountsOnlyCheckboxEnabled = searchConfig.checkboxes.deletedAccountsOnly.enabled

      if (!this.fuzzyMatchingCheckboxEnabled) {
        this.fuzzyMatching = false
      }

      if (!this.deletedAccountsOnlyCheckboxEnabled) {
        this.deletedAccountsOnly = false
      }

      this.searchConfig = searchConfig
    }

    public async searchUsers(forceSearch: boolean = false) {
      if (this.searchInputError || !this.searchConfig) {
        return
      }

      if (forceSearch || this.search) {
        this.$router
          .push({
            query: {
              //remove encodeURIComponent since router Vue2x already encode for query string
              search: this.search.toLowerCase() || '',
              fuzzyMatching: this.fuzzyMatching ? 'true' : 'false',
              deletedAccountsOnly: this.deletedAccountsOnly ? 'true' : 'false',
            },
          })
          .catch((_err) => {})

        const searchActionParams: SearchActionParameters = {
          search: this.searchConfig.keyword,
          searchType: this.searchConfig.searchType,
        }

        if (this.deletedAccountsOnly) {
          await this.userStore.searchDeleted(searchActionParams).then((_response) => {
            this.displaySearchResults = true
          })

          this.userSearchTableHeader = [...usersListHeaders, ...userListDeleteHeaders]
        } else {
          this.userStore.search(searchActionParams).then((_response) => {
            this.displaySearchResults = true
          })

          this.userSearchTableHeader = usersListHeaders
        }
      }

      logEvent(this.$analytics, 'user_search', {
        category: `Users:User Search`,
        action: 'Click user search',
        label: 'Click user search',
        page_title: 'Oura users',
        page_location: window.location.toString().split('?')[0],
      })
    }

    public addFilter(_item: any) {
      return
    }

    public removeFilter(_filter: string) {
      return
    }

    public deleteBrazeUsers() {
      this.brazeDelete = false

      this.userStore.deleteBrazeUsers(this.brazeUsersSelections).then((response) => {
        this.brazeSnackbar = true

        this.brazeResponse = response?.deleted
          ? 'Braze delete request send succesfully'
          : 'Braze delete request sending failed'
      })
    }

    public async searchBrazeUsers() {
      this.brazeLoading = true

      await this.userStore.getBrazeUsers(this.brazeEmail)

      this.brazeLoading = false
    }

    public isLatestDeletedTicket(deletionTicket: MemberSearchDeletionTicketResponse[]) {
      const sortTicketByCreatedAt = sortBy(deletionTicket, 'createdAt')
      return sortTicketByCreatedAt[0] ?? {}
    }

    public isMemberCompletelyDeleted(searchResponse: UserSearchResponseSingleUser) {
      return searchResponse.deletionTickets?.some(
        (ticket: MemberSearchDeletionTicketResponse) => ticket.status === 'complete',
      )
    }
  }

  export default toNative(UsersView)
</script>

<style lang="scss" scoped>
  .bg {
    height: 100%;
  }

  .loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  :deep(.v-data-table) {
    tr:hover {
      cursor: pointer;
    }

    .hidden td {
      display: none;
    }

    .v-row-group__header {
      .text-start {
        button:nth-child(2) {
          display: none;
        }
      }
    }

    .disabled {
      opacity: 0.5;
      cursor: auto !important;
    }
  }
</style>
