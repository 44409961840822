<template>
  <div>
    <v-card :loading="dataWait" min-width="100%">
      <v-card-title>
        <p class="text-overline">Entitlements</p>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="entitlements"
        :items-per-page="10"
        no-data-text="No entitlements found"
      />
    </v-card>
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Watch, mixins, toNative } from 'vue-facing-decorator'

  import { DateTime } from '#mixins/dateTime'

  import { EntitlementsStore } from '#stores'

  @Component
  class Entitlements extends mixins(DateTime) {
    @Prop() public ringSerial!: string

    public entitlementsStore = new EntitlementsStore()

    public headers = [
      {
        key: 'ringSerialNumber',
        title: 'Ring serial',
      },
      {
        key: 'entitlementGroup',
        title: 'Group',
      },
      {
        key: 'numberOfActivations',
        title: 'Number of activations',
      },
      {
        key: 'maxNumberOfActivations',
        title: 'Max activations',
      },
      {
        key: 'voided',
        title: 'Voided',
      },
      {
        key: 'type',
        title: 'type',
      },
    ]

    @Watch('ring')
    protected onRingChanged(_val: any, _oldVal: any) {
      this.updateEntitlements()
    }

    public mounted() {
      this.updateEntitlements()
    }

    public get entitlements() {
      return this.entitlementsStore.entitlements
    }

    public get dataWait(): boolean {
      return this.entitlementsStore.dataWait
    }

    private updateEntitlements() {
      if (this.ringSerial) {
        this.entitlementsStore.getEntitlements({
          ringSerial: this.ringSerial,
        })
      }
    }
  }

  export default toNative(Entitlements)
</script>

<style lang="scss" scoped></style>
