import { Pinia, Store } from 'pinia-class-component'

@Store
export class InternalDebugStore extends Pinia {
  public dataWait: boolean = false

  public async testSentryErrorEndpoint(data: any) {
    this.dataWait = true
    await this.$axios.get('/api/v1/testing/sentry-error', { params: data })
    this.dataWait = false
  }
}
