<template>
  <div>
    <v-card>
      <v-card-title class="d-flex justify-space-between">
        <p class="text-overline">Invoices</p>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="invoices"
        :items-per-page="10"
        :sort-by="[{ key: 'date', order: 'asc' }]"
        sort-desc
        item-key="date"
        class="elevation 1"
        no-data-text="No invoice data available"
      >
        <template #[`item.view-invoice`]="{ item }">
          <v-btn icon @click="showInvoice(item.invoiceId)">
            <v-tooltip location="top">
              <template #activator="{ props }">
                <v-icon v-if="item.invoiceId">mdi-receipt</v-icon>
                <v-icon v-else v-bind="props">mdi-close-box-outline</v-icon>
              </template>
              <span>Invoice Unavailable</span>
            </v-tooltip>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <PDFDialog v-model="invoiceVisible" :pdf="invoice" />
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { invoiceHeaders } from '#views/users/constants'

  import { SubscriptionStore } from '#stores'

  @Component
  class Invoices extends Vue {
    @Prop() public user!: any

    public subscriptionStore = new SubscriptionStore()

    public headers = invoiceHeaders
    public invoiceVisible = false

    public get subscriptions() {
      return this.subscriptionStore.subscriptions
    }

    public get invoices() {
      return this.subscriptionStore.invoices
    }

    public get invoice() {
      const invoice = this.subscriptionStore.invoice
      if (!invoice) {
        return null
      }
      return URL.createObjectURL(invoice)
    }

    @Watch('subscriptions')
    private subscriptionsChanged() {
      if (this.user) {
        // This is triggered three times in a single page load. Should be improved.
        this.subscriptionStore.getInvoices({ userId: this.user?.uuid })
      }
    }

    public async showInvoice(invoiceId: string) {
      await this.subscriptionStore.getInvoice({ userId: this.user?.uuid, invoiceId: invoiceId })
      this.invoiceVisible = true
    }
  }

  export default toNative(Invoices)
</script>

<style lang="scss" scoped></style>
