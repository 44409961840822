import { UserStore } from './user'

import { Pinia, Store } from 'pinia-class-component'

import * as Sentry from '@sentry/browser'

import { AppStore } from '#stores'

import { GraphMode, PrefsState } from '#types'

@Store
export class PrefsStore extends Pinia implements PrefsState {
  public graphMode: GraphMode = GraphMode.WebGL
  public newFeature: string = ''
  public debugLogPrefs: any = {}
  public timezonePrefs: string = 'UTC'
  public timezoneSetting: string = 'UTC'
  public timezoneNotAllowed: boolean = false
  public adminDebugMode: boolean = false
  public expandedViewsPrefs: any = {}
  public resetAllowed = false

  public get getTimezoneSetting() {
    return this.timezoneNotAllowed ? 'UTC' : this.timezoneSetting
  }

  public init() {
    this.newFeature = localStorage.OuraNewFeature || ''

    this.timezonePrefs = localStorage.getItem('OuraTimezonePrefs') ?? 'UTC'

    this.timezoneSetting =
      this.$router.currentRoute.value.query.utc === 'false' || this.timezonePrefs === 'USER' ? 'USER' : 'UTC'

    this.debugLogPrefs = JSON.parse(localStorage.OuraDebugLogPrefs || localStorage.OuraDebugSettings || '{}')

    this.expandedViewsPrefs = JSON.parse(localStorage.OuraExpandedViews || '{}')

    this.adminDebugMode = JSON.parse(localStorage.OuraAdminDebugMode || 'false')

    this.graphMode = localStorage.getItem('OuraGraphMode') == GraphMode.SVG.toString() ? GraphMode.SVG : GraphMode.WebGL
  }

  public updateNewFeature(feature: string) {
    localStorage.OuraNewFeature = JSON.stringify(feature)

    this.newFeature = feature
  }

  public changeDebugLogPrefs(data: { caseSensitive: boolean; count: number }) {
    localStorage.OuraDebugLogPrefs = JSON.stringify(data)

    this.debugLogPrefs = data
  }

  public changeExpandedViewsPrefs(data: any) {
    const expands = { ...this.expandedViewsPrefs, debug: data }

    localStorage.OuraExpandedViews = JSON.stringify(expands)

    this.expandedViewsPrefs = expands
  }

  public toggleAdminDebugMode(enabled: boolean) {
    localStorage.OuraAdminDebugMode = JSON.stringify(enabled)

    this.adminDebugMode = enabled
  }

  public async checkTimezoneRights() {
    const appStore = new AppStore()
    const userStore = new UserStore()

    this.timezoneNotAllowed =
      this.timezoneSetting !== 'UTC' &&
      !userStore.user?.ownerAccess &&
      !userStore.user?.supportAccess &&
      !appStore.getRights.includes('allowPersonalDataAccess')

    try {
      const currentRoute = this.$router.currentRoute.value
      const cloudEnv = appStore.cloudEnv || import.meta.env.VITE_SYS_ENV
      const utcSetting = this.timezoneSetting === 'UTC' ? 'true' : 'false'

      await this.$router.replace({
        hash: currentRoute.hash,
        query: {
          ...currentRoute.query,
          env: cloudEnv,
          utc: utcSetting,
        },
      })
    } catch (e) {
      Sentry.captureException(e)
      console.error('An error occurred while replacing the route:', e)
    }
  }

  public changeTimezoneSetting(data: any) {
    this.timezoneSetting = data.timezone

    if (data.permanent) {
      this.timezonePrefs = data.timezone

      localStorage.setItem('OuraTimezonePrefs', data.timezone)
    }

    this.checkTimezoneRights()
  }

  public changeGraphMode(data: { mode: GraphMode; permanent: boolean }) {
    this.graphMode = data.mode

    if (data.permanent) {
      this.graphMode = data.mode

      localStorage.setItem('OuraGraphMode', data.mode)
    }
  }
}
