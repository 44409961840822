<template>
  <!-- eslint-disable vue/v-on-handler-style -->
  <DiagnosticAlert
    v-if="diagnostic"
    v-model:extra-info="active"
    :diagnostic-id="diagnostic.diagnosticId"
    :index="diagnostic.diagnosticId"
    :alert="diagnostic"
    :sidecar="sidecar"
    :pinned="isPinned(diagnostic.diagnosticId)"
    :show-hidden-diagnostics="showHiddenDiagnostics"
    @toggle-pin="togglePin"
  />
  <v-expand-transition>
    <div v-show="active === diagnostic.diagnosticId && diagnostic.extraInfo.items.length">
      <v-data-table
        :headers="diagnostic?.extraInfo?.headers"
        :items="diagnostic?.extraInfo?.items"
        :items-per-page="diagnosticsDetailsPerPage"
        disable-sort
        class="elevation-1"
      >
        <template #item="{ item }">
          <tr>
            <td v-for="th in diagnostic?.extraInfo?.headers" :key="th.key">
              <div v-if="th.key === 'message'">
                <!-- eslint-disable-next-line vue/no-v-html -->
                <span v-html="formatMessage(item[th.key])" />
                <div v-if="item.details" small class="mb-1 details-button" @click="toggleDetails(item.name)">
                  Details
                </div>
              </div>
              <div v-else>
                {{ item[th.key] }}
              </div>
            </td>
          </tr>
          <tr v-if="item.details && item.name === detailsExpand">
            <td colspan="3">
              <p class="mt-3">
                <strong>Message:</strong>
                {{ item.details.title }}
              </p>
              <v-data-table
                :headers="item.details.headers"
                :items="item.details.items"
                :items-per-page="diagnosticsSubDetailsPerPage"
              >
                <!-- eslint-disable-next-line vue/no-template-shadow -->
                <template #[`item.date`]="{ item }">
                  <span>{{ formatDateTime((item as any).date, 'Do MMM YYYY') }}</span>
                </template>
              </v-data-table>
            </td>
          </tr>
        </template>
        <!--TODO: change back to hide-default-footer if Vuetify3 update this-->
        <template v-if="sidecar || diagnostic?.extraInfo?.items?.length < diagnosticsDetailsPerPage" #bottom />
      </v-data-table>
    </div>
  </v-expand-transition>
</template>

<script lang="ts">
  import { Component, Prop, mixins, toNative } from 'vue-facing-decorator'

  import { DateTime } from '#mixins/dateTime'

  import { maxPinnedDiagnosticsCount } from '#views/users/constants'

  import { DiagnosticsStoreV3 } from '#stores'

  import { Diagnostic, Nullable } from '#types'

  @Component
  class SingleDiagnostic extends mixins(DateTime) {
    @Prop() public diagnostic!: Diagnostic
    @Prop() public sidecar!: boolean
    @Prop() public showHiddenDiagnostics!: boolean

    public active: Nullable<string> = null
    public diagnosticsStore = new DiagnosticsStoreV3()

    /**
     * How many diagnostics details to show per page
     *
     * Each diagnostics items can have details. For example feature diagnostics has list of reasons why it failed
     * (too much movement, in charger, not in finger etc).
     */
    public diagnosticsDetailsPerPage = 7

    /**
     * How many diagnostics sub details to show per page
     *
     * Each diagnostics details can have also sub details, for example resilience diagnostics has details
     * "historical data" which has sub details - list of all the dates as when analytics passed or failed.
     */
    public diagnosticsSubDetailsPerPage = 5

    public detailsExpand = ''

    public formatMessage(message: string) {
      return message.replace(/\n/g, '<br>')
    }

    public toggleDetails(name: string) {
      this.detailsExpand = name === this.detailsExpand ? '' : name
    }

    public isPinned(diagnosticId: string): boolean {
      return this.diagnosticsStore.isPinned(diagnosticId)
    }

    public togglePin(diagnosticId: string) {
      this.diagnosticsStore.togglePin(diagnosticId, maxPinnedDiagnosticsCount)
    }
  }

  export default toNative(SingleDiagnostic)
</script>

<style lang="scss" scoped>
  :deep(.details-button) {
    color: #2196f3;
    font-size: 0.9rem;
    cursor: pointer;
  }
</style>
