import { AxiosError } from 'axios'

import { startCase } from 'lodash'

import { Store } from 'pinia-class-component'

import { SAMLAuthProvider, User, getAuth, signInWithPopup, signInWithRedirect, signOut } from 'firebase/auth'
import { Unsubscribe, doc, getFirestore, onSnapshot, serverTimestamp, setDoc } from 'firebase/firestore'

import * as Sentry from '@sentry/vue'

import { BaseStore } from '#stores/base'
import { PrefsStore } from '#stores/prefs'

import { AppState } from '#types'

let unsubscribeMaintenance: Unsubscribe | undefined = undefined

@Store
export class AppStore extends BaseStore implements AppState {
  public auth: any
  public claims: any
  public groups: string[] = []
  public rights: string[] = []
  public consent: any
  public accounts: any[] = []
  public overrides: string[] = []
  public sensitiveDataVisible: boolean = false
  public sessionExpirationTime: any

  public cloudEnv = ''
  public resetAllowed = false

  public currentRoute = ''
  public previousRoute = ''

  public get getRights() {
    let rights = this.rights

    rights = rights.filter((right) => !this.overrides.includes(right))

    return rights
  }

  public async init() {
    if (sessionStorage.OuraOverrides) {
      this.overrides = sessionStorage.OuraOverrides.split(' ')
    }

    sessionStorage.OuraSensitiveDataVisible = 'false'
  }

  public setOverrides(overrides: any) {
    this.overrides = overrides
    console.log('Overrides changed', overrides)
    sessionStorage.OuraOverrides = overrides.join(' ')
    window.location.reload()
  }

  public setClaims(claims: any) {
    this.claims = claims

    if (claims?.firebase?.sign_in_attributes?.groups) {
      const groups = Array.isArray(claims?.firebase?.sign_in_attributes?.groups)
        ? claims?.firebase?.sign_in_attributes?.groups
        : [claims?.firebase?.sign_in_attributes?.groups]

      this.groups = groups.map((g: string) => 'group' + startCase(g).replace(/ /g, ''))
    }
  }

  public setAuth(auth: any) {
    this.auth = typeof auth === 'object' ? auth : null

    if (import.meta.env.VITE_LOCAL_RIGHTS && window.location.host.slice(0, 9) === 'localhost') {
      this.rights = import.meta.env.VITE_LOCAL_RIGHTS.split(',')
    } else {
      this.rights = auth?.rights || []
    }
  }

  public setCloudEnv(env: string) {
    this.cloudEnv = env || sessionStorage.OuraCloudEnv

    sessionStorage.OuraCloudEnv = env || sessionStorage.OuraCloudEnv
  }

  public async login(usePopup?: any) {
    this.auth = null
    this.claims = {}
    const env = import.meta.env.VITE_APP_ENV
    const provider = new SAMLAuthProvider('saml.oura')

    if (usePopup || env !== 'release') {
      try {
        await signInWithPopup(getAuth(), provider)

        window.location.reload()
      } catch (_error) {
        console.info('Popup closed or blocked')
      }
    } else {
      await signInWithRedirect(getAuth(), provider)
    }
  }

  public logout() {
    if (unsubscribeMaintenance) {
      // Unsubscribe from maintenance document listener
      unsubscribeMaintenance()
    }
    signOut(getAuth())

    this.auth = null
    this.claims = {}
  }

  public toggleRights(right: string) {
    if (this.overrides.includes(right)) {
      this.setOverrides(this.overrides.filter((override) => override !== right))
    } else if (this.rights.includes(right)) {
      this.setOverrides([...this.overrides, right])
    }
  }

  public async getAccounts() {
    const response = await this.makeRequest(
      {
        method: 'get',
        url: '/api/v1/me/access-rights',
      },
      'getAccounts',
    ).catch((_axiosError: AxiosError) => {
      //ignore for now
      return null
    })
    this.accounts = response?.data?.accessRights || []
    this.consent = response?.data?.consentContent || null
  }

  public async addAccount(email: string) {
    const response = await this.$axios.post('/api/v1/me/access-rights', {
      email,
      consentContentUid: this.consent?.consentContentUid,
    })

    return response
  }

  public async setUserAndGetAuth(user: User | null) {
    Sentry.setUser({ email: (user && user.email) || '' })
    // Cloud function to set custom claims for the user

    if (user) {
      await user.getIdTokenResult(true).then(async (result) => {
        console.log('User:', JSON.parse(JSON.stringify(user)))
        console.log('Claims:', JSON.parse(JSON.stringify(result?.claims)))
        console.log('Last login at:', new Date(+JSON.parse(JSON.stringify(user)).lastLoginAt))
        if (result?.claims?.auth_time) {
          // Set session duration
          const authTime: number = +result?.claims?.auth_time
          await this.setSessionExpirationTime({ authTime: authTime })
        }
        this.setClaims(result?.claims)
      })
    }

    const response = await this.makeRequest({ method: 'get', url: '/api/v1/me' }, 'setUserAndGetAuth').catch(
      (_axiosError: AxiosError) => {
        //ignore for now
        return null
      },
    )

    if (window.name === 'darwin-login') {
      window.close()
    } else {
      this.setAuth(response?.data)

      const prefsStore = new PrefsStore()
      prefsStore.checkTimezoneRights()
    }
  }

  public async setSensitiveDataVisible(data: { value: boolean; uuid?: string }) {
    sessionStorage.OuraSensitiveDataVisible = data.value.toString()

    if (data.value) {
      await this.makeRequest(
        { method: 'post', url: `/api/v1/users/${data.uuid}/log-sensitive-data-view` },
        'setSensitiveDataVisible',
      )
        .then(() => {
          this.sensitiveDataVisible = data.value
        })
        .catch((_axiosError: AxiosError) => {
          //ignore for now
          return null
        })
    } else {
      this.sensitiveDataVisible = data.value
    }
  }

  public async setSessionExpirationTime(data: { authTime: number }) {
    this.sessionExpirationTime = null
    // Set session duration
    let authTime: number = data.authTime
    authTime = authTime * 1000
    // Expire session in 12 hours
    const sessionDuration = 1000 * 60 * 60 * 12
    const millisecondsUntilExpiration = sessionDuration - (Date.now() - authTime)
    if (millisecondsUntilExpiration <= 0) {
      this.sessionExpirationTime = null
      this.logout()
    } else {
      this.sessionExpirationTime = new Date(authTime + sessionDuration)
      setTimeout(() => this.logout(), millisecondsUntilExpiration)
    }
  }

  public async updateFirebaseUser(user: any) {
    const docRef = doc(getFirestore(), 'users', `${getAuth().currentUser?.uid}`)
    await setDoc(
      docRef,
      {
        email: user.email,
        groups: this.groups,
        rights: this.rights,
        lastActive: serverTimestamp(),
      },
      { merge: true },
    )
  }

  public updateMaintenance(): boolean {
    let maintenanceValue: boolean = false
    unsubscribeMaintenance = onSnapshot(doc(getFirestore(), 'config/maintenance'), (maintenance: any) => {
      maintenanceValue = maintenance.data()?.value === 'true'
    })
    return maintenanceValue
  }
}
