<template>
  <v-container v-if="!userFound" class="viewport px-12 mt-7">
    <v-card>
      <v-card-title primary-title class="headline mb-4">User not found</v-card-title>
      <v-card-text>
        <p>
          No user found with this UUID:
          <strong>{{ uuid }}</strong>
        </p>
        <p>Please check UUID and try again.</p>
      </v-card-text>
      <v-card-actions class="px-4 pt-5">
        <v-spacer />
        <v-btn variant="text" @click="$router.back()">Back</v-btn>
        <v-btn variant="text" :to="{ path: '/' }">Home</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>

  <v-container v-else-if="zdTicketNotFound" class="viewport px-12 mt-7">
    <UserSearchConsent v-model:zd-ticket-status="zdTicketNotFound" v-model:agent-reason="agentReason" :uuid="uuid" />
  </v-container>
  <v-container v-else-if="user?.uuid === routeUuid && !zdTicketNotFound" class="user container">
    <div v-if="!!alerts.length" class="mt-n4 mx-n4 mb-16" style="position: sticky; top: 64px; z-index: 5">
      <v-menu close-on-click bottom left offset-y content-class="ml-1" :disabled="!!alerts[0].action">
        <template #activator="{ props }">
          <v-alert
            v-model="showAlert"
            tile
            class="px-5 v-sheet--tile"
            :type="alerts[0].type"
            :color="alerts[0].color"
            v-bind="props"
          >
            <div class="d-flex flex-row">
              {{ alerts[0].text }}
              <v-spacer />
              <span v-if="alerts.length > 1 && !alerts[0].action" class="px-2">
                (click to see {{ alerts.length - 1 }} more)
              </span>
              <v-icon v-if="alerts.length > 1 && !alerts[0].action">mdi-arrow-down-bold</v-icon>
              <v-btn
                v-if="alerts[0].action === 'cancel' && (access || rights.includes('allowDataDeletionAccess'))"
                class="text-none"
                size="small"
                variant="flat"
                color="white"
                @click="cancelDelete = true"
              >
                UNDO
              </v-btn>
            </div>
          </v-alert>
        </template>

        <v-list style="padding: 0 !important">
          <v-alert
            v-for="(alert, index) in alerts.slice(1)"
            :key="index"
            :type="alert.type"
            :color="alert.color"
            tile
            class="px-5 v-sheet--tile"
            style="position: static !important; margin: 0 !important"
          >
            <div class="px-3">
              {{ alert.text }}
            </div>
          </v-alert>
        </v-list>
      </v-menu>
    </div>

    <v-container fill-height class="viewport mt-n4" style="transition: margin 300ms">
      <v-row>
        <v-col>
          <v-tabs v-model="routeParams.tab" show-arrows center-active>
            <v-tab value="dashboard" @click="tabChange('dashboard')">Dashboard</v-tab>
            <v-tab value="batteryinfo" @click="tabChange('batteryinfo')">Battery</v-tab>
            <v-tab value="accountinfo" @click="tabChange('accountinfo')">Account</v-tab>
            <v-tab value="devicesinfo" @click="tabChange('devicesinfo')">Devices</v-tab>
            <v-tab value="health" @click="tabChange('health')">Health</v-tab>
            <v-tab value="troubleshooter" @click="tabChange('troubleshooter')">Troubleshooter</v-tab>

            <v-spacer />

            <v-tab
              v-if="access || rights.includes('allowDataDownloadAccess')"
              value="databrowser"
              @click="
                tabChange('databrowser'),
                  logAnalyticsEvent($analytics, 'databrowser_open', {
                    category: 'User:Data Browser',
                    action: 'clicked DataBrowser ',
                    label: 'Click tab Data browser',
                  })
              "
            >
              Data browser
            </v-tab>
            <v-tab
              v-if="access || rights.includes('allowDataDownloadAccess')"
              value="debug"
              @click="
                tabChange('debug'),
                  logAnalyticsEvent($analytics, 'debuglog_open', {
                    category: 'User:DebugLog',
                    action: 'click DebugLog',
                    label: 'Click tab DebugLog',
                  })
              "
            >
              Debug Logs
            </v-tab>
            <v-tab
              v-if="access || rights.includes('allowDataDownloadAccess')"
              value="datafiles"
              @click="
                tabChange('datafiles'),
                  logAnalyticsEvent($analytics, 'data_download_open', {
                    category: `User:Data Download`,
                    action: 'Click Data Download',
                    label: `Click Data Download`,
                  })
              "
            >
              Data Files
            </v-tab>
          </v-tabs>
        </v-col>
      </v-row>
    </v-container>

    <v-window v-model="routeParams.tab">
      <v-window-item value="dashboard">
        <DashboardV2 v-model:ring="ring" :user="user" />
      </v-window-item>

      <v-window-item value="batteryinfo">
        <BatteryInfo v-model:ring="ring" :user="user" />
      </v-window-item>

      <v-window-item value="accountinfo">
        <AccountInfo v-model:ring="ring" :user="user" />
      </v-window-item>

      <v-window-item value="devicesinfo">
        <DevicesInfo :ring="ring" :user="user" :ring-details="ringDetails" />
      </v-window-item>

      <v-window-item value="health">
        <Health v-model:ring="ring" :user="user" />
      </v-window-item>

      <v-window-item value="troubleshooter">
        <Troubleshooter
          v-model:ring="ring"
          v-model:diff-start="diffStart"
          v-model:diff-end="diffEnd"
          v-model:show-diff="showDiff"
          :user="user"
        />
      </v-window-item>

      <v-window-item v-if="access || rights.includes('allowDataDownloadAccess')" value="databrowser">
        <UserDataBrowser :uuid="uuid" :range="true" :ring-serial="ring ? ring.serialNumber : ''" />
      </v-window-item>

      <v-window-item v-if="access || rights.includes('allowDataDownloadAccess')" value="debug">
        <Debug :uuid="uuid" />
      </v-window-item>

      <v-window-item v-if="access || rights.includes('allowDataDownloadAccess')" value="datafiles">
        <Files :uuid="uuid" :env="user.env && user.env.environment" :rings="user.rings" />
      </v-window-item>
    </v-window>

    <v-dialog v-model="showDiff" max-width="1200">
      <v-card :loading="dataWait">
        <v-card-title class="headline grey lighten-2" primary-title>Client Config Changes</v-card-title>

        <v-card-text>
          <v-container>
            <Diff :uuid="uuid" :ring="ring" />
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-if="cancelDelete" v-model="cancelDelete" width="500">
      <v-card>
        <v-card-title>Cancel member deletion?</v-card-title>

        <v-card-text>
          This will cancel member deletion completely, no data will be deleted from Oura systems.
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn @click="cancelDelete = false">Cancel</v-btn>
          <v-btn color="red" @click="unmarkForDeletion()">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script lang="ts">
  import { deleteStatus } from './constants'

  import { Component, Prop, Watch, mixins, toNative } from 'vue-facing-decorator'

  import { logEvent } from 'firebase/analytics'

  import { RouteParams } from '@jouzen/outo-apps-toolkit'

  import { DateTime } from '#mixins/dateTime'
  import { ZendeskTicketChecker } from '#mixins/zendeskTicketChecker'

  import { unreleasedRingNamesToHide } from '#utils/darwinFeatureFlags'
  import { resetAllPiniaStores } from '#utils/store/reset'

  import { AppStore, PrefsStore, RingsStore, TimelineStore, UserStore } from '#stores'

  import { Member, Ring, RingDetails } from '#types'

  @Component
  class UserView extends mixins(DateTime, RouteParams, ZendeskTicketChecker) {
    @Prop() public uuid!: string

    public userStore = new UserStore()
    public prefsStore = new PrefsStore()
    public timelineStore = new TimelineStore()
    public appStore = new AppStore()
    public ringsStore = new RingsStore()

    public routeParams: any = {
      tab: '',
    }

    public alerts: any[] = []
    public diffEnd: string = ''
    public diffStart: string = ''
    public showDiff = false
    public showAlert = false
    public cancelDelete = false
    public zdTicketNotFound = false
    public userFound = true
    protected text: string = ''
    public agentReason: string = ''

    public logAnalyticsEvent = logEvent

    public get routeUuid(): string | string[] {
      return this.$route.params?.uuid ?? ''
    }

    public get user() {
      return this.userStore.user
    }

    public get ringDetails(): RingDetails | null {
      return this.ringsStore.ring
    }

    public get ring(): Ring | null {
      return this.ringsStore.activeRing || null
    }

    public set ring(newRing: any) {
      this.ringsStore.setActiveRing(newRing)
    }

    public get rights() {
      return this.appStore.getRights
    }

    public get access() {
      return this.userStore.user?.ownerAccess || this.userStore.user?.sharedAccess || this.userStore.user?.supportAccess
    }

    public get dataWait() {
      return this.userStore.dataWait
    }

    public get timelineEvents() {
      return this.timelineStore.events
    }

    public get previousAgentReason(): string {
      return localStorage.getItem('previousAgentReason') || ''
    }

    public updateRing(newRing: any) {
      this.ringsStore.setActiveRing(newRing)
    }

    @Watch('uuid')
    protected async onUuidChanged(_val: string, _prevVal: string) {
      // When member ID changes, we want to flush all Pinia stores to ensure there is no previous member's data left
      resetAllPiniaStores()
      // Check if user has open zendesk tickets or if darwin user has sufficient permissions before showing the user
      this.setAgentReason(this.previousAgentReason)
      this.zdTicketNotFound = false

      const user = await this.userStore.getUserAccessLevels(this.uuid)
      const sensitiveDataVisible = this.userAccessLevels?.ownerAccess || this.userAccessLevels?.supportAccess

      if (!user) {
        this.userFound = false
        return
      }

      // Check if the darwin user has access or if user has open zendesk tickets
      const response = await this.getUsersTicketStatus(this.uuid)
      if ('zdTicketNotFound' in response) {
        this.zdTicketNotFound = response['zdTicketNotFound'] ? true : false
      } else if ('reason' in response) {
        this.setAgentReason(response['reason'] ? response['reason'] : '', false)
      }

      if (this.zdTicketNotFound) {
        return
      } else if (this.agentReason) {
        // Get user data
        this.appStore.setSensitiveDataVisible({ value: sensitiveDataVisible, uuid: this.uuid }).then(() => {
          if (this.uuid) {
            this.userStore.getUser({
              user: this.uuid,
              agentReason: this.agentReason,
              hideRingNames: unreleasedRingNamesToHide(),
            })
          }
        })
      }
    }

    @Watch('ring', { immediate: true })
    protected onRingChanged() {
      if (this.ring && this.ring.serialNumber) {
        this.ringsStore.getRingDetails(this.ring.serialNumber)
      }
    }

    @Watch('user')
    protected onUserChanged(_val: any, _prevVal: any) {
      // Check that this.ring actually belongs to this.user. If not, set ring to null
      if (
        this.ring &&
        this.user &&
        this.user.rings &&
        this.user.rings[0] &&
        !this.user.rings.find((r) => r.serialNumber == this.ring?.serialNumber)
      ) {
        this.ringsStore.setActiveRing(null)
      }

      if (!this.ring && this.user?.rings[0]) {
        this.ringsStore.setActiveRing(this.user.rings[0])
      }

      this.checkAlerts()
    }

    @Watch('showAlert')
    protected onShowAlertChanged(val: any, _prevVal: any) {
      if (!val && this.alerts.length) {
        this.alerts.shift()

        setTimeout(() => {
          this.showAlert = !!this.alerts[0]
        }, 100)
      }
    }

    public mounted() {
      this.setAgentReason(this.previousAgentReason)
      this.onUuidChanged(this.uuid, this.uuid)

      this.timelineStore.init()
    }

    public tabChange(path: string) {
      this.routeParams.tab = path
      this.$router.push({ path: this.$router.currentRoute.value.fullPath, hash: '#' + path })
    }

    private setAgentReason(reason: string, setPrevious: boolean = false) {
      if (reason) {
        this.agentReason = reason
      }

      localStorage.setItem('agentReason', reason)
      if (setPrevious) {
        localStorage.setItem('previousAgentReason', reason)
      }
    }

    public unmarkForDeletion() {
      this.cancelDelete = false

      this.userStore.removeDeleteTicket(this.user?.uuid || '')
    }

    private checkProcessJzlogAlertFromMember() {
      // Re-assignment of variable should be skipped after this.user is Member (after newMemberObject feature is available for everyone)
      const member: Member | null = this.user
      if (member && member.flags && member.flags.jzlogProcessingDisabled) {
        this.alerts.push({
          text: 'JZLog processing is disabled for this member',
          type: 'error',
          color: 'red',
        })
      }
    }

    private checkAlerts() {
      this.alerts = []

      if (
        this.user?.deleteTicket?.status &&
        [deleteStatus.INITIAL, deleteStatus.PENDING, deleteStatus.REQUESTED].includes(this.user?.deleteTicket?.status)
      ) {
        const createAtFormat = this.formatDateTime(this.user.deleteTicket.created_at, 'HH:mm - D MMM YYYY')
        const updateAtFormat = this.formatDateTime(this.user.deleteTicket.created_at, 'HH:mm - D MMM YYYY')
        const createdAtAfter2Weeks = this.formatDateTime(
          this.$dayjs(this.user.deleteTicket.created_at).add(14, 'day'),
          'HH:mm - D MMM YYYY',
        )
        // Possible values for initiation_type are at least: darwin, app and command-line
        let initiationType = this.user.deleteTicket.initiation_type || ''
        initiationType = initiationType === 'darwin' ? 'Darwin' : initiationType
        initiationType = initiationType?.replace('-', ' ')

        let alertText = ''

        if (this.user.deleteTicket.status === deleteStatus.PENDING) {
          alertText = `Member has confirmed deletion request on ${createAtFormat}, account will be automatically deleted in two weeks from confirmation on ${createdAtAfter2Weeks}`
        } else if (this.user.deleteTicket.status === deleteStatus.REQUESTED) {
          alertText = `Member is waiting for deletion, ${initiationType} requested deletion on ${createAtFormat}`
        } else {
          alertText = `Member has NOT confirmed deletion, request was sent on ${updateAtFormat}`
        }

        this.alerts.push({
          text: alertText,
          type: this.user.deleteTicket.status === deleteStatus.INITIAL ? 'error' : 'info',
          color: this.user.deleteTicket.status === deleteStatus.INITIAL ? 'error' : 'info',
          action: 'cancel',
        })
      }

      this.checkProcessJzlogAlertFromMember()

      this.showAlert = !!this.alerts[0]
    }
  }

  export default toNative(UserView)
</script>

<style lang="scss" scoped>
  :deep(.v-alert) {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
  }

  :deep(.v-input) {
    .v-input__prepend-inner {
      opacity: 0.4;
    }

    &.active .v-input__prepend-inner {
      opacity: 1;

      & button {
        color: #2196f3;
      }
    }
  }

  .user :deep(.v-item-group),
  .user :deep(.v-tabs-items) {
    background: transparent !important;
  }

  .user :deep(.v-expansion-panel) {
    background: transparent !important;

    &::before {
      display: none;
    }

    .v-expansion-panel-header {
      padding: 0;
    }

    .v-expansion-panel-header__icon i {
      color: #e91e63 !important;
    }

    .v-expansion-panel-content__wrap {
      padding: 0;
    }
  }

  .infos :deep(.v-text-field__slot) {
    input {
      text-align: right;
    }
  }
</style>
