<template>
  <div class="pa-0">
    <v-card :loading="!user || !diagnosticsReady">
      <v-card-title :style="{ cursor: sidecar ? 'pointer' : 'default' }">
        <v-col>
          <v-row @click="link ? moreInfo() : null">
            <p class="text-overline">Diagnostics</p>
          </v-row>
          <v-row>
            <v-text-field
              v-if="ring && ring.serialNumber && sidecar"
              label="Ring Serial"
              :model-value="ring.serialNumber || 'Ring not registered'"
              readonly
            >
              <template #prepend>
                <v-tooltip location="top">
                  <template #activator="{ props }">
                    <v-icon
                      v-bind="props"
                      color="grey"
                      icon="mdi-content-copy"
                      @click.stop="useClipboard.copy(ring.serialNumber)"
                    />
                  </template>
                  {{ useClipboard.copied ? 'Copied' : 'Copy to clipboard' }}
                </v-tooltip>
              </template>
            </v-text-field>
            <v-tooltip v-if="link && sidecar" location="bottom">
              <template #activator="{ props }">
                <v-btn
                  v-if="link"
                  icon="mdi-share"
                  variant="plain"
                  color="blue"
                  class="ml-2 mt-2"
                  v-bind="props"
                  @click="moreInfo()"
                />
              </template>
              <span>Go to Darwin</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-card-title>

      <div v-if="user">
        <div v-for="(alert, index) in alerts" :key="index">
          <AlertV2 v-model:extra-info="active" :index="index" :user="user" :alert="alert" :sidecar="sidecar" />
          <v-expand-transition>
            <div v-show="active === index && alert.tooltip?.items?.length">
              <v-data-table
                :headers="alert.tooltip?.headers"
                :items="alert.tooltip?.items"
                :items-per-page="diagnosticsItemPerPage"
                disable-sort
                class="elevation-1"
              >
                <template #item="{ item }">
                  <tr>
                    <td v-for="th in alert?.tooltip?.headers" :key="th.key" :style="{ textAlign: th.align ?? 'left' }">
                      <div v-if="th.key === 'message'">
                        <!-- eslint-disable-next-line vue/no-v-html -->
                        <span v-html="formatMessage(item[th.key])" />
                        <div v-if="item.details" small class="mb-1 details-button" @click="toggleDetails(item.name)">
                          Details
                        </div>
                      </div>
                      <div v-else>
                        {{ item[th.key] }}
                      </div>
                    </td>
                  </tr>
                  <tr v-if="item.details && item.name === detailsExpand">
                    <td colspan="3">
                      <p class="mt-3">
                        <strong>Message:</strong>
                        {{ item.details.title }}
                      </p>
                      <v-data-table
                        :headers="item.details.headers"
                        :items="item.details.items"
                        :items-per-page="detailsItemPerPage"
                      >
                        <!-- eslint-disable-next-line vue/no-template-shadow -->
                        <template #item.date="{ item }">
                          <span>{{ formatDateTime((item as any).date, 'Do MMM YYYY') }}</span>
                        </template>
                      </v-data-table>
                    </td>
                  </tr>
                </template>
                <!--TODO: change back to hide-default-footer if Vuetify3 update this-->
                <template v-if="sidecar || (alert?.tooltip?.items?.length || 0) < diagnosticsItemPerPage" #bottom />
              </v-data-table>
            </div>
          </v-expand-transition>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script lang="ts">
  import { useClipboard } from '@vueuse/core'

  import { Component, Prop, Watch, mixins, toNative } from 'vue-facing-decorator'

  import { logEvent } from 'firebase/analytics'
  import { getValue } from 'firebase/remote-config'

  import { Debounce } from '@jouzen/outo-apps-toolkit'

  import { DateTime } from '#mixins/dateTime'

  import { alertColors } from '#views/users/constants'

  import { BatteryDiagnosticsResponse, ConnectedAccount, WarrantyData } from '#views/rings/types'
  import { DiagnosticAlert, DiagnosticsConfig, SubscriptionInfo } from '#views/users/types'

  import {
    automaticActivityDiagnosticFeature,
    boughtFromPartnerDiagnosticFeature,
    cardioVascularAgeDiagnosticsFeature,
    connectivityDiagnosticsFeature,
    deviceDiagnosticFeature,
    multipleRingsInUseDiagnosticFeature,
    oldRingInUseDiagnosticFeature,
    partnershipIntegrationDiagnostic,
    previouslyOwnedRingDiagnosticsFeature,
    resilienceDiagnosticsFeature,
    ringReplacementsDiagnosticsFeature,
    sidecarMacroAssistFeature,
    signupFlowDiagnosticFeature,
    stressDiagnosticsFeature,
    stressErrorDiagnosticFeature,
    versionDiagnosticFeature,
    vo2MaxDiagnosticsFeature,
  } from '#utils/darwinFeatureFlags'
  import { RingDiagnostics } from '#utils/ring/diagnostics'
  import { UserDiagnostics } from '#utils/user/diagnostics'
  import { checkDiagnosticsSidecar } from '#utils/user/sidecarDiagnostics'

  import {
    AppStore,
    DiagnosticsStore,
    RingsStore,
    SamplesStore,
    SubscriptionStore,
    TimelineStore,
    UserStore,
  } from '#stores'
  import { MacroArrayInfo, UserRingDetailsResponse } from '#stores/user.types'

  import { Chronotype, Diagnostics } from '#types'

  export enum DiagnosticsSubComponents {
    Subscription = 'subscription',
    Age = 'age',
    Samples = 'samples',
    Warranty = 'warranty',
    Replaced = 'replaced',
    BatteryHealthy = 'battery_healthy',
    SelfTestHealth = 'self_test_health',
    Chronotype = 'chronotype',
    RingPreOwned = 'pre_owned',
    Retail = 'retail',
    IsSupportedDevice = 'is_supported_device',
    CycleInsights = 'cycle_insights',
    Stress = 'stress',
    StressError = 'stress_error',
    SignupFlow = 'signup_flow',
    MultipleRingsInUse = 'multiple_rings_in_use',
    AutomaticActivity = 'automatic_activity',
    Resilience = 'resilience',
    PartnershipIntegration = 'partnership_integration',
    Connectivity = 'connectivity',
    RingReplacements = 'ring_replacements',
    OldRingInUse = 'old_ring_in_use',
    PreviouslyOwnedRing = 'previously_owned_ring',
    CardioVascularAge = 'cardio_vascular_age',
    Vo2Max = 'vo2_max',
    Device = 'device',
    Version = 'version',
    BoughtFromPartner = 'bought_from_partner',
  }

  const DEFAULT_DIAGNOSTICS_ACTIVE: string[] = Object.values(DiagnosticsSubComponents)

  @Component
  class DiagnosticsV2 extends mixins(DateTime) {
    @Prop() public user!: any
    @Prop() public ring!: UserRingDetailsResponse
    @Prop() public link!: string
    @Prop() public sidecar!: boolean
    @Prop({
      default: () => DEFAULT_DIAGNOSTICS_ACTIVE,
    })
    public subComponents!: string[]

    public qaDiagnosticsFieldId = import.meta.env.VITE_ZENDESK_QA_DIAGNOSTICS_FIELD_ID

    public qaDiagnosticsField = ''

    public alerts: {
      id?: string
      type: string
      link: string
      color: string
      message: string
      macro?: { title?: string; id?: string }
      tooltip?: { headers: any; items: any[] }
    }[] = []

    public zdTags: string[] = []
    public zdGroup: string = ''
    // public zdProductIssue = ''
    // public zdRingType = ''
    public requesterLocale = ''
    public notificationShown = false
    public diagnosticsItemPerPage = 7
    public detailsItemPerPage = 5
    public detailsExpand = ''
    public macroArray: MacroArrayInfo[] = []

    public active: null | number = null

    private STATUS = {
      pass: 'pass',
      fail: 'fail',
      na: 'n/a',
    }

    public useClipboard = useClipboard()

    private ringDiagnostics = new RingDiagnostics()
    private userDiagnostics = new UserDiagnostics()

    public appStore = new AppStore()
    public diagnosticsStore = new DiagnosticsStore()
    public timelineStore = new TimelineStore()
    public ringsStore = new RingsStore()
    public samplesStore = new SamplesStore()
    public userStore = new UserStore()
    public subscriptionStore = new SubscriptionStore()

    @Watch('user')
    protected onUserChanged(_val: any[], _prevVal: any[]) {
      this.updateUserData()
      this.runDiagnostics()
    }

    @Watch('ring')
    protected onRingChanged(_val: any[], _prevVal: any[]) {
      this.updateRingData()
      this.runDiagnostics()
    }

    @Watch('summary')
    protected onSummaryChanged(_val: any[], _prevVal: any[]) {
      this.runDiagnostics()
    }

    @Watch('featureFlags')
    protected onFeaturesFlagsChanged(_val: any[], _prevVal: any[]) {
      this.runDiagnostics()
    }

    @Watch('sampleFilters')
    protected onSampleFiltersChanged(_val: any, _prevVal: any) {
      this.runDiagnostics()
    }

    @Watch('diagnosticsReady')
    protected onDiagnosticsReadyChanged(_val: any, _prevVal: any) {
      this.runDiagnostics()
    }

    @Watch('alerts')
    protected onAlerts(_val: any, _prevVal: any) {
      if (this.diagnosticsReady && this.sidecar) {
        this.checkDiagnostics(this.alerts)
      }
    }

    @Watch('batteryDiagnostics')
    protected onBatteryDiagnosticChanged(_val: any, _prevVal: any) {
      this.runDiagnostics()
    }

    public toggleDetails(name: string) {
      this.detailsExpand = name === this.detailsExpand ? '' : name
    }

    private updateRingData() {
      if (this.ring?.serialNumber) {
        this.ringsStore.getConnectedAccounts(this.ring.serialNumber)
        this.diagnosticsStore.getBatteryDiagnostics(this.ring.serialNumber)
        this.samplesStore.getSampleFilters(this.ring.serialNumber)
        this.ringsStore.getWarrantyData({ serial: this.ring.serialNumber, initial: true })
        if (boughtFromPartnerDiagnosticFeature()) {
          this.diagnosticsStore.getBoughtFromPartnerDiagnostic(this.ring.serialNumber)
        }
        if (versionDiagnosticFeature()) {
          this.diagnosticsStore.getVersionDiagnostics(this.ring.serialNumber)
        }
      }
    }

    private updateUserData() {
      if (this.user) {
        this.subscriptionStore.getSubscriptions({
          userId: this.user.uuid,
          initial: true,
        })
        this.timelineStore.getFeatureFlags({
          uuid: this.user.uuid,
        })
        this.diagnosticsStore.getCycleInsights(this.user.uuid)
        this.diagnosticsStore.getAutomaticActivityDiagnostics(this.user.uuid)
        this.userStore.getChronotype(this.user.uuid)
        this.diagnosticsStore.getStressDiagnostics(this.user.uuid)
        this.diagnosticsStore.getResilienceDiagnostics(this.user.uuid)
        if (deviceDiagnosticFeature()) {
          this.diagnosticsStore.getDeviceDiagnostics(this.user.uuid)
        }
        if (signupFlowDiagnosticFeature()) {
          this.diagnosticsStore.getSignupFlowDiagnostics(this.user.uuid)
        }
        if (stressErrorDiagnosticFeature()) {
          this.diagnosticsStore.getStressErrorDiagnostics(this.user.uuid)
        }
        if (multipleRingsInUseDiagnosticFeature()) {
          this.diagnosticsStore.getMultipleRingsInUseDiagnostic(this.user.uuid)
        }
        if (partnershipIntegrationDiagnostic()) {
          this.diagnosticsStore.getPartnershipIntegrationDiagnostic(this.user.uuid)
        }
        if (connectivityDiagnosticsFeature() && this.ring?.serialNumber) {
          this.diagnosticsStore.getConnectivityDiagnostics(this.ring.serialNumber)
        }

        if (ringReplacementsDiagnosticsFeature()) {
          this.diagnosticsStore.getRingReplacementsDiagnostics(this.user.uuid)
        }
        if (oldRingInUseDiagnosticFeature()) {
          this.diagnosticsStore.getOldRingInUseDiagnostic(this.user.uuid)
        }
        if (previouslyOwnedRingDiagnosticsFeature() && this.ring?.serialNumber) {
          this.diagnosticsStore.getPreviouslyOwnedRingDiagnostics(this.user.uuid, this.ring.serialNumber)
        }
        if (cardioVascularAgeDiagnosticsFeature() && this.ring?.serialNumber) {
          this.diagnosticsStore.getCardioVascularAgeDiagnostics(this.user.uuid)
        }
        if (vo2MaxDiagnosticsFeature() && this.ring?.serialNumber) {
          this.diagnosticsStore.getVo2MaxDiagnostics(this.user.uuid)
        }
      }
    }

    public async mounted() {
      if (!this.sidecar) {
        this.updateRingData()
      }
      this.updateUserData()
      this.runDiagnostics()

      if (this.$zafClient) {
        const data = await this.$zafClient.get([
          'macros',
          'ticket.requester.locale',
          'ticket.tags',
          'ticket.assignee.group.name',
          `ticket.customField:custom_field_${import.meta.env.VITE_ZENDESK_PRODUCT_ISSUE_FIELD_ID}`,
          `ticket.customField:custom_field_${import.meta.env.VITE_ZENDESK_RING_TYPE_FIELD_ID}`,
        ])
        this.zdTags = data['ticket.tags']
        this.zdGroup = data['ticket.assignee.group.name']

        if (sidecarMacroAssistFeature() && Array.isArray(data.macros)) {
          this.filterMacros(data.macros)
        }

        // this.zdProductIssue =
        //   data[`ticket.customField:custom_field_${import.meta.env.VITE_ZENDESK_PRODUCT_ISSUE_FIELD_ID}`]
        // this.zdRingType = data[`ticket.customField:custom_field_${import.meta.env.VITE_ZENDESK_RING_TYPE_FIELD_ID}`]
        this.requesterLocale = data['ticket.requester.locale'] ? data['ticket.requester.locale'].slice(0, 2) : 'en'
        this.$zafClient.on('ticket.submit.start', () => {
          this.updateTicket()
        })
      }
    }

    public async updateTicket() {
      const data = await this.$zafClient.get([`ticket.customField:custom_field_${this.qaDiagnosticsFieldId}`])
      this.qaDiagnosticsField = data[`ticket.customField:custom_field_${this.qaDiagnosticsFieldId}`]
      let currentAlerts = ''
      for (const item of this.alerts) {
        currentAlerts += item.id + ' : ' + item.type + '\n'
      }
      if (this.qaDiagnosticsField?.trim().replace(/,/g, '') !== currentAlerts?.trim()) {
        this.$zafClient.set(
          `ticket.customField:custom_field_${this.qaDiagnosticsFieldId}`,
          currentAlerts.replace(/\n/g, ',\n'),
        )
      }
    }

    public filterMacros(macros: any[]) {
      for (const macro of macros) {
        if (macro['description']) {
          this.macroArray.push(macro)
        }
      }
    }
    public get toggleMacroNotification(): boolean {
      return localStorage.getItem('toggleMacroNotification') == 'true'
    }

    public get rights() {
      return this.appStore.getRights || []
    }

    public get battery() {
      return this.timelineStore.battery
    }

    public get summary() {
      return this.timelineStore.summary
    }

    public get featureFlags() {
      return this.timelineStore.features
    }

    public get connectedAccounts(): ConnectedAccount[] {
      return this.ringsStore.connectedAccounts || []
    }

    public get warrantyData(): WarrantyData | null {
      return this.ringsStore.warrantyData
    }

    public get diagnosticsReady() {
      // Improvement idea: only check dataWaits of the requests that are related to diagnostics.
      // Maybe this could be more elegant loop for example?
      const diagnosticsStoreReady = !this.diagnosticsStore.waitingForData()
      const timelineStoreReady = !this.timelineStore.waitingForData()
      const userStoreReady = !this.userStore.waitingForData()
      const samplesStoreReady = !this.samplesStore.waitingForData()
      const subscriptionStoreReady = !this.subscriptionStore.waitingForData()
      const ringsStoreReady = !this.ringsStore.waitingForData()

      return (
        this.timelineStore.diagnosticsReady &&
        timelineStoreReady &&
        userStoreReady &&
        samplesStoreReady &&
        subscriptionStoreReady &&
        ringsStoreReady &&
        diagnosticsStoreReady
      )
    }

    private get subscriptions() {
      return this.subscriptionStore.subscriptions
    }

    private get sampleFilters() {
      return this.samplesStore.filters
    }

    private get versionDiagnostics(): Diagnostics | null {
      return this.diagnosticsStore.versionDiagnostics
    }

    private get chronotype(): Chronotype | null {
      return this.userStore.chronotype
    }

    private get batteryDiagnostics(): BatteryDiagnosticsResponse | null {
      return this.diagnosticsStore.batteryDiagnostics
    }

    private get diagnosticsInValidation() {
      return getValue(this.$remoteConfig, 'diagnosticsInValidation').asString().split(',')
    }

    private subComponentActive(name: string): boolean {
      return this.subComponents.includes(name)
    }

    private get deviceDiagnostics(): Diagnostics | null {
      return this.diagnosticsStore.deviceDiagnostics
    }

    private get cycleInsights() {
      return this.diagnosticsStore.cycleInsights
    }

    private get stressDiagnostics(): Diagnostics | null {
      return this.diagnosticsStore.stressDiagnostics
    }
    private get stressErrorDiagnostics(): Diagnostics | null {
      return this.diagnosticsStore.stressErrorDiagnostics
    }
    private get signupFlowDiagnostics(): Diagnostics | null {
      return this.diagnosticsStore.signupFlowDiagnostics
    }
    private get multipleRingsInUseDiagnostic(): Diagnostics | null {
      return this.diagnosticsStore.multipleRingsInUseDiagnostic
    }
    private get automaticActivityDiagnostic(): Diagnostics | null {
      return this.diagnosticsStore.automaticActivityDiagnostic
    }
    private get resilienceDiagnostics(): Diagnostics | null {
      return this.diagnosticsStore.resilienceDiagnostics
    }

    private get partnershipIntegrationDiagnotics(): Diagnostics | null {
      return this.diagnosticsStore.partnershipIntegrationDiagnotics
    }

    private get connectivityDiagnostics(): Diagnostics | null {
      return this.diagnosticsStore.connectivityDiagnostics
    }

    private get ringReplacementsDiagnostics(): Diagnostics | null {
      return this.diagnosticsStore.ringReplacementsDiagnostics
    }

    private get oldRingInUseDiagnostic(): Diagnostics | null {
      return this.diagnosticsStore.oldRingInUseDiagnostic
    }

    private get previouslyOwnedRingDiagnostics(): Diagnostics | null {
      return this.diagnosticsStore.previouslyOwnedRingDiagnostics
    }

    private get cardioVascularAgeDiagnostics(): Diagnostics | null {
      return this.diagnosticsStore.cardioVascularAgeDiagnostics
    }

    private get vo2MaxDiagnostics(): Diagnostics | null {
      return this.diagnosticsStore.vo2MaxDiagnostics
    }

    private get boughtFromPartnerDiagnostic(): Diagnostics | null {
      return this.diagnosticsStore.boughtFromPartnerDiagnostic
    }

    public formatMessage(message: string | null) {
      return message ? message.replace(/\n/g, '<br>') : ''
    }

    public moreInfo() {
      logEvent(this.$analytics, `${this.sidecar ? 'sidecar' : 'dashboard'}_diagnostics_v2_moreInfo_clicked`, {
        category: `${this.sidecar ? 'Sidecar' : 'Dashboard'}'`,
        action: `${this.sidecar ? 'Sidecar' : 'Dashboard'} diagnosticsV2 moreinfo clicked'`,
        label: `${this.sidecar ? 'Sidecar' : 'Dashboard'} diagnosticsV2 moreinfo clicked'`,
        page_title: `${this.sidecar ? 'Sidecar' : 'Dashboard'}`,
        page_location: window.location.toString().split('?')[0],
      })
      window.open(this.link.toLowerCase(), '_blank')
    }

    @Debounce(100)
    private runDiagnostics() {
      this.alerts = []
      if (!this.diagnosticsReady) {
        return
      }

      /**
       * When adding new diagnostics, they should be added here.
       */
      const activeDiagnostics: DiagnosticsConfig[] = [
        { id: DiagnosticsSubComponents.Stress, data: this.stressDiagnostics, enabled: stressDiagnosticsFeature() },
        {
          id: DiagnosticsSubComponents.StressError,
          data: this.stressErrorDiagnostics,
          enabled: stressErrorDiagnosticFeature(),
        },
        {
          id: DiagnosticsSubComponents.SignupFlow,
          data: this.signupFlowDiagnostics,
          enabled: signupFlowDiagnosticFeature(),
        },
        {
          id: DiagnosticsSubComponents.MultipleRingsInUse,
          data: this.multipleRingsInUseDiagnostic,
          enabled: multipleRingsInUseDiagnosticFeature(),
        },
        {
          id: DiagnosticsSubComponents.AutomaticActivity,
          data: this.automaticActivityDiagnostic,
          enabled: automaticActivityDiagnosticFeature(),
        },
        {
          id: DiagnosticsSubComponents.Resilience,
          data: this.resilienceDiagnostics,
          enabled: resilienceDiagnosticsFeature(),
        },
        {
          id: DiagnosticsSubComponents.PartnershipIntegration,
          data: this.partnershipIntegrationDiagnotics,
          enabled: partnershipIntegrationDiagnostic(),
        },
        {
          id: DiagnosticsSubComponents.Connectivity,
          data: this.connectivityDiagnostics,
          enabled: connectivityDiagnosticsFeature(),
        },
        {
          id: DiagnosticsSubComponents.RingReplacements,
          data: this.ringReplacementsDiagnostics,
          enabled: ringReplacementsDiagnosticsFeature(),
        },
        {
          id: DiagnosticsSubComponents.OldRingInUse,
          data: this.oldRingInUseDiagnostic,
          enabled: oldRingInUseDiagnosticFeature(),
        },
        {
          id: DiagnosticsSubComponents.PreviouslyOwnedRing,
          data: this.previouslyOwnedRingDiagnostics,
          enabled: previouslyOwnedRingDiagnosticsFeature(),
        },
        {
          id: DiagnosticsSubComponents.CardioVascularAge,
          data: this.cardioVascularAgeDiagnostics,
          enabled: cardioVascularAgeDiagnosticsFeature(),
        },
        {
          id: DiagnosticsSubComponents.Vo2Max,
          data: this.vo2MaxDiagnostics,
          enabled: vo2MaxDiagnosticsFeature(),
        },
        {
          id: DiagnosticsSubComponents.Version,
          data: this.versionDiagnostics,
          enabled: versionDiagnosticFeature(),
        },
        {
          id: DiagnosticsSubComponents.Device,
          data: this.deviceDiagnostics,
          enabled: deviceDiagnosticFeature(),
        },
        {
          id: DiagnosticsSubComponents.BoughtFromPartner,
          data: this.boughtFromPartnerDiagnostic,
          enabled: boughtFromPartnerDiagnosticFeature(),
        },
      ]

      // TODO: Remove this when vue3 is released and we can change the backend
      for (const item of activeDiagnostics) {
        if (!item?.data?.extraInfo) {
          continue
        }
        item.data.extraInfo.headers.forEach((x) => {
          if (x.text) {
            x.title = x.text
            delete x.text
          }
          if (x.value) {
            x.key = x.value
            delete x.value
          }
        })
      }

      const legacyHardware = this.ring?.hardwareType == 'gen1' || this.ring?.hardwareType == 'gen2'

      if (this.user && !legacyHardware) {
        const { subscriptionDiagnosticState, subscriptionAlert } = this.subscriptionDiagnostic()

        if (
          this.subComponentActive(DiagnosticsSubComponents.Subscription) &&
          this.checkAlertPush(subscriptionAlert, subscriptionDiagnosticState) &&
          !this.sidecar
        ) {
          this.alerts.push(subscriptionAlert)
        }
      }

      if (this.ring) {
        const { ringAge, ringAgeAlert } = this.ringAgeDiagnostic()
        const { samplesState, samplesAlert } = this.ringSamplesDiagnostic()

        // Battery health diagnostics from backend
        const { newBatteryHealth, newBatteryHealthAlert } = this.newBatteryDiagnosticReport()

        const { selfTestHealth, selfTestHealthAlert } = this.selfTestDiagnostic()

        const { preOwnedStatus, preOwnedAlert } = this.ringDiagnostics.preOwnedReport(this.connectedAccounts)
        if (
          preOwnedStatus !== 'info' &&
          this.checkAlertPush(preOwnedStatus, preOwnedAlert) &&
          this.subComponentActive(DiagnosticsSubComponents.RingPreOwned)
        ) {
          // Check AllowRingHistoryAccess right to show alert tooltip or not
          if (this.rights.includes('AllowRingHistoryAccess')) {
            this.alerts.push(preOwnedAlert)
          } else {
            this.alerts.push({ ...preOwnedAlert, tooltip: { headers: [], items: [] }, macro: { title: '', id: '' } })
          }
        }

        if (this.checkAlertPush(ringAge, ringAgeAlert) && this.subComponentActive(DiagnosticsSubComponents.Age)) {
          this.alerts.push(ringAgeAlert)
        }

        if (
          this.checkAlertPush(samplesState, samplesAlert) &&
          this.subComponentActive(DiagnosticsSubComponents.Samples)
        ) {
          this.alerts.push(samplesAlert)
        }

        if (newBatteryHealth && newBatteryHealthAlert) {
          // This should be always visible when the diagnostic is in subcomponents
          if (this.subComponentActive(DiagnosticsSubComponents.BatteryHealthy)) {
            this.alerts.push(newBatteryHealthAlert)
          }
        }

        // This should be always visible when the diagnostic is in subcomponents but not in sidecar
        const { chronotypeStatus, chronotypeAlert } = this.userDiagnostics.chronotypeDiagnostic(this.chronotype)
        if (
          this.checkAlertPush(chronotypeStatus, chronotypeAlert) &&
          this.subComponentActive(DiagnosticsSubComponents.Chronotype) &&
          !this.sidecar
        ) {
          this.alerts.push(chronotypeAlert)
        }

        if (this.checkAlertPush(selfTestHealth, selfTestHealthAlert)) {
          if (this.subComponentActive(DiagnosticsSubComponents.SelfTestHealth)) {
            this.alerts.push(selfTestHealthAlert)
          }
        }

        /**
         * Handle diagnostics the generic way. When adding new diagnostics, they should contain enough information so
         * that handleDiagnostics() function can handle them without addition client side logic.
         */
        activeDiagnostics.forEach((diagnosticsConfig) => {
          if (this.subComponentActive(diagnosticsConfig.id) && diagnosticsConfig.enabled && diagnosticsConfig.data) {
            const { status, alert } = this.userDiagnostics.handleDiagnostics(
              diagnosticsConfig.data,
              diagnosticsConfig.id,
            )
            if (this.checkAlertPush(status, alert)) {
              this.alerts.push(alert)
            }
          }
        })

        // Run Cycle Insights diagnostics
        this.runCycleInsightsDiagnostics()
      }

      if (!this.sidecar && !this.alerts.length) {
        this.alerts.push({
          type: 'success',
          color: 'green',
          message: 'All diagnostics are passing with flying colors',
          link: '',
          macro: { title: '', id: '' },
        })
      }

      this.alerts.sort((a, b) => {
        return this.getAlertWeight(b) - this.getAlertWeight(a)
      })
    }

    // Cycle insights diagnostics
    private runCycleInsightsDiagnostics() {
      if (this.cycleInsights) {
        const { cycleInsightsStatus, cycleInsightsAlert } = this.userDiagnostics.cycleInsightsDiagnostics(
          this.cycleInsights,
        )
        if (this.checkAlertPush(cycleInsightsStatus, cycleInsightsAlert)) {
          if (this.subComponentActive(DiagnosticsSubComponents.CycleInsights)) {
            this.alerts.push(cycleInsightsAlert)
          }
        }
      }
    }
    /**
     * Check if diagnostic should be visible or hidden
     * @param level
     * @param alert
     * @private
     */
    private checkAlertPush(level: string, alert: string | DiagnosticAlert) {
      return (
        this.diagnosticsInValidation.includes(typeof alert === 'string' ? alert : alert['id']) ||
        !this.sidecar ||
        level !== 'success'
      )
    }

    private subscriptionDiagnostic() {
      let errors: any[] = []
      let subscriptionAlert: any
      let subscriptionDiagnosticState: string = 'info'
      let guruLink = 'https://app.getguru.com/card/Tgdz9gzc/How-to-Confirm-Email-Account-Awareness-of-Data-Privacy'

      const headers = [{ title: 'Errors', key: 'error', align: 'left' }]

      const messages: { [key: string]: string } = {
        info: 'Subscription information not found for this account',
        error: '',
        success: 'Subscription state can be updated',
      }

      // ***BEGIN OF ERROR LIST ***
      // this error_list is generated least important to the most important at the bottom
      // messages.errror is overwritten as each subsequent conditions is true

      if (this.subscriptions && this.subscriptions.subscriptions?.length) {
        guruLink = 'https://app.getguru.com/card/Tj8yApac/How-to-Troubleshooting-for-Membership'
        const pending_cancellation =
          this.subscriptions.subscriptions.filter(function (sub: SubscriptionInfo) {
            return sub.pendingCancellation
          }).length > 0

        if (this.subscriptions.defaultPaymentMethodCardExpired == true) {
          errors.push({
            key: 'payment-expiring',
            error: 'Expired payment method',
          })
          guruLink = 'https://app.getguru.com/card/cgdzA47i/How-to-Payment-issues-in-My-Account'
          messages.error = "User's default payment card has expired!"
        }

        if (this.subscriptions.dunningLevel) {
          errors.push({
            key: 'dunning-user',
            error: 'Account in dunning.',
          })
          guruLink = 'https://app.getguru.com/card/c8A5RGgi/How-to-Upgrade-Membership-to-Free-Lifetime'
          messages.error = `Account is in dunning state: ${this.subscriptions.dunningLevel}`
        }

        if (pending_cancellation) {
          errors.push({
            key: 'cancelled-subscription',
            error: 'Subscription is pending cancellation',
          })
          messages.error = 'Subscription is pending cancellation'
        }
        subscriptionDiagnosticState = errors.length > 0 ? 'error' : 'success'
      }

      // ***END OF ERROR LIST ***

      subscriptionAlert = {
        id: 'subscription',
        type: subscriptionDiagnosticState,
        error_list: errors,
        tooltip: { headers: headers, items: errors },
        color: alertColors[subscriptionDiagnosticState],
        message: messages[subscriptionDiagnosticState],
        link: guruLink,
        macro: { title: '', id: '' },
      }
      return {
        subscriptionDiagnosticState,
        subscriptionAlert,
      }
    }

    private ringAgeDiagnostic() {
      let items: any[] = []
      let ringAge: string = 'info'
      let ringAgeAlert: any

      const headers = [
        { title: 'Ring', key: 'ring', align: 'left' },
        { title: 'Ring registration date', key: 'date', align: 'center' },
        { title: 'Age > 30 days', key: 'age', align: 'center' },
        { title: 'Diagnostic', key: 'diagnostic', align: 'end' },
      ]

      const messages: { [key: string]: string } = {
        info: 'Unable to run ring age diagnostics',
        error: 'Active ring registration date not set',
        warning: 'New ring in use, less than 30 days old',
        success: 'Ring has been in use longer than 30 days',
      }

      if (this.ring) {
        const checkDate = this.checkPastXDays(this.ring.registrationDate, 30)
        if (this.ring.registrationDate) {
          if (checkDate) {
            ringAge = 'warning'
          } else {
            ringAge = 'success'
          }
        } else {
          ringAge = 'error'
        }

        const status = checkDate ? this.STATUS.fail : this.STATUS.pass

        items.push({
          ring: this.ring.hardwareType,
          date: this.formatDateTime(this.ring.registrationDate, 'DD/MM/YYYY'),
          age: status,
          diagnostic: status,
        })

        ringAgeAlert = {
          id: 'ring-age',
          type: ringAge,
          color: alertColors[ringAge],
          message: messages[ringAge],
          tooltip: { headers: headers, items: items },
          link: 'https://app.getguru.com/card/Todj5bzc/What-is-Weekly-Monthly-Quarterly-Yearly-Reports',
          macro: { title: '', id: '' },
        }
      }
      return {
        ringAge,
        ringAgeAlert,
      }
    }

    private ringSamplesDiagnostic() {
      let items: any = []
      let samplesAlert: any
      let samplesState: string = 'info'

      const headers = [
        { title: 'Ring', key: 'ring', align: 'left' },
        { title: 'Description', key: 'description', align: 'center' },
        { title: 'More info', key: 'link', align: 'center' },
        { title: 'Diagnostic', key: 'diagnostic', align: 'end' },
      ]
      const messages: { [key: string]: string } = {
        info: 'Unable to get tracking lists data',
        error: 'Ring belongs to multiple tracking lists',
        success: 'Ring does not exist in any of the tracking lists',
      }

      if (!!this.sampleFilters && this.user?.rings.length) {
        if (this.sampleFilters.length) {
          samplesState = 'error'
          for (const item of this.sampleFilters) {
            items.push({
              ring: 'Current',
              description: item.description,
              link: item.descriptionUrl,
              diagnostic: 'info',
            })
          }
          if (this.sampleFilters.length == 1) {
            messages[samplesState] = this.sampleFilters[0].description
          }
        } else {
          samplesState = 'success'
        }
      } else {
        messages.info = 'No serial available for the selected ring'
      }
      samplesAlert = {
        id: 'ring-lists',
        type: samplesState === 'success' ? 'success' : 'info',
        color: samplesState === 'success' ? alertColors.success : alertColors.info,
        message: messages[samplesState],
        tooltip: { headers: headers, items: items },
        link: '',
        macro: { title: '', id: '' },
      }

      return {
        samplesState,
        samplesAlert,
      }
    }

    private newBatteryDiagnosticReport(): {
      newBatteryHealth: string | null
      newBatteryHealthAlert: DiagnosticAlert | null
    } {
      const batteryDiagnostics = this.batteryDiagnostics

      // If we have no data, return nulls
      if (!batteryDiagnostics) {
        const newBatteryHealth = null
        const newBatteryHealthAlert = null
        return { newBatteryHealth, newBatteryHealthAlert }
      }

      const newBatteryHealth = batteryDiagnostics.status

      const newBatteryHealthAlert = {
        id: 'battery-new-diagnostics',
        type: newBatteryHealth,
        color: alertColors[newBatteryHealth],
        message: batteryDiagnostics.title,
        tooltip: { headers: batteryDiagnostics.extraInfo.headers, items: batteryDiagnostics.extraInfo.items },
        link: 'https://app.getguru.com/card/i6jagALT/Battery-Drain-Gen-3',
        macro: { title: '', id: '' },
      }

      return { newBatteryHealth, newBatteryHealthAlert }
    }

    private selfTestDiagnostic(): { selfTestHealth: string; selfTestHealthAlert: DiagnosticAlert } {
      const { selfTestHealth, selfTestHealthAlert } = this.ringDiagnostics.selfTestErrorReport(this.summary)
      // We iterate alert items here because date time formatting is not available in RingDiagnostics.
      selfTestHealthAlert.tooltip.items.forEach((alertItem: { date: string }) => {
        alertItem.date = this.formatDateTime(alertItem.date, 'DD/MM/YYYY')
      })
      return { selfTestHealth, selfTestHealthAlert }
    }

    private getAlertWeight(alert: any): number {
      if (Object.keys(alert).includes('type')) {
        switch (alert['type']) {
          case 'success':
            return 1
          case 'info':
            return 2
          case 'warning':
            return 3
          case 'error':
            return 4
          default:
            return 0
        }
      }
      return 0
    }
    private notifyAgent(message: string) {
      if (!this.notificationShown && this.toggleMacroNotification) {
        this.$zafClient.invoke('notify', `${message}`, 'error', 9000)
        this.notificationShown = true
      }
    }

    private checkDiagnostics(alertsArr: any[]) {
      let alertMessage = checkDiagnosticsSidecar(
        this.macroArray,
        alertsArr,
        this.zdTags,
        this.zdGroup,
        this.requesterLocale,
        // this.zdProductIssue,
        // this.zdRingType,
      )
      if (alertMessage) {
        this.notifyAgent(alertMessage)
      }
    }
  }

  export default toNative(DiagnosticsV2)
</script>

<style lang="scss" scoped>
  :deep(.v-alert) {
    .v-icon {
      margin: 0 8px !important;
    }
  }

  :deep(.details-button) {
    color: #2196f3;
    font-size: 0.9rem;
    cursor: pointer;
  }
</style>
