<template>
  <v-card min-width="100%">
    <v-card-title>
      <p class="text-overline">Client info</p>
      <v-spacer />
      <v-tooltip location="top">
        <template #activator="{ props }">
          <v-btn
            v-if="copyContentsFeature"
            v-bind="props"
            color="grey"
            variant="plain"
            class="mr-n4"
            icon="mdi-content-copy"
            @click="copyContents()"
          />
        </template>

        {{ useClipboard.copied ? 'Copied' : 'Copy to clipboard' }}
      </v-tooltip>
    </v-card-title>

    <v-card-text class="px-10 pb-10 mt-3">
      <v-container class="pa-0 mb-n8">
        <v-row class="align-end">
          <v-col class="pr-6" cols="12" md="4" lg="4" xl="3">
            <div class="mx-n2 mt-n3 mb-6 text-no-wrap">Oura Ring ({{ ring.hardwareType || 'Unknown' }})</div>

            <div class="text--black">
              <v-text-field
                label="Bootloader version"
                readonly
                :model-value="
                  ring.bootloaderUpdatedOn
                    ? ring.bootloaderVersion + ' ' + formatDateTime(ring.bootloaderUpdatedOn, '(DD MMM YYYY)', 0)
                    : ring.bootloaderVersion
                "
                :class="{
                  active: isLatestBootloaderVersion(),
                }"
              >
                <template #prepend>
                  <v-tooltip location="top">
                    <template #activator="{ props }">
                      <v-icon icon="mdi-star" :color="isLatestBootloaderVersion() ? 'green' : ''" v-bind="props" />
                    </template>
                    {{
                      isLatestBootloaderVersion()
                        ? 'Has newest version'
                        : 'Not the newest version. (Latest: ' + versions?.bootloaderVersion + ')'
                    }}
                  </v-tooltip>
                </template>
              </v-text-field>

              <v-text-field
                label="Firmware version"
                readonly
                :model-value="
                  ring.firmwareUpdatedOn
                    ? ring.firmwareVersion + ' ' + formatDateTime(ring.firmwareUpdatedOn, '(DD MMM YYYY)', 0)
                    : ring.firmwareVersion
                "
                :class="{ active: isLatestFirmwareVersion() }"
              >
                <template #prepend>
                  <v-tooltip location="top">
                    <template #activator="{ props }">
                      <v-icon icon="mdi-star" :color="isLatestFirmwareVersion() ? 'green' : ''" v-bind="props" />
                    </template>
                    {{
                      isLatestFirmwareVersion()
                        ? 'Has newest version'
                        : 'Not the newest version. (Latest: ' + versions?.firmwareVersion + ')'
                    }}
                  </v-tooltip>
                </template>
              </v-text-field>
            </div>
          </v-col>

          <v-col class="pr-6" cols="12" md="4" lg="4" xl="3">
            <div class="mx-n2 mt-n3 mb-6 text-no-wrap">
              {{ ring && ring.deviceModel ? ring.deviceModel[0].toUpperCase() + ring.deviceModel.slice(1) : 'Device' }}
              ({{ ring?.clientFlavor ? ring.clientFlavor : 'unknown' }} app)
            </div>

            <div class="text--black">
              <v-text-field
                :label="getLabel(ring.deviceOs) + ' version'"
                :model-value="
                  ring.deviceOsUpdatedOn
                    ? ring.deviceOsVersion + ' ' + formatDateTime(ring.deviceOsUpdatedOn, '(DD MMM YYYY)', 0)
                    : ring.deviceOsVersion
                "
                readonly
                :class="{ active: isSupportedOsVersion() }"
              >
                <template #prepend>
                  <v-tooltip location="top">
                    <template #activator="{ props }">
                      <v-icon icon="mdi-star" :color="isSupportedOsVersion() ? 'green' : ''" v-bind="props" />
                    </template>
                    {{
                      isSupportedOsVersion()
                        ? 'Has supported OS version'
                        : 'OS version not supported. (Minimum: ' + versions?.minimumOsVersion + ')'
                    }}
                  </v-tooltip>
                </template>
              </v-text-field>

              <v-text-field
                label="App version"
                readonly
                :model-value="
                  ring.clientUpdatedOn
                    ? ring.clientVersion + ' ' + formatDateTime(ring.clientUpdatedOn, '(DD MMM YYYY)', 0)
                    : ring.clientVersion
                "
                :class="{ active: isLatestClientVersion() }"
              >
                <template #prepend>
                  <v-tooltip location="top">
                    <template #activator="{ props }">
                      <v-icon icon="mdi-star" :color="isLatestClientVersion() ? 'green' : ''" v-bind="props" />
                    </template>
                    {{
                      isLatestClientVersion()
                        ? 'Has newest version'
                        : 'Not the newest version. (Latest: ' + versions?.appVersion + ')'
                    }}
                  </v-tooltip>
                </template>
              </v-text-field>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
  import { useClipboard } from '@vueuse/core'

  import { Component, Prop, mixins, toNative } from 'vue-facing-decorator'

  import { DateTime } from '#mixins/dateTime'

  import { copyContentsFeature } from '#utils/darwinFeatureFlags'

  import { ConfigStore } from '#stores'

  import { LatestVersions, Member, Ring } from '#types'

  @Component
  /**
   * ClientInfo component for new (cloud management) user responses.
   */
  class ClientV2 extends mixins(DateTime) {
    @Prop() public member!: Member
    @Prop() public ring!: Ring

    public configStore = new ConfigStore()

    public useClipboard = useClipboard()

    public get versions(): LatestVersions | null {
      return this.configStore.versions
    }

    public get copyContentsFeature() {
      return copyContentsFeature()
    }

    public async mounted() {
      await this.configStore.getLatestVersions(this.member.uuid)
    }

    public getLabel(os: string | null): string {
      if (os === 'ios') {
        return 'iOS'
      } else if (os === 'android') {
        return 'Android'
      } else {
        return 'Undefined'
      }
    }

    public isLatestBootloaderVersion(): boolean {
      return this.versions?.bootloaderVersion === this.ring.bootloaderVersion
    }

    public isLatestFirmwareVersion(): boolean {
      return this.versions?.firmwareVersion === this.ring.firmwareVersion
    }

    public isSupportedOsVersion(): boolean {
      if (this.versions?.minimumOsVersion && this.member.devices[0]?.osVersion) {
        return this.versions?.minimumOsVersion <= this.member.devices[0]?.osVersion
      }
      return false
    }

    public isLatestClientVersion(): boolean {
      return this.versions?.appVersion === this.member.clients[0]?.version
    }

    public copyContents() {
      const copyContents = `Ring: ${this.ring.hardwareType || 'Unknown'} \nBootloader version: ${this.ring.bootloaderVersion} \nFirmware version: ${this.ring.firmwareVersion} \nDevice model: ${this.ring.deviceModel ? this.ring.deviceModel[0].toUpperCase() + this.ring.deviceModel.slice(1) : 'device'} (${this.ring?.clientFlavor ? this.ring.clientFlavor : 'unknown'} app) \n${this.getLabel(this.ring.deviceOs)} version:  ${
        this.ring.deviceOsUpdatedOn
          ? this.ring.deviceOsVersion + ' ' + this.formatDateTime(this.ring.deviceOsUpdatedOn, '(DD MMM YYYY)', 0)
          : this.ring.deviceOsVersion
      } \nApp version: ${
        this.ring.clientUpdatedOn
          ? this.ring.clientVersion + ' ' + this.formatDateTime(this.ring.clientUpdatedOn, '(DD MMM YYYY)', 0)
          : this.ring.clientVersion
      }`

      this.useClipboard.copy(copyContents)
    }
  }

  export default toNative(ClientV2)
</script>
