<template>
  <!-- eslint-disable vue/v-on-handler-style -->
  <v-container fill-height>
    <v-row class="viewport mt-4">
      <v-col class="d-flex" cols="12" sm="12" md="5">
        <v-row>
          <v-col>
            <v-text-field
              ref="search"
              append-inner-icon="mdi-account-search"
              placeholder="Regexp search, e.g. assa.*sleep. Press enter to perform search."
              :rules="[rules.minLength, rules.maxLength]"
              hide-details="auto"
              @click:append="loadLogs()"
              @keydown.enter="loadLogs()"
            />
          </v-col>
        </v-row>
      </v-col>

      <v-col class="d-flex" cols="12" sm="12" md="7">
        <v-row align="center" class="">
          <v-col>
            <DateRangePicker
              v-model:start-date="fromDate"
              v-model:end-date="toDate"
              :min-date="date1MonthBack || null"
              @valid="datesAreValid = $event"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="mt-n8 mb-4">
      <v-col>
        <v-alert
          v-model="debugInit"
          class="viewport pt-3 v-sheet--tile"
          style="position: relative; flex: 0 0 100%"
          type="info"
        >
          Syncing debug logs, please wait...
        </v-alert>
      </v-col>
    </v-row>

    <v-row class="viewport mb-4">
      <v-col>
        <div class="text-h red--text">
          ⚠
          <span class="text-subtitle-2">
            Please note that data is only available one month backwards from the current date.
          </span>
        </div>
        <div class="text-subtitle-2 grey--text">
          Use regular expressions to search the content of the debug logs. Case sensitiveness can be changed from the
          Quick Settings. For possible regexp options see the
          <a href="https://prestodb.io/docs/current/functions/regexp.html" target="_blank" rel="noreferrer noopener">
            Presto regexp documentation
          </a>
          .
        </div>
        <div class="text-subtitle-2 grey--text">
          Tip: If you want to include special characters such as
          <code>* # ( ) [ ] &lt; &gt;</code>
          into your search, you need to escape them with
          <code>\</code>
          . For example, if you want to search for text
          <code>(background)*foregroundEvent*-appForegrounded-&gt;(foregroundSync)</code>
          add
          <code>\</code>
          in front of every special character like this:
          <code>\(background\)\*foregroundEvent\*-appForegrounded-\>\(foregroundSync\)</code>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col align="stretch" class="pa-0">
        <v-card :class="{ viewport: !expandedViewsPrefs.debug }" :loading="dataWait">
          <v-data-table
            :headers="visibleHeaders"
            :items="logs"
            :items-per-page="10000"
            density="compact"
            disable-pagination
            hide-default-footer
            class="elevation-1"
            :class="!search ? 'non-expandable' : ''"
          >
            <template #item="{ item }">
              <tr :class="item.lineIndex === extra ? 'selected-row' : ''" @click="expandLogs(item)">
                <td>
                  <div class="text-no-wrap">
                    {{ formatDateTime(item.timestamp, 'HH:mm:ss.SSS - DD MMM YYYY') }}
                  </div>
                </td>
                <td>{{ item.userUid }}</td>
                <td>
                  <!-- eslint-disable-next-line vue/no-v-html -->
                  <div v-html="sanitizeHTML(item.content)" />
                </td>
                <td>
                  <v-icon v-if="search" small class="expand mr-2">mdi-unfold-more-horizontal</v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="openExtraLogsDialog" width="100vw" @click:outside="removeExtraLogs">
      <v-card>
        <v-card-actions class="float-end mb-n5 pr-4 pt-2">
          <v-spacer />
          <v-icon icon="mdi-window-close" @click="removeExtraLogs" />
        </v-card-actions>
        <v-data-table
          ref="extraLogsTable"
          :headers="visibleHeaders"
          :items="extraLogs"
          :items-per-page="1000"
          disable-pagination
          hide-default-footer
          class="elevation-1"
        >
          <template #item="{ item }">
            <tr :class="item.lineIndex === extra ? 'extra-logs-selected-row' : ''" :data-id="item.lineIndex">
              <td>
                <div class="text-no-wrap">
                  {{ formatDateTime(item.timestamp, 'HH:mm:ss.SSS - DD MMM YYYY') }}
                </div>
              </td>
              <td>{{ item.userUid }}</td>
              <td>
                <!-- eslint-disable-next-line vue/no-v-html -->
                <div v-html="sanitizeHTML(item.content)" />
              </td>
              <td>
                <v-icon v-if="search" small class="expand mr-2">mdi-unfold-more-horizontal</v-icon>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script lang="ts">
  import { Component, Prop, Watch, mixins, toNative } from 'vue-facing-decorator'

  import { logEvent } from 'firebase/analytics'

  import { Debounce } from '@jouzen/outo-apps-toolkit'

  import { DateTime } from '#mixins/dateTime'

  import { debugHeaders, debugLogSearchMaxLength, debugLogSearchMinLength } from '#views/users/constants'

  import { DebugStore, PrefsStore } from '#stores'

  @Component
  class Debug extends mixins(DateTime) {
    @Prop() public uuid!: string

    public prefsStore = new PrefsStore()

    public debugStore = new DebugStore()

    public declare $refs: {
      search: any
      extraLogsTable: any
    }

    public date1MonthBack = ''
    public fromDate = ''
    public toDate = ''

    public datesAreValid = false

    public search = ''

    public showExtraLogs: boolean = false

    public extra = 0

    public headers = debugHeaders

    public rules = {
      maxLength: (value: any) => {
        if (value) {
          return (
            value.length <= debugLogSearchMaxLength ||
            `Keyword too long, maximum length is ${debugLogSearchMaxLength} chars.`
          )
        }
        return true
      },
      minLength: (value: any) => {
        if (value) {
          return (
            value.length >= debugLogSearchMinLength ||
            `Keyword too short, min length is ${debugLogSearchMinLength} chars.`
          )
        }
        return true
      },
    }

    @Watch('fromDate')
    @Watch('toDate')
    @Watch('datesAreValid')
    protected onToOrFromDateChanged() {
      this.loadLogs()
    }

    @Watch('uuid')
    protected onUUIDChanged(_val: string, _oldVal: string) {
      this.loadLogs()
    }

    @Watch('expandedViewsPrefs')
    protected onExpandedChanged(val: string, _oldVal: string) {
      this.prefsStore.changeExpandedViewsPrefs(val)
    }

    public mounted() {
      this.date1MonthBack = this.$dayjs().subtract(1, 'month').format('YYYY-MM-DD')
      this.fromDate = this.$dayjs().subtract(1, 'month').format('YYYY-MM-DD')
      this.toDate = this.$dayjs().format('YYYY-MM-DD')

      logEvent(this.$analytics, 'page_view', {
        page_title: 'Debug Logs',
        page_location: window.location.toString().split('?')[0],
      })
      this.loadLogs()
    }

    public get openExtraLogsDialog() {
      return this.showExtraLogs && !!this.extraLogs.length
    }
    public get logs() {
      return this.debugStore.debugLogs || []
    }

    public get dataWait() {
      return this.debugStore.dataWait
    }

    public get debugInit() {
      return this.debugStore.initWait
    }

    public get extraLogs() {
      this.showExtraLogs = true
      return this.debugStore.extraLogs || []
    }

    public get debugLogPrefs() {
      return this.prefsStore.debugLogPrefs
    }

    public get expandedViewsPrefs() {
      return this.prefsStore.expandedViewsPrefs
    }

    public get visibleHeaders(): any[] {
      const devices = [...new Set(this.logs.map((log: any) => log.deviceUid))]

      return this.headers.filter(
        (header) => (header.key !== 'userUid' || !this.uuid) && (header.key !== 'deviceUid' || devices.length !== 1),
      )
    }

    public loadLogs() {
      if (this.uuid && this.datesAreValid) {
        this.debugStore.resetLogs()
        this.updateLogs()
      }
    }

    // public lineClass(line: any) {
    //  return this.extraLogs.length && line.item.lineIndex === this.extra ? 'selected' : ''
    // }

    public sanitizeHTML(html: string) {
      return html
        .replace(/&/g, '&amp;')
        .replace(/(?!<\/?strong>)</g, '&lt;')
        .replace(/(!<\/?strong>?)>/g, '&gt;')
    }

    @Debounce(1000)
    private updateLogs() {
      // Due to Vue v-model lag/bug we only update search value here (will be fixed in Vue 3)
      this.search = this.$refs.search.$el.getElementsByTagName('input')[0].value
      if (
        this.uuid &&
        this.debugLogPrefs &&
        this.search &&
        this.search.length <= debugLogSearchMaxLength &&
        this.search.length >= debugLogSearchMinLength
      ) {
        this.debugStore.setDebugLogs({
          search: this.search,
          uuid: this.uuid,
          from: this.fromDate,
          to: this.toDate,
          count: this.debugLogPrefs['count'],
          caseSensitive: this.debugLogPrefs['caseSensitive'],
        })
      }
    }

    public async expandLogs(line: any) {
      this.extra = line.lineIndex

      await this.debugStore.setExtraLogs({
        uuid: this.uuid,
        fileTimestamp: line.fileTimestamp,
        line: line.lineIndex,
        before: 100,
        after: 100,
      })
      this.showExtraLogs = true

      this.$nextTick(() => {
        const activeRow = this.$refs.extraLogsTable?.$el?.querySelector(`[data-id="${this.extra}"]`)
        if (activeRow) {
          activeRow.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
      })
    }

    public removeExtraLogs() {
      this.showExtraLogs = false
      this.debugStore.extraLogs = []
    }
  }

  export default toNative(Debug)
</script>

<style lang="scss" scoped>
  .bg {
    background: #f8f8fb;
    height: 100%;
  }

  .loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
  :deep(.v-alert) {
    .v-icon {
      align-self: center !important;
    }
  }
  :deep(tr) {
    cursor: pointer;
  }

  :deep(.non-expandable tr) {
    cursor: auto;
  }

  :deep(.expand) {
    visibility: hidden;
  }

  :deep(tbody tr:hover) {
    cursor: pointer;
    .expand {
      visibility: visible;
    }
  }

  :deep(.selected-row) {
    background-color: #e0e0e0;
  }

  :deep(.extra-logs-selected-row) {
    background-color: #fce4ec;
  }

  code {
    background: #c7c7c7;
    padding: 0.1rem 0.2rem;
    border-radius: 0.2rem;
  }
</style>
