import { createRouter, createWebHistory } from 'vue-router'

import { getAnalytics, logEvent } from 'firebase/analytics'

import Home from '#Home.vue'

import AdminView from '#views/admin/AdminView.vue'
import AppsView from '#views/apps/AppsView.vue'
import AuditView from '#views/audit/AuditView.vue'
import InternalDebugView from '#views/debug/InternalDebug.vue'
import OrderSearch from '#views/order/OrderSearch.vue'
import RingDetails from '#views/rings/RingDetails.vue'
import RingSearch from '#views/rings/RingSearch.vue'
import EditOrganization from '#views/teams/EditOrganization.vue'
import TeamsView from '#views/teams/TeamsView.vue'
import SidecarView from '#views/users/SidecarView.vue'
import UserView from '#views/users/UserView.vue'
import UsersView from '#views/users/UsersView.vue'

import { AppStore } from '#stores'

// import UserConsent from '#views/shared/UserConsent.vue'

/* async function requireAuth(to: Route, from: Route, next: any) {
  const isLoggedIn = true

  if (!isLoggedIn) {
    next({
      path: '/login',
      query: { redirect: to.fullPath },
    })
  } else {
    next()
  }
} */
/*
function requireUserConsent(to: Route, from: Route, next: any) {
  let status: boolean
  if (!localStorage.getItem('OuraUserConsent') && !sessionStorage.getItem('OuraUserConsent')) {
    status = true
  } else {
    status = false
  }
  if (status) {
    next({
      path: '/consent',
      query: {
        nextUrl: to.fullPath,
      },
    })
  } else {
    next()
  }
}
*/
const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      title: 'Home',
    },
    component: Home,
    // beforeEnter: requireUserConsent,
  },
  {
    path: '/login',
    component: Home,
    meta: {
      title: 'Login',
    },
    beforeEnter(to: any, from: any, next: any) {
      const appStore = new AppStore()

      setTimeout(() => appStore.login(), 1000)

      next({ path: '/' })
    },
  },
  {
    path: '/logout',
    component: Home,
    meta: {
      title: 'Logout',
    },
    beforeEnter(to: any, from: any, next: any) {
      const appStore = new AppStore()

      appStore.logout()

      next({ path: '/' })
    },
  },
  {
    path: '/users',
    name: 'Oura Users',
    meta: {
      title: 'Oura Users',
    },
    component: UsersView,
    // beforeEnter: requireUserConsent,
  },
  {
    path: '/users/:uuid',
    component: UserView,
    name: 'Oura User',
    meta: {
      title: 'Oura User',
    },
    props: true,
    // beforeEnter: requireUserConsent,
  },

  {
    path: '/audit/:tab',
    component: AuditView,
    name: 'Auditing',
    meta: {
      title: 'Auditing',
    },
    props: true,
    // beforeEnter: requireUserConsent,
  },
  {
    path: '/api',
    component: AppsView,
    name: 'Oura Apps',
    meta: {
      title: 'Oura Apps',
    },
    props: true,
    // beforeEnter: requireUserConsent,
  },
  {
    path: '/teams',
    component: TeamsView,
    name: 'Oura Teams',
    meta: {
      title: 'Oura Teams',
    },
    props: true,
    // beforeEnter: requireUserConsent,
    children: [
      {
        path: ':orgID',
        component: EditOrganization,
        meta: {
          title: 'Oura Teams',
        },
        props: true,
        // beforeEnter: requireUserConsent,
      },
    ],
  },
  {
    path: '/rings/:ringOrChargerSerial',
    component: RingDetails,
    name: 'ring-details',
    meta: {
      title: 'Oura Rings',
    },
    props: true,
  },
  {
    path: '/ring-search',
    component: RingSearch,
    meta: {
      title: 'Oura Rings',
    },
    props: true,
  },
  {
    path: '/order',
    component: OrderSearch,
    meta: {
      title: 'Orders',
    },
    props: true,
  },
  {
    path: '/admin/:tab',
    component: AdminView,
    meta: {
      title: 'Accounts',
    },
    props: true,
    // beforeEnter: requireUserConsent,
  },
  {
    path: '/admin',
    redirect: () => '/admin/bulk',
  },

  {
    path: '/sidecar',
    name: 'Darwin Sidecar',
    component: SidecarView,
    meta: {
      title: 'Darwin Sidecar',
      panel: true,
    },
    props: true,
  },
  {
    path: '/internal-debug',
    name: 'Internal debug',
    component: InternalDebugView,
    meta: {
      title: 'Internal debug',
    },
    props: true,
  },
  /*{
    path: '/consent',
    component: PopupDialog,
    meta: {
      title: 'Oura User Consent',
      panel: true,
    },
    props: true,
  },*/
  /* {
    path: '/debug',
    component: Debug,
    meta: {
      title: 'Debug Logs',
    },
    props: true,
    // beforeEnter: requireAuth,
  },
  {
    path: '/debug/:uuid',
    component: Debug,
    meta: {
      title: 'Debug Logs',
    },
    props: true,
    // beforeEnter: requireAuth,
  }, */
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.afterEach((to, from) => {
  if (from?.name !== to?.name || !from.name) {
    logEvent(getAnalytics(), 'page_view', {
      page_title: to?.name?.toString() || '',
      page_location: window.location.toString().split('?')[0],
      page_path: to?.name === 'Oura User' ? '/users/:id' : to.path?.toString(),
    })
  }
})

// Let's set the path to store so we can manage user access there
router.beforeEach(async (to, from, next) => {
  const appStore = new AppStore()

  if (to.path !== from.path) {
    appStore.currentRoute = to.path
    appStore.previousRoute = from.path.replace(/\/$/, '')
  }

  next()
})

export default router
