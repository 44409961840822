<template>
  <div>
    <v-card min-width="100%">
      <v-card-item>
        <v-card-title class="d-flex">
          <EmailV2 :member="user" :account-info="false" />

          <v-spacer />

          <v-menu
            v-if="personal || rights.includes('allowPersonalDataAccess') || rights.includes('allowDataDeletionAccess')"
            location="end"
            offset-y
          >
            <template #activator="{ props }">
              <v-icon class="ma-1" v-bind="props" size="x-small">mdi-dots-vertical</v-icon>
            </template>
            <v-list>
              <v-list-item
                v-if="rights.includes('allowPersonalDataAccess')"
                :disabled="user.emailVerified || user.isVerificationBypassed"
                :prepend-icon="'mdi-email-sync-outline'"
                @click="verifyEmail = true"
              >
                <v-list-item-title>Re-send verification email</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="rights.includes('allowDataDeletionAccess')"
                :prepend-icon="'mdi-email-edit-outline'"
                @click="changeEmailClick()"
              >
                <v-list-item-title>Change member email address</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="remoteAppActions()" :prepend-icon="'mdi-remote'" @click="remoteAppActionDialog = true">
                <v-list-item-title>Run app action on users behalf</v-list-item-title>
              </v-list-item>

              <v-divider v-if="personal || rights.includes('allowDataDeletionAccess')" />

              <v-list-item
                v-if="personal || rights.includes('allowDataDeletionAccess')"
                :disabled="!!user.deleteTicket"
                :prepend-icon="'mdi-delete-clock-outline'"
                @click="deleteUser = true"
              >
                <v-list-item-title class="text-red">Mark member to be deleted</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-card-title>
      </v-card-item>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" md="6">
              <v-row class="align-start mb-n8">
                <v-col cols="12" md="6">
                  <v-text-field v-if="user" label="UUID" :model-value="user.uuid || ' '" readonly>
                    <template #prepend>
                      <v-tooltip location="top">
                        <template #activator="{ props }">
                          <v-icon
                            v-bind="props"
                            color="grey"
                            icon="mdi-content-copy"
                            @click="useClipboard.copy(user.uuid)"
                          />
                        </template>
                        {{ useClipboard.copied ? 'Copied' : 'Copy to clipboard' }}
                      </v-tooltip>
                    </template>
                  </v-text-field>

                  <v-text-field
                    v-if="ring && ring.serialNumber"
                    label="Ring Serial"
                    :model-value="ring.serialNumber || 'Ring not registered'"
                    readonly
                    class="mt-4"
                  >
                    <template #prepend>
                      <v-tooltip location="top">
                        <template #activator="{ props }">
                          <v-icon
                            v-bind="props"
                            color="grey"
                            icon="mdi-content-copy"
                            @click="useClipboard.copy(ring.serialNumber)"
                          />
                        </template>
                        {{ useClipboard.copied ? 'Copied' : 'Copy to clipboard' }}
                      </v-tooltip>
                    </template>
                  </v-text-field>

                  <v-text-field
                    label="Analytics ID"
                    :model-value="user.analyticsId || 'No data available'"
                    readonly
                    class="mt-4"
                  >
                    <template #prepend>
                      <v-tooltip location="top">
                        <template #activator="{ props }">
                          <v-icon
                            color="grey"
                            v-bind="props"
                            icon="mdi-content-copy"
                            @click="useClipboard.copy(user.analyticsId || '')"
                          />
                        </template>
                        {{ useClipboard.copied ? 'Copied' : 'Copy to clipboard' }}
                      </v-tooltip>
                    </template>
                  </v-text-field>

                  <v-text-field
                    v-if="ring && !ring.serialNumber"
                    label="Ring MAC Address"
                    :disabled="!ring"
                    :model-value="ring.macAddress || 'Ring not registered'"
                    readonly
                    prepend-icon="mdi-content-copy"
                  >
                    <template #prepend>
                      <v-tooltip location="top">
                        <template #activator="{ props }">
                          <v-icon
                            color="grey"
                            v-bind="props"
                            icon="mdi-content-copy"
                            @click="useClipboard.copy(ring.macAddress)"
                          />
                        </template>
                        {{ useClipboard.copied ? 'Copied' : 'Copy to clipboard' }}
                      </v-tooltip>
                    </template>
                  </v-text-field>

                  <v-text-field
                    v-if="showHipaaStatus"
                    label="HIPAA Status"
                    :model-value="user.hipaaStatus ? 'HIPAA Customer' : 'No HIPAA association'"
                    class="mt-3"
                    :class="user.hipaaStatus ? 'hipaa-member' : 'non-hipaa-member ml-6'"
                    readonly
                  >
                    <template #prepend>
                      <v-icon v-if="user.hipaaStatus" icon="mdi-alert-circle" />
                    </template>
                  </v-text-field>
                </v-col>

                <v-col class="pr-6" cols="12" md="6">
                  <v-text-field label="User created" :model-value="userCreatedAt" readonly />

                  <v-text-field
                    v-if="ring && ring.registrationDate"
                    label="Ring registered"
                    :model-value="ringRegistered"
                    readonly
                    class="mt-4"
                  />
                  <div v-if="showWarrantyEndDate">
                    <v-text-field
                      label="Warranty end date"
                      :model-value="warrantyEndDate"
                      class="mt-4"
                      :class="memberInfo.warrantyEndDateClass"
                      readonly
                    />
                    <v-tooltip v-if="warrantyStartDate" activator="parent" location="top" :disabled="false">
                      Warranty start date: {{ warrantyStartDate }}
                    </v-tooltip>
                  </div>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" md="6">
              <div ref="scroll" class="d-flex" style="overflow: auto">
                <div
                  v-if="ring && rings"
                  ref="rings"
                  class="pa-3 black--text d-flex flex-row-reverse justify-start flex-grow-1"
                >
                  <div v-for="(r, index) in rings" :key="r.macAddress">
                    <v-row class="ml-1 mr-0">
                      <div>
                        <v-tooltip location="top">
                          <template #activator="{ props }">
                            <div
                              class="ring pa-3 ml-3"
                              style="min-width: 120px"
                              :class="{
                                new: isNewRing(r),
                                selected: r.macAddress === ring.macAddress,
                              }"
                              v-bind="props"
                              @click="handleRingClick(r)"
                            >
                              <span>{{ ringLabel(r, index) }}</span>
                              <img
                                alt="Ring image"
                                style="
                                  display: block;
                                  margin: 8px auto;
                                  width: 64px;
                                  min-width: 64px;
                                  max-width: 64px;
                                  min-height: 64px;
                                  max-height: 64px;
                                "
                                :src="checkImgFile(r.design, r.color, r.hardwareType)"
                                @error="fallbackRingImage($event)"
                              />
                              <span class="text-caption pink--text">
                                {{
                                  `${
                                    r.ringSerialInfo?.product?.replace('ring', r.design ?? '') ?? 'unknown'
                                  }`.toUpperCase()
                                }}
                              </span>
                            </div>
                          </template>
                          Last update:
                          {{ formatDateTime(r.lastSeenDate, 'HH:mm Do MMM YYYY') }}
                          <template v-if="r.warrantyEndDate">
                            <br />
                            Warranty end date: {{ formatDateTime(r.warrantyEndDate, 'Do MMM YYYY') }}
                          </template>
                          <br />
                          Days since ring registered:
                          <span v-if="r.registrationDate">
                            {{
                              Math.round((Date.now() - new Date(r.registrationDate).getTime()) / 1000 / 60 / 60 / 24)
                            }}
                          </span>
                        </v-tooltip>
                      </div>
                    </v-row>
                    <v-row class="mr-n1" style="float: right">
                      <v-tooltip location="bottom">
                        <template #activator="{ props }">
                          <v-icon
                            v-if="
                              (rights.includes('allowDataDownloadAccess') || personal) &&
                              r.macAddress === ring.macAddress
                            "
                            icon="mdi-key"
                            style="float: right"
                            dark
                            v-bind="props"
                            @click="authKeyModal = true"
                          />
                        </template>
                        <span>Get ring authentication key</span>
                      </v-tooltip>
                    </v-row>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>

    <v-dialog v-if="verifyEmail" v-model="verifyEmail" width="500">
      <v-card>
        <v-card-title>Re-send the verification email?</v-card-title>

        <v-card-text>
          This will send a new verification email to
          <b>{{ user.email }}</b>
          .
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn @click="verifyEmail = false">Cancel</v-btn>
          <v-btn color="blue" @click="sendVerificationEmail()">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-if="changeEmail" v-model="changeEmail" persistent width="600">
      <v-card v-if="changeDeletedEmail" :loading="changingEmail">
        <v-card-title>Change member email address</v-card-title>
        <v-card-text>
          <p>Account is pending deletion and NOT confirmed by the member.</p>
          <p>The email will be freed automatically once the deletion request is confirmed by the member</p>
          <p>Are you sure you want to change the email?</p>

          <v-checkbox v-if="changeDeletedEmail" v-model="undoDeletion" label="Undo pending user deletion" />
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn @click="resetEmailChange()">Cancel</v-btn>
          <v-btn color="error" @click="changeDeletedEmail = false">Continue</v-btn>
        </v-card-actions>
      </v-card>

      <v-card v-else :loading="changingEmail">
        <v-card-title>Change member email address</v-card-title>
        <v-card-text>
          <div>
            This will change the member email from
            <b>{{ user.email }}</b>
            to the email given below. New verification email will be send if the checkbox below is checked.
          </div>

          <v-form v-model="emailValid">
            <v-text-field
              v-model="newEmail"
              class="mt-4 mb-n2"
              label="New email address"
              hide-details="auto"
              :rules="emailRules"
              :disabled="changingEmail"
            />

            <v-checkbox
              v-model="sendVerification"
              :disabled="changingEmail"
              label="Send verification email to the new email address"
            />

            <v-alert
              v-if="error"
              dense
              variant="text"
              tile
              type="error"
              class="mx-n4 mb-n4"
              style="position: static !important"
            >
              {{ error }}
            </v-alert>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn @click="resetEmailChange()">Cancel</v-btn>
          <v-btn color="blue" :disabled="!emailValid || changingEmail" @click="changeUserEmail()">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-if="deleteUser" v-model="deleteUser" width="700">
      <v-card>
        <v-card-title>Mark member for deletion?</v-card-title>

        <v-card-text>
          This will mark the member and all member's data to be deleted on next deletion run. Member deletions are
          processed every Friday at 12:00 UTC time.

          <div v-if="rings.length === 0" class="mt-3">
            Since the member doesn't have any active rings in use, deletion can proceed without requiring member
            confirmation.
            <v-checkbox v-model="deleteRequested" label="Delete without confirmation" />
          </div>
        </v-card-text>

        <v-alert
          v-if="deletionError"
          dense
          variant="text"
          tile
          type="error"
          class="mx-n4 mb-n4"
          style="position: static !important"
        >
          {{ deletionError }}
        </v-alert>

        <v-card-actions>
          <v-spacer />
          <v-btn @click="cancelDelete()">Cancel</v-btn>
          <v-btn color="red" @click="markForDeletion()">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-if="remoteAppActionDialog" v-model="remoteAppActionDialog" width="500">
      <v-card>
        <v-card-title>Run app action on users behalf</v-card-title>

        <v-card-text>
          <v-select
            v-model="remoteAppAction"
            :items="appActions"
            label="Action to run"
            @update:model-value="remoteAppActionText = appActionTexts[$event] || ''"
          />

          <v-switch v-model="remoteAppActionConfirm" label="Ask confirmation from the user" />

          <v-textarea
            v-model="remoteAppActionText"
            rows="2"
            class="mt-2"
            label="Confirmation dialog text"
            :disabled="!remoteAppActionConfirm"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn @click="remoteAppActionDialog = false">Cancel</v-btn>
          <v-btn color="green" @click="sendAppActionMessage()">Run</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <RingAuthKey v-if="authKeyModal && user && ring" :user="user" :ring="ring" @close="authKeyModal = false" />
  </div>
</template>

<script lang="ts">
  import { useClipboard } from '@vueuse/core'

  import { Component, Prop, Watch, mixins, toNative } from 'vue-facing-decorator'

  import { getFunctions, httpsCallable } from 'firebase/functions'

  import { DateTime } from '#mixins/dateTime'

  import { appActionTexts, appActions } from '#views/users/constants'

  import { MemberInfo } from '#views/users/types'

  import { remoteAppActions, showHipaaStatus, showWarrantyDatesOnDashboard } from '#utils/darwinFeatureFlags'
  import { extractMemberInfo } from '#utils/member-info/extract'
  import { getEmailValidationErrorMessage, isEmailValid } from '#utils/user/emailValidation'

  import { AppStore, RingsStore, UserStore } from '#stores'

  import { Member, Ring } from '#types'

  @Component({})
  class Info extends mixins(DateTime) {
    @Prop() public user!: Member
    @Prop() public ring!: Ring
    @Prop() public rings!: Ring[]
    @Prop() public updateRing!: (ring: any) => void

    public useClipboard = useClipboard()

    public userStore = new UserStore()
    public appStore = new AppStore()
    public ringsStore = new RingsStore()

    public error = ''
    public newEmail = ''

    public emailValid = false
    public deleteUser = false
    public changeEmail = false
    public verifyEmail = false
    public changingEmail = false
    public sendVerification = true
    public authKeyModal = false
    public deleteRequested = false
    public deletionError = ''
    public changeDeletedEmail: boolean | null = false
    public remoteAppActionDialog = false
    public remoteAppActionConfirm = true
    public undoDeletion: boolean = false

    public remoteAppAction = 'backup'
    public remoteAppActionText = appActionTexts['backup']

    private env = import.meta.env.VITE_APP_ENV

    public readonly appActions = appActions
    public readonly appActionTexts = appActionTexts

    public remoteAppActions = remoteAppActions

    public emailRules = [(v: string) => isEmailValid(v, this.env) || getEmailValidationErrorMessage(this.env)]
    public memberInfo: MemberInfo = {
      warrantyStartDate: null,
      warrantyEndDate: null,
      warrantyDaysLeftText: null,
      warrantyEndDateClass: 'not-expired',
    }

    public handleRingClick(ring: Ring) {
      this.ringsStore.setActiveRing(ring)
    }

    public get rights() {
      return this.appStore.getRights
    }

    public get activeRing(): Ring | null {
      return this.ringsStore.activeRing
    }

    public get showWarrantyEndDate() {
      return showWarrantyDatesOnDashboard()
    }

    public get showHipaaStatus() {
      return showHipaaStatus()
    }

    @Watch('activeRing')
    protected async onActiveRingChanged() {
      this.updateMemberInfo()
    }

    protected updateMemberInfo() {
      if (this.activeRing) {
        this.memberInfo = extractMemberInfo(this.activeRing)
      }
    }

    public get warrantyDetails() {
      return this.ringsStore.warrantyData
    }

    public get personal() {
      return this.userStore.user?.ownerAccess || this.userStore.user?.supportAccess || this.userStore.user?.sharedAccess
    }

    public get userCreatedAt() {
      return this.user.userCreatedAt
        ? this.formatDateTime(this.user.userCreatedAt, 'HH:mm Do MMM YYYY')
        : 'No data available'
    }

    public get ringRegistered() {
      return this.ring.registrationDate
        ? this.formatDateTime(this.ring.registrationDate, 'HH:mm Do MMM YYYY')
        : 'No data available'
    }

    public get warrantyStartDate(): string | null {
      return this.memberInfo.warrantyStartDate
        ? this.formatDateTime(this.activeRing?.warrantyStartDate, 'Do MMM YYYY')
        : null
    }

    public get warrantyEndDate() {
      return this.memberInfo.warrantyEndDate
        ? this.formatDateTime(this.memberInfo.warrantyEndDate, 'Do MMM YYYY') + this.memberInfo.warrantyDaysLeftText
        : 'No data available'
    }

    public mounted() {
      this.updateMemberInfo()
      setTimeout(() => {
        const e: any = this.$refs.rings
        const s: any = this.$refs.scroll

        if (s && e) {
          s.scrollLeft = Math.max(0, e.offsetWidth - s.offsetWidth)
        }
      })
    }

    public isNewRing(ring: any) {
      const rDate = new Date(ring.registrationDate)

      return Date.now() - rDate.getTime() < 30 * 24 * 60 * 60 * 1000
    }

    public isFaultyRing(ring: any) {
      return ring.warrantyData?.ringReturn?.replacedBy && ring.macAddress === this.rings[0].macAddress
    }

    public ringLabel(ring: any, index: number) {
      return index === 0 ? 'Current' : `Old ${index}`
    }

    public serialToLabel(serial: string) {
      if (serial) {
        serial = serial.replace(/,.*/, '')

        const index = this.rings.findIndex((r: any) => r.serialNumber === serial)

        return index >= 0 ? this.ringLabel(this.rings[index], index) + ' ring' : serial
      } else {
        return 'Unknown ring'
      }
    }

    public changeUserEmail() {
      this.error = ''
      this.changingEmail = true

      this.userStore
        .changeUserEmail({
          uuid: this.user.uuid,
          email: this.newEmail,
          verify: this.sendVerification,
        })
        .then(() => {
          this.changingEmail = false
          const requestError = this.userStore.requestErrors?.changeUserEmail

          if (requestError) {
            this.error = requestError.userMessage ?? ''
          } else {
            this.error = ''
            this.newEmail = ''
            this.changeEmail = false
          }
        })

      if (this.undoDeletion) {
        this.userStore.removeDeleteTicket(this.user.uuid)
      }
    }

    public resetEmailChange() {
      this.error = ''
      this.changeEmail = false
      this.changeDeletedEmail = false
    }

    public sendVerificationEmail() {
      this.verifyEmail = false

      this.userStore.sendVerificationEmail(this.user.uuid)
    }

    public checkImgFile(design: string | null, color: string | null, type: string | null) {
      let file = ''
      switch (type) {
        case 'gen2':
          file = design?.replace('gen1', 'heritage').replace('gemma', 'heritagex') + '-' + color + '.png'
          break

        case 'gen2m':
          file = design?.replace('gen1', 'heritage').replace('gemma', 'heritagex') + '-' + color + '.png'
          break

        case 'gen2x':
          file = design?.replace('heritage', 'heritagex') + '-' + color + '.png'
      }

      return `/rings/${file}`
    }

    public fallbackRingImage(event: Event) {
      const element = event.target as HTMLImageElement
      if (element) {
        element.src = `/rings/unknown-ring.png`
      }
    }

    public markForDeletion() {
      this.userStore
        .createDeleteTicket({
          user: this.user.uuid,
          status: this.deleteRequested ? 'requested' : 'initial',
        })
        .then((response) => {
          this.deletionError = response?.status === 200 ? null : response?.data?.detail

          if (response?.status === 200) {
            this.deleteUser = false
            this.deleteRequested = false
          }
        })
    }

    public cancelDelete() {
      this.deleteUser = false
      this.deleteRequested = false
    }

    public changeEmailClick() {
      this.changeEmail = true
      this.changeDeletedEmail = this.user.flags.markedForDeletion || this.user.flags.queuedForDeletion
      if (this.changeDeletedEmail) {
        this.undoDeletion = true
      }
    }

    public sendAppActionMessage() {
      httpsCallable(
        getFunctions(this.$firebase, 'europe-west1'),
        'sendMessageToApp',
      )({
        data: {
          darwin_remote_action: this.remoteAppAction.split('-')[0],
          action_execution_view: this.remoteAppAction.split('-')[1] || '',
          confirm_dialog_text: this.remoteAppActionConfirm ? this.remoteAppActionText : '',
        },
        token:
          'c1_xrNzYQqu2bDX9SaQm2F:APA91bEqmdsv0uNenlZ8mh-JB0_UlkGLxFYs--9lKFl7_dLr1qj17JcioqSFzEwIz1z6XxajnjGSJiXhA595cVN8mX7-DMzoGNKLHVdJdgVnVKDxhIGj2KBlS5RUTxc_U1EVs4_KqXt2',
      })

      this.remoteAppActionDialog = false
    }
  }

  export default toNative(Info)
</script>

<style lang="scss" scoped>
  .ring {
    position: relative;
    cursor: pointer;
    text-align: center;
    border: 2px solid #c9c9c9;

    span {
      font-weight: bold;
    }

    &.selected {
      border-color: #03a9f4;

      &.new {
        border-color: #8bc34a;
      }
    }
  }

  .mdi-content-copy {
    cursor: pointer;
  }

  .expired {
    color: red;
  }

  .hipaa-member {
    color: #ff9800;
  }

  .non-hipaa-member {
    color: #4caf50;
  }
</style>
