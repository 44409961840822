<template>
  <div>
    <v-card>
      <v-card-title class="d-flex justify-space-between">
        <p class="text-overline">payments</p>
        <div>
          <v-tooltip location="bottom">
            <template #activator="{ props }">
              <v-icon icon="mdi-refresh" size="x-small" class="ma-2" v-bind="props" @click="reloadPayments()" />
            </template>
            <span>Reload payments</span>
          </v-tooltip>
        </div>
      </v-card-title>

      <v-alert v-if="errorText" variant="outlined" type="error" color="red" class="px-6" style="position: relative">
        Error occured while trying to refund a payment: {{ errorText }}
      </v-alert>

      <v-data-table
        v-if="!isFetching"
        :headers="headers"
        :items="payments"
        :items-per-page="sidecar ? 2 : 10"
        no-data-text="No payments data available"
        :sort-by="[{ key: 'lastChanged', order: 'desc' }]"
        item-key="effectiveDate"
        :hide-default-footer="sidecar ? true : false"
        class="elevation 1"
      >
        <template #[`item.status`]="{ item }">
          <span v-if="item.status === 'Processed'" class="green--text text">{{ item.status }}</span>
          <span v-else-if="item.status === 'Error'" class="red--text text">{{ item.status }}</span>
          <span v-else>{{ item.status }}</span>
        </template>
        <template #[`item.amount`]="{ item }">
          {{ item.amount + ' ' + item.currency }}
        </template>
        <template #[`item.refundAmount`]="{ item }">
          <span v-if="item.refundAmount !== 0" class="red--text text">
            {{ item.refundAmount + ' ' + item.currency }}
          </span>
          <span v-else>{{ item.refundAmount + ' ' + item.currency }}</span>
        </template>

        <template #[`item.refundStatus`]="{ item }">
          <template v-if="item.refunds && item.refunds.length">
            <div :class="refundsStatusAndText(item.refunds)?.class">
              {{ refundsStatusAndText(item.refunds)?.status }}
            </div>
          </template>
          <template v-else>
            <span>Unknown</span>
          </template>
        </template>

        <template #[`item.actions`]="{ item }">
          <v-menu location="start" offset-y>
            <template #activator="{ props }">
              <v-btn icon="mdi-dots-vertical" v-bind="props" />
            </template>
            <v-list>
              <v-list-item
                style="cursor: pointer"
                :disabled="refundDisabled(item)"
                icon="mdi-cash-refund"
                tooltip="Refund Payment"
                @click="refundItem(item)"
              >
                <v-list-item-title>Refund Payment</v-list-item-title>
              </v-list-item>
              <v-tooltip v-if="refundDisabled(item)" location="top" activator="parent">
                <span>{{ refundDisabledReason(item) }}</span>
              </v-tooltip>
              <v-list-item
                v-if="item.refunds && item.refunds.length"
                style="cursor: pointer"
                icon="mdi-eye"
                @click="showRefundsPerPayment(item)"
              >
                <v-list-item-title>Show refunds</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-if="refundListDialog" v-model="refundListDialog" width="auto ">
      <v-card>
        <v-card-title>Refunds</v-card-title>

        <v-data-table
          :headers="refundsHeaders"
          :items="refundsPerPayment"
          :items-per-page="10"
          sort-desc
          item-key="id"
          class="elevation 1"
        >
          <template #[`item.status`]="{ item }">
            <div :class="refundStatusClass(item.status)">
              {{ item.status }}
            </div>
          </template>
        </v-data-table>

        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" @click="hideRefundsPerPayment()">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="refundDialog" width="600" @update:model-value="cancelRefund()">
      <v-card>
        <v-card-title class="primary">
          <span class="white--text text-heading-5">Refund payment?</span>
        </v-card-title>
        <v-alert v-if="showAnnualRefundWarning(paymentId)" type="info" class="mt-3" title="Annual charges found">
          <span class="text-subtitle-1">
            This payment contains charges(s) for annual subscription and the warranty start date of latest seen ring is
          </span>
          <span class="text-subtitle-1 font-weight-bold">
            {{ formatDateTime(warrantyStartDateForLatestRing, 'DD MMM YYYY') }}.
          </span>
          <span class="text-subtitle-1">
            If this is the correct ring, this will have implications on revenue recognition.
          </span>
          <span class="text-subtitle-1">Are you sure you want to proceed?</span>
          <br />
          <br />
          <a
            style="color: white"
            href=" https://app.getguru.com/card/TA8XKjKc/What-Is-Annual-Membership-Plan"
            target="_blank"
          >
            [GURU] When refunding annual membership is allowed?
          </a>
        </v-alert>
        <v-card-text class="px-6 pb-0">
          <p class="my-3 text-subtitle-1">You are about to refund a payment</p>

          <v-autocomplete
            v-model="refundReason"
            :items="refundReasons"
            :disabled="refundProcessing"
            label="Select the refund reason"
            item-title="text"
            item-value="value"
          />
          <v-textarea
            v-model="refundReasonDetail"
            :disabled="refundReason !== 'Escalations' || refundProcessing"
            label="Details for escalation (max 255 characters)"
            auto-grow
            rows="2"
          />
          <DatePicker v-model="issueStartDate" label="Issue start date" @valid="issueStartDateValid = $event" />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="grey darken-1" :disabled="refundProcessing" variant="text" @click="cancelRefund()">NO</v-btn>
          <v-btn
            color="primary"
            :disabled="
              !issueStartDateValid ||
              refundProcessing ||
              refundReason?.length < 3 ||
              refundReason?.length > 255 ||
              (refundReason === 'Escalations' && refundReasonDetail?.length < 3)
            "
            :loading="refundProcessing"
            variant="text"
            @click="confirmRefund()"
          >
            YES
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
  import { ChargeBillingPeriod, PaymentsInfo, Refund, RefundStatus, RefundsStatusInfo } from './types'

  import { Component, Prop, Watch, mixins, toNative } from 'vue-facing-decorator'

  import { DateTime } from '#mixins/dateTime'

  import { paymentsHeaders, refundReasonFields, refundsHeaders } from '#views/users/constants'

  import { getRefundStatusClass, getRefundsStatusInfo } from '#utils/user/refunds'

  import { AppStore, SubscriptionStore } from '#stores'

  import { Ring } from '#types'

  @Component({})
  class Payments extends mixins(DateTime) {
    @Prop() public user!: any
    @Prop() public sidecar!: boolean

    public subscriptionStore = new SubscriptionStore()
    public appStore = new AppStore()

    public errorText = ''
    public paymentId = ''
    public refundReason = ''
    public refundReasonDetail = ''

    public issueStartDate = ''
    public issueStartDateValid = false

    public isFetching = true
    public refundDialog = false
    public refundProcessing = false

    public headers = paymentsHeaders
    public refundReasons = refundReasonFields.flat()
    public refundsPerPayment: Refund[] | undefined = []

    public inputErrors: { issueStartDate: string[] } = {
      issueStartDate: [],
    }

    public interval: number | undefined = undefined

    public refundListDialog = false
    public refundsHeaders = refundsHeaders

    public get rights() {
      return this.appStore.getRights || []
    }

    /**
     * Returns warranty start date for latest ring
     * This is used for annual subscription refund warning
     * @returns string | null
     */
    public get warrantyStartDateForLatestRing(): string | null {
      return this.user?.rings.reduce((a: Ring, b: Ring) => {
        return new Date(a.lastSeenDate) > new Date(b.lastSeenDate) ? a : b
      })?.warrantyStartDate
    }

    public get payments() {
      return this.subscriptionStore.payments || []
    }

    @Watch('user')
    protected onUuidChange(_val: any, _prevVal: string) {
      this.updatePayments(true)
    }

    public mounted() {
      this.issueStartDate = this.$dayjs().format('YYYY-MM-DD')

      this.updatePayments(true)
      this.interval = setInterval(this.updatePayments, 5 * 60 * 1000)
    }

    public beforeUnmount() {
      clearInterval(this.interval)
    }

    /**
     * Check if payment contains one or more annual subscription charges
     * @param payment
     * @returns boolean
     */
    public isAnnualPayment(payment: PaymentsInfo | undefined): boolean {
      if (payment?.charges) {
        return payment.charges.some((charge) => charge.billingPeriod === ChargeBillingPeriod.YEARS)
      }
      return false
    }

    /**
     * Check if payment is refundable
     * Payments that contain hardware charges, are not processed or have already been refunded are not refundable
     * Payments that contain annual subscription charges are not refundable for non-admin users
     * @param payment
     * @returns boolean
     */
    public refundDisabled(payment: PaymentsInfo): boolean {
      if (this.isAnnualPayment(payment) && !this.rights.includes('rolesPaymentAdmin')) {
        return true
      }
      return payment.refundAmount !== 0 || payment.status !== 'Processed' || payment.reason === 'hardware'
    }

    /**
     * Returns the reason why the refund is disabled
     * @param payment
     * @returns string
     */
    public refundDisabledReason(payment: PaymentsInfo): string {
      if (this.isAnnualPayment(payment) && !this.rights.includes('rolesPaymentAdmin')) {
        return 'Refund disabled. Payment contains annual charges. Payment data admin role required.'
      } else if (payment.refundAmount !== 0) {
        return 'Refund disabled. Payment has already been refunded.'
      } else if (payment.status !== 'Processed') {
        return 'Refund disabled. Payment is not processed.'
      } else if (payment.reason === 'hardware') {
        return 'Refund disabled. Payment contains hardware charges.'
      }
      return 'Refund disabled.'
    }

    /**
     * Check if the annual refund warning should be visible.
     * The warning should be visible if the payment is annual and the warranty start date for latest ring is older than 30 days
     * This is done to prevent revenue recognition issues
     * @param paymentId
     * @returns boolean
     */
    public showAnnualRefundWarning(paymentId: string): boolean {
      const payment: PaymentsInfo | undefined = this.payments.find((payment) => payment.id === paymentId)

      if (payment && this.isAnnualPayment(payment) && this.warrantyStartDateForLatestRing) {
        const checkDate = this.checkPastXDays(this.warrantyStartDateForLatestRing, 30)
        if (!checkDate) {
          return true
        }
      }
      return false
    }

    public cancelRefund() {
      this.refundDialog = false
      this.refundReason = ''
      this.refundReasonDetail = ''
      this.issueStartDate = this.$dayjs().format('YYYY-MM-DD')
    }

    public async confirmRefund() {
      if (this.refundReason.length > 3 && this.issueStartDate) {
        this.refundProcessing = true
        await this.subscriptionStore
          .refundPayment({
            userId: this.user.uuid,
            paymentId: this.paymentId,
            refundReason: this.refundReason,
            issueStartDate: this.issueStartDate,
            refundReasonDetail: this.refundReasonDetail,
          })
          .catch((error) => {
            this.errorText = error?.message ? error?.message || '' : ''
          })
      }

      this.paymentId = ''
      this.refundReason = ''
      this.refundReasonDetail = ''
      this.issueStartDate = this.$dayjs().format('YYYY-MM-DD')

      this.refundDialog = false
      this.refundProcessing = false

      this.updatePayments(true)
    }

    public async refundItem(item: any) {
      this.refundDialog = true
      this.paymentId = item.id
    }

    public showRefundsPerPayment(payment: PaymentsInfo) {
      this.refundListDialog = true
      this.paymentId = payment.id

      this.refundsPerPayment = payment.refunds
        ? payment.refunds.map((refund) => {
            return {
              ...refund,
              formattedMarkedForSubmissionOn: this.formatDateTime(
                refund.markedForSubmissionOn,
                'DD/MM/YYYY HH:mm',
              ).replace('UTC', ''),
              formattedRefundTransactionTime: this.formatDateTime(
                refund.refundTransactionTime,
                'DD/MM/YYYY HH:mm',
              ).replace('UTC', ''),
            }
          })
        : []
    }

    public hideRefundsPerPayment() {
      this.refundListDialog = false
      this.paymentId = ''
      this.refundsPerPayment = []
    }

    public refundsStatusAndText(refunds: Refund[]): RefundsStatusInfo | undefined {
      return getRefundsStatusInfo(refunds)
    }

    public refundStatusClass(status: RefundStatus): string {
      return getRefundStatusClass(status)
    }

    private updatePayments(initial: boolean) {
      if (this.user) {
        this.subscriptionStore.getPayments({
          userId: this.user.uuid,
          initial,
        })
        this.isFetching = false
      }
    }

    public reloadPayments() {
      this.updatePayments(true)
    }
  }

  export default toNative(Payments)
</script>

<style lang="scss" scoped>
  :deep(.v-list-subheader) {
    color: #e91e63 !important;
    font-size: 0.7rem;
  }

  :deep(.v-data-table-header.v-data-table-header-mobile) {
    display: none;
  }
</style>
